// DECLARATION OF WHICH DEVICE IS IT
import DeviceDetector from 'device-detector-js';
export default {
	namespaced: true,
	state() {
		return {
			width: document.documentElement.clientWidth,
			height: document.documentElement.clientHeight,
			device: null,
			isLandscape: false,
		};
	},
	getters: {
		isDesktopSize(state) {
			return state.width >= 1200 ? true : false;
		},
		// isTablet(state) {
		// 	return state.width < 1200 && state.width >= 768 ? true : false;
		// },
		// isMobile(state) {
		// 	return state.width <= 767 ? true : false;
		// },
		// desktopStatus(_, getters) {
		// 	return getters.isDesktop ? '1' : '0';
		// },
		// mobileStatus(_, getters) {
		// 	return getters.isDesktop ? '0' : '1';
		// },
		isDesktop(state) {
			return state.device == 'desktop' || state.device == 'television';
		},
		isTablet(state) {
			return state.device == 'tablet';
		},
		isMobile(state) {
			return state.device == 'smartphone' || state.device == 'phablet';
		},
		desktopStatus(_, getters) {
			return getters.isDesktop ? '1' : '0';
		},
		mobileStatus(_, getters) {
			return getters.isDesktop ? '0' : '1';
		},
		isLandscape(state) {
			return state.isLandscape;
		},
	},
	mutations: {
		setDeviceDimensions(state) {
			state.width = document.documentElement.clientWidth;
			state.height = document.documentElement.clientHeight;
		},
		runDeviceDetector(state) {
			const deviceDetector = new DeviceDetector();
			const userAgent = navigator.userAgent;
			const device = deviceDetector.parse(userAgent);
			state.device = device.device.type;
		},
		setLandscape(state, status) {
			state.isLandscape = status;
		},
	},
	actions: {
		async getDeviceDimensions({ commit, dispatch }) {
			commit('setDeviceDimensions');
			await commit('runDeviceDetector');
			dispatch('checkLandscapeMode');
		},
		checkLandscapeMode({ state, getters, commit }) {
			if (state.width > state.height && !getters.isDesktop) {
				commit('setLandscape', true);
			} else {
				commit('setLandscape', false);
			}
		},
	},
};
