<template>
  <div class="container-fluid games-filter-component" :class="containerClass">
    <div class="row games-filter-row d-flex align-items-center justify-content-center">
      <div
        class="col-12 col-md-5 order-md-1 col-lg-3 order-lg-1 text-center text-md-start text-lg-center mt-3 mt-md-0 ps-md-2 ps-lg-0"
        :class="isDesktop ? 'search-bar' : ''"
      >
        <div
          class="d-inline cursor-pointer button-search"
          :class="isOpenSearch ? 'open' : ''"
          @click="openSearchForm"
          for="game_name"
        >
          <i
            class="bi bi-x-circle px-2"
            :class="isOpenSearch ? '' : 'hide'"
            @click="resetSearchField"
          ></i>
          <i
            class="bi bi-search px-2"
            :class="isOpenSearch ? '' : 'hide'"
            @click="searchField"
          ></i>
          <i class="bi bi-search" :class="isOpenSearch ? 'hide' : ''"></i>
        </div>

        <form
          @submit.prevent="searchField"
          class="d-inline-block searchForm"
          :class="isOpenSearch ? 'open' : ''"
        >
          <input
            type="text"
            id="game_name"
            class="form-control no-shadow"
            :placeholder="translations.casino.search_game + '...'"
            v-model="searchTerm"
            @keydown.enter.prevent="searchField"
          />
        </form>
      </div>

      <div class="col-12 col-md-10 order-md-3 col-lg-6 order-lg-2">
        <base-game-sections-menu
          @closeSearch="closeSearch"
          :sections="sections"
          :translations="translations"
          :isSticked="isSticked"
          :filters="filters"
        ></base-game-sections-menu>
      </div>

      <!--START OLD SELECT PICKER-->
      <div
        class="col-12 col-md-4 order-md-2 col-lg-2 order-lg-3 mt-4 mt-md-0"
        v-if="!isCasinoLoading"
      >
        <select
          name="provider"
          id="provider"
          class="form-control"
          @change="callSingleProviderSelectedOld"
        >
          <option value="null" disabled>
            {{ translations.casino.select_provider }}
          </option>
          <option value="all">{{ translations.casino.show_all }}</option>
          <option
            v-for="provider in games_providers"
            :key="provider.id"
            :value="provider.id"
          >
            {{ provider.name }}
          </option>
        </select>
      </div>
      <!--End OLD SELEC PICKER-->
      <!-- <div
        class="col-12 col-md-4 order-md-2 col-lg-2 order-lg-3 mt-3 mt-md-0"
        v-if="!isCasinoLoading && !isDesktop"
      >
        <button class="showProviderModal" @click="showModal = true">
          {{
            providerSelected
              ? providerNameBtn
              : 'Select Provider'
          }}
        </button>

        <div
          class="modal"
          :class="{ 'd-block': showModal }"
          @click="closeModal"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                 Select Provider
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  @click="showModal = false"
                ></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div
                    v-for="(provider, index) in games_providers"
                    :key="provider.id"
                    class="col-lg-4 col-md-6 col-sm-12 mb-3"
                    @click="callSingleProviderSelected(provider)"
                  >
                    <div
                      class="modalImg text-center mb-2"
                      :class="[
                        { selected: provider.id === providerSelected },
                        {
                          'mx-4': isDesktop,
                          'mx-3':
                            !isDesktop &&
                            this.$route.name !== 'live-casino' &&
                            this.$route.name !== 'others',
                          'mx-2':
                            !isDesktop &&
                            (this.$route.name === 'live-casino' ||
                              this.$route.name === 'others'),
                        },
                      ]"
                      @mouseover="hoverProvider(provider)"
                      @mouseleave="resetProviderHover()"
                    >
                      <img :src="provider.image" alt="Provider Image" />
                      <p class="providerNameModal m-0">
                        {{ provider.name }}
                      </p>
                    </div>
                   
                    <div v-if="(index + 1) % 2 === 0" class="clearfix"></div>
                  </div>
                </div>
              </div>
              <div class="modal-footer mb-2">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="resetproviderFilter()"
                >
                  Reset filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!--END SELECT PICKER-->
    </div>
  </div>
</template>

<script>
import BaseGameSectionsMenu from "@/components/global/ui/BaseGameSectionsMenu.vue";
import { mapGetters } from "vuex";
export default {
  props: [
    "games_providers",
    "sections",
    "isCasinoLoading",
    "isDesktop",
    "sectionsFilters",
    "filters",
  ],
  components: { BaseGameSectionsMenu },
  data() {
    return {
      isSticked: false,
      isOpenSearch: false,
      searchTerm: "",
      providerNameBtn: null,
      providerSelected: null,
      showErrorMessage: false,
      isMobile: window.innerWidth <= 480,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters("translations", ["translations"]),
    containerClass() {
      return [this.isSticked ? "isSticked" : "", this.isDesktop ? "" : "mobile"];
    },
  },
  watch: {
    providerSelected(value, oldValue) {
      if (value != oldValue) {
        this.$store.dispatch("casino/selectProvider", value);
      }
    },
  },
  methods: {
    closeModal(event) {
      if (event.target.classList.contains("modal")) {
        this.showModal = false;
      }
    },
    windowScroll() {
      if (window.scrollY < 550) {
        this.isSticked = false;
      } else {
        this.isSticked = true;
      }
    },
    openSearchForm() {
      this.isOpenSearch = !this.isOpenSearch;
      if (!this.isOpenSearch) {
        this.searchTerm = "";
      }
    },
    closeSearch() {
      if (this.isOpenSearch) {
        this.openSearchForm();
      }
    },
    resetSearchField() {
      if (this.searchTerm.length > 1) {
        this.searchTerm = ""; // Reset the searchTerm
        // Show the games of the section by clearing any applied filters
        this.$store.dispatch("casino/changingPage", { currentPage: 1 });
      } else if (this.searchTerm.length == 0) {
        this.searchTerm = ""; // Reset the searchTerm
      }

      // this.providerSelected = "all"; // Reset providerSelected to "all"
    },

    setProviderAndSection() {
      this.$store.dispatch("casino/clearSelection");
    },
    //ORIGINALE FUNZIONANTE

    /////////////////////////////////////
    callSingleProviderSelectedOld(event) {
      let value = event.target.value;
      this.providerSelected = value;
      if (value != "all") {
        this.$store.dispatch("casino/callSingleProvider", {
          slug: value,
          currentSection: this.$route.path,
          searchTerm: this.searchTerm,
        });
      } else {
        this.$store.dispatch("casino/changingPage", { currentPage: 1 });
        this.$store.dispatch("casino/clearFilter");
        this.providerSelected = "all";
        // }
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    /////////////////////////////////////
    callSingleProviderSelected(provider) {
      // let value = event.target.value;
      // this.providerSelected = value;
      this.providerSelected = provider.id;
      this.providerNameBtn = provider.name;
      // if (value != "all") {
      if (this.providerSelected != "all") {
        this.$store.dispatch("casino/callSingleProvider", {
          // slug: value,
          slug: this.providerSelected,
          currentSection: this.$route.path,
          searchTerm: this.searchTerm,
        });
        this.showModal = false;
      } else {
        this.$store.dispatch("casino/changingPage", { currentPage: 1 });
        this.$store.dispatch("casino/clearFilter");
        // }
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    resetproviderFilter() {
      this.$store.dispatch("casino/changingPage", { currentPage: 1 });
      this.providerSelected = "all";
      this.showModal = false;
    },
    searchField() {
      if (this.searchTerm.length >= 3) {
        let selectedProvider = this.providerSelected; // Ottieni il valore del provider selezionato
        if (selectedProvider === "all") {
          selectedProvider = ""; // Assegna "" se il provider selezionato è "all"
        }

        this.$store.dispatch("casino/searchGames", {
          payload: this.searchTerm.toLowerCase(),
          currentSection: this.$route.path,
          providerParam: selectedProvider, // Assegna il valore del provider selezionato a providerParam
          sectionsFilters: this.sectionsFilters,
        });
        // Hide the error message if it was previously shown
        this.showErrorMessage = false;
      } else {
        if (this.searchTerm.length > 0) {
          alert("You must enter at least three characters");
        } else {
          this.$store.dispatch("casino/changingPage", { currentPage: 1 });
          // this.showErrorMessage = this.searchTerm.length = 0;
        }
      }
    },

    hoverProvider(provider) {
      if (this.providerSelected !== provider.id) {
        provider.isHovered = true;
      }
    },
    resetProviderHover() {
      for (const provider of this.games_providers) {
        provider.isHovered = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.windowScroll();
    });
  },
  beforeUnmount() {
    this.setProviderAndSection();
  },
};
</script>

<style scoped>
.games-filter-component {
  position: sticky;
  top: 4rem;
  background: var(--darker-grey);
  z-index: 69;
  box-shadow: none;
  min-height: 5rem;
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
  transition: var(--short-trans);
}

@media (max-width: 1199px) {
  .games-filter-component {
    position: sticky;
    top: 101px;
    background: var(--darker-grey);
    z-index: 69;
    box-shadow: none;
    min-height: 5rem;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    transition: var(--short-trans);
  }
}

.games-filter-row {
  min-height: 5rem;
}
.games-filter-component.mobile {
  position: relative !important;
  top: 0 !important;
  min-height: 6rem;
  margin: 0px;
}
.games-filter-component.isSticked {
  box-shadow: var(--light-shadow);
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
}
.games-filter-component.mobile.isSticked {
  box-shadow: var(--light-shadow);
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
}
.cursor-pointer:hover {
  cursor: pointer;
}
.bi-search,
.bi-x-circle {
  cursor: pointer;
  display: inline-block;
  transition: var(--short-trans);
}
.searchForm {
  visibility: hidden;
  opacity: 0;
  width: 0%;
  margin-left: -10px;
  border-radius: 0px;
  transition: var(--medium-trans);
  box-shadow: none;
}
input[placeholder="Game name..."] {
  border: none;
}
.searchForm.open {
  visibility: visible;
  opacity: 1;
  width: 65%;
  margin-left: 10px;
  border-radius: 10px;
  box-shadow: var(--light-shadow);
}
.no-shadow:focus {
  box-shadow: none;
}
.search-bar {
  padding-left: 2rem;
}
.hide.bi-search,
.hide.bi-x-circle {
  display: none;
}
.button-search {
  border-radius: 10px;
  box-shadow: var(--light-shadow);
  border: 1px solid var(--dirty-white);
  padding: 0.5vw 2.4vw;
  transition: var(--short-trans);
  color: var(--dirty-white);
}
.mobile .button-search {
  padding: 0.5rem 2rem;
}
.button-search > i:nth-child(1) {
  color: var(--dirty-white);
  transition: var(--short-trans);
}
.button-search.open {
  padding: 0;
  border-color: transparent;
  color: var(--dirty-white);
  box-shadow: none;
  transition: var(--short-trans);
  text-shadow: none;
}
.button-search.open > i:nth-child(2) {
  transition: var(--short-trans);
  color: var(--dirty-white);
  text-shadow: none;
}

/* qui va la modale */
@media (min-width: 769px) {
  .modal {
    backdrop-filter: blur(2px);
  }
  .modal-body .row .col-md-6 {
    width: 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;

    padding: 5px;
  }
  .modal-dialog {
    /* max-width: 90%; */
    max-width: 60%;
  }
  .modalImg img {
    object-fit: contain;
    max-width: 30%;
    max-height: 80%;
    cursor: pointer;
  }

  .modalImg {
    padding: 10px 20px;
  }

  .modal-footer {
    display: flex !important;
    justify-content: center !important;
    align-content: center !important;
    align-items: center !important;
    /* padding: 6px; */
  }
}

/* qui va la modale */

.modalImg {
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0px 0px 0.5rem 0.01rem var(--darker-grey);
}

.modalImg.selected {
  border-color: var(--dark-orange);
  box-shadow: 0px 0px 0.5rem 0.01rem var(--dark-orange);
  transition: var(--short-trans);
}

.modalImg:hover {
  border-color: var(--dark-orange);
  box-shadow: 0px 0px 0.5rem 0.01rem var(--dark-orange);
  transition: var(--short-trans);
}

.providerNameModal {
  text-align: center;
  font-weight: bolder;
  font-size: small;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.showProviderModal {
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: var(--darker-grey);
  transition: var(--short-trans);
  box-shadow: none;
  background: var(--dirty-white);
  border: 1px solid transparent;
  max-width: 100%;
  text-align: center;
}

@media (max-width: 768px) {
  .providerNameModal {
    text-align: center;
    font-weight: bolder;
    font-size: xx-small;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .modal-content {
    max-height: 570px;
    margin-bottom: 75px;
  }
  .games-filter-row {
    flex-wrap: wrap;
  }

  .games-filter-row > div {
    flex-basis: 100%;
    margin-bottom: 1rem;
  }

  .col-12.col-md-5.order-md-1.col-lg-3.order-lg-1 {
    order: 1;
    flex-basis: 65%;
    display: flex;
    align-content: flex-start;
    margin-top: 5px !important;
  }

  .col-12.col-md-10.order-md-3.col-lg-6.order-lg-3 {
    order: 2;
    flex-basis: 100%;
  }

  .col-12.col-md-4.order-md-2.col-lg-2.order-lg-3 {
    order: 3;
    flex-basis: 35%;
    margin-top: 5px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .button-search.open {
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 5px;
  }

  .searchForm.open {
    max-width: 100%;
    display: flex;
  }

  .modal {
    position: fixed;
    top: 40px;
    left: 0;
    backdrop-filter: blur(2px);
  }

  .modal-body .row > div {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .modal-body {
    /* max-height: 600px; Altezza massima desiderata */
    max-height: 470px;
    overflow-y: auto;
  }

  .modal-title {
    font-size: small;
    text-align: center;
    font-weight: bolder;
  }

  .modal-footer {
    display: flex !important;
    justify-content: center !important;
    align-content: center !important;
    align-items: center !important;
    padding: 6px;
  }

  .modal img {
    object-fit: contain;
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    cursor: pointer;
  }

  .showProviderModal {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: var(--darker-grey);
    transition: var(--short-trans);
    box-shadow: none;
    background: var(--dirty-white);
    border: 1px solid transparent;
    max-width: 100%;
    text-align: center;
    font-size: small;
  }
}
</style>
