<template>
	<div>
		<base-info-page>
			<template #title>Recovery password</template>
			<template #default>
				<div class="form-group">
					<input
						v-model="email"
						type="email"
						id="email"
						placeholder="Email"
						class="form-control my-3"
					/>
					<div class="form-group">
						<base-button
							@click="recovery"
							color="orange"
							caption="Recover"
							id="recover"
							:disabled="isEmailInvalid"
						></base-button>
					</div>
				</div>
			</template>
		</base-info-page>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				email: '',
				isEmailInvalid: true,
			};
		},
		watch: {
			email(value) {
				function validationEmail(el) {
					let result = String(el)
						.toLowerCase()
						.match(
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
						);
					return result;
				}
				if (validationEmail(value)) {
					this.isEmailInvalid = false;
				} else {
					this.isEmailInvalid = true;
				}
			},
		},
		methods: {
			recovery() {
				this.$store.dispatch('registration/recoverPassword', this.email);
			},
		},
	};
</script>
