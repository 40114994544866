<template>
  <div class="admin-page">
    <section class="admin-section">
      <base-info-page>
        <template #title> <h1 class="text-center">Login Admin</h1></template>
        <template #default>
          <form @submit.prevent>
            <div class="form-group">
              <input
                v-model="nickname"
                type="text"
                id="nickname"
                :placeholder="'Username'"
                class="form-control my-3"
              />
            </div>
            <div class="form-group">
              <input
                v-model="password"
                type="password"
                id="password"
                :placeholder="'Password'"
                class="form-control my-3"
              />
            </div>
            <section>
              <div class="form-group mt-5 text-center">
                <base-button
                  @click="redirectToAdminPage"
                  color="orange"
                  :caption="'Login'"
                  id="login"
                  :disabled="isLoginButtonDisabled"
                ></base-button>
              </div>
            </section>
          </form>
        </template>
      </base-info-page>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      language_selected: localStorage.getItem("language"),
      nickname: "",
      password: "",
      admin_nickname: "Kajot-admin-2023",
      admin_password: "Kajot2023@admin!!",
    };
  },
  computed: {
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    endpoint() {
      return this.$store.getters["api/endpoint"];
    },
    axios() {
      return this.$store.getters["api/axios"];
    },
    auth() {
      return this.$store.getters["api/auth"];
    },
    prefilled_nickname() {
      return this.$store.getters.prefilled_nickname;
    },
    prefilled_password() {
      return this.$store.getters.prefilled_password;
    },
    ...mapGetters("player", ["token"]),
    isLoginButtonDisabled() {
      return !this.nickname.trim() || !this.password.trim();
    },
  },
  methods: {
    redirectToAdminPage() {
      if (!this.isLoginButtonDisabled) {
        if (
          this.nickname === this.admin_nickname &&
          this.password === this.admin_password
        ) {
          sessionStorage.setItem("adminNickname", this.admin_nickname);
          sessionStorage.setItem("adminPassword", this.admin_password);
          this.$store.dispatch("translations/callTranslations").then(() => {
            alert("You will be redirected to the home page shortly");
            window.location.href = "/";
          });
        } else {
          alert("Incorrect credentials");
          this.nickname = "";
          this.password = "";
        }
      }
    },
  },
  created() {
    let language = localStorage.getItem("language");
    this.$router.push({ params: { lang: language } });
  },
  mounted() {},
};
</script>

<style scoped>
.admin-section {
  padding-top: 0px;
  padding-bottom: 0px;
  background: var(--dirty-white);
}
</style>
