import { createI18n } from 'vue-i18n';
import en from './en.json';
import de from './de.json';
import cz from './cz.json';
import sk from './sk.json';

const messages = {
  en: {
    ...en,
    titles: {
      ...en.titles
    }
  },
  de: {
    ...de,
    titles: {
      ...de.titles
    }
  },
  cz: {
    ...cz,
    titles: {
      ...cz.titles
    }
  },
  sk: {
    ...sk,
    titles: {
      ...sk.titles
    }
  }
};

const defaultLanguage = 'en';
const currentLanguage = localStorage.getItem('language');

const i18n = createI18n({
  locale: currentLanguage ?? defaultLanguage,
  fallbackLocale: defaultLanguage,
  messages,
  globalInjection: true,
});

export default i18n;