<template>
  <div
    class="col-md-4 col-12 mb-4"
    v-for="promotion in promotions"
    :key="promotion.id"
    :value="promotion.id"
  >
    <div class="img">
      <img
        @click="openPromo(promotion.id)"
        :src="getPromotionImage(promotion)"
        class="img-fluid rounded"
      />
    </div>
    <div class="content content-adjusted mt-2">
      <h5 v-if="promotion.name">{{ promotion.name }}</h5>
      <p v-if="0 && promotion.campaign?.valid_from">
        {{ translations?.promotion?.start_promotion }}:
        {{ formatDate(promotion.campaign.valid_from) }}
      </p>
      <p v-if="0 &&  promotion.campaign?.expires_at">
        {{ translations?.promotion?.end_promotion }}:
        {{ formatDate(promotion.campaign.expires_at) }}
      </p>
      <p
        v-if="
          !promotion.banners[0] ||
          !promotion.banners[0].medias ||
          promotion.banners[0].medias.length === 0
        "
      >
        Discover Offers
      </p>
      <p v-if="promotion.comment">{{ promotion.comment }}</p>
      <div class="btn-wrapper">
        <base-button
          class="promotionButton"
          color="orange"
          nomargin="true"
          :caption="promotion.is_valid ? getCaption() : notAvailableYet(promotion.valid_from)"
          @click="openPromo(promotion.id)"
          :disabled="!promotion.is_valid"
        ></base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import moment from 'moment';
export default {
  props: ["promotions"],
  created() {},
  computed: {
    ...mapGetters("translations", ["translations"]),
  },
  methods: {
    openPromo(id) {
      let el = this.promotions.find(el => el.id == id);
      if(el != null && !el.is_valid)
        return;
      this.$store.dispatch("skinPromotions/findPromotion", { id });
      const lang = localStorage.getItem("language") || "";
      const location = `/${lang}/promotion/${id}`;
      this.$router.push(location);
    },
    getPromotionImage(promotion) {
      if ( promotion.banners[0] && promotion.banners[0].medias && promotion.banners[0].medias.length > 0) 
      {
        const cardMedia = promotion.banners[0].medias.find(
          (media) =>  media.title && media.title.toLowerCase() === "card"
        );
        if (cardMedia) 
          return cardMedia.full_url;
      }
      return "/images/promotiondefault.jpg";
    },
    getCaption() {
      const lang = localStorage.getItem("language") || "en";
      switch (lang) {
        case "en":
          return "Find out more";
        case "sk":
          return "Dozvedieť sa viac";
        case "pl":
          return "Dowiedz się więcej";
        case "hu":
          return "Tudj meg többet";
        case "cs":
          return "Zjistit více";
        case "de":
          return "Finde mehr heraus";
        default:
          return "Find out more";
      }
    },
    notAvailableYet(date) {
      let transAvailable = this.translations.promotion.available ?? 'This bonus will available from';     
      let string = date;
      return transAvailable+ " "+string;
    },
    formatDate(dateString) {
      let date = new Date(dateString);

      let options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };

      let formattedDate = date.toLocaleString("en-US", options);

      return formattedDate;
    },
  },
};
</script>

<style scoped>
img {
  width: 100%;
  transition: var(--short-trans);
  object-fit: cover;
  box-shadow: var(--light-shadow);
}
img:hover {
  cursor: pointer;
  transform: scale(1.01);
  box-shadow: var(--orange-shadow);
  transition: var(--short-trans);
}

p {
  font-weight: 600;
  line-height: 1.2rem;
}

.content-adjusted {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
