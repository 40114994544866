<template>
  <div>
    <div class="banner">
      <div class="row mb-4 justify-content-center">
        <div class="col-12">
          <carousel-full :banners="promotion.banners ?? ''"></carousel-full>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-start mt-5">
          <div class="col-12 col-md-5 col-lg-3">
            <base-button
              color="orange"
              :caption="
                translations?.buttons?.goToallPromotions ?? 'Go to all Promotions'
              "
              nomargin="true"
              icon="bi bi-arrow-left"
              @click="
                $router.push({
                  name: 'promotion',
                  params: { lang: promotion.language.code },
                })
              "
            ></base-button>
          </div>
        </div>
      </div>
    </div>
    <div class="container my-5">
      <div class="row">
        <div class="col-12">
          <div class="title">
            {{ promotion.name }}
          </div>
          <div class="mt-3">
            <p v-if="0 && promotion.campaign?.valid_from">
              {{ translations?.promotion?.start_promotion ?? "Start" }}:
              {{ formatDate(promotion.campaign.valid_from) }}
            </p>
            <p v-if="0 && promotion.campaign?.expires_at">
              {{ translations?.promotion?.end_promotion ?? "End" }}:
              {{ formatDate(promotion.campaign.expires_at) }}
            </p>
          </div>
        </div>
        <div class="col-11 my-3">
          <span v-html="promotion.content"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CarouselFull from "@/components/elements/CarouselFull.vue";
export default {
  props: ["promotion", "lang"],
  data() {
    return {
      translbtn: "",
    };
  },
  components: { CarouselFull },
  beforeUnmount() {
    this.$store.dispatch("skinPromotions/clearPromotion");
  },
  computed: {
    ...mapGetters("translations", ["translations"]),
  },
  created() {},
  methods: {
    formatDate(dateString) {
      let date = new Date(dateString);

      let options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };

      let formattedDate = date.toLocaleString("en-US", options);

      return formattedDate;
    },
  },
};
</script>

<style scoped>
.title {
  color: var(--dark-orange);
  font-size: 2rem;
  font-weight: 600;
}

p {
  font-weight: 600;
  line-height: 1.2rem;
}

.banner .row {
  margin-right: 0px;
}

.row > * {
  padding-right: 0px;
}
</style>
