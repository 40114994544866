import { createStore } from 'vuex';
import deviceDimensions from './modules/main/deviceDimensions.js';
import apiAccess from './modules/main/apiAccess.js';
import authPlayer from './modules/player/authPlayer.js';
import playerMessages from './modules/player/playerMessages.js';
import reports from './modules/player/reports.js';
import skinTranslations from './modules/main/skinTranslations.js';
import skinSettings from './modules/main/skinSettings.js';
import skinBanners from './modules/main/skinBanners.js';
import skinPromotions from './modules/main/skinPromotions.js';
import paymentsMethods from './modules/main/paymentsMethods.js';
import casinoGames from './modules/main/casinoGames.js';
import playGame from './modules/main/playGame.js';
import registrationAndRecovery from './modules/main/registrationAndRecovery.js';
import sweetAlert from './modules/main/sweetAlert.js';

const store = createStore({
	modules: {
		device: deviceDimensions,
		registration: registrationAndRecovery,
		api: apiAccess,
		translations: skinTranslations,
		player: authPlayer,
		reports: reports,
		skinSettings: skinSettings,
		skinPromotions: skinPromotions,
		skinBanners: skinBanners,
		payments: paymentsMethods,
		casino: casinoGames,
		play: playGame,
		alert: sweetAlert,
		messages: playerMessages
	},
	state() {
		return {
			prefilled_nickname: null,
			prefilled_password: null,
		};
	},
	getters: {
		prefilled_nickname(state) {
			return state.prefilled_nickname;
		},
		prefilled_password(state) {
			return state.prefilled_password;
		},
	},
	mutations: {
		setPrefilledLoginValue(state, payload) {
			state.prefilled_nickname = payload.nickname;
			state.prefilled_password = payload.password;
		},
	},
	actions: {
		setPrefilledLoginValue(context, payload) {
			context.commit('setPrefilledLoginValue', payload);
		},
	},
});

export default store;
