<template>
	<div class="container my-3">
		<div class="row justify-content-center">
			<div
				class="col-12 col-md-4 my-2 my-lg-0"
				v-for="(item, index) in playerDocuments"
				:key="index"
			>
				<div
					class="selector"
					:class="selectedIndex == index ? 'active' : ''"
					v-if="IDList.includes(index)"
					@click="selectForm(index, item)"
				>
					{{ translations.myprofile[index] ?? index }}
				</div>
			</div>
		</div>
		<transition name="fade">
			<div v-if="selectedItem != null" class="mt-2 mt-lg-4">
				<document-form
					:item="selectedItem"
					:translations="translations"
					:i="selectedIndex"
				></document-form>
			</div>
		</transition>
	</div>
</template>

<script>
	import DocumentForm from '@/components/elements/Forms/DocumentForm.vue';
	import { mapGetters } from 'vuex';
	export default {
		components: {
			DocumentForm,
		},
		props: ['translations', 'playerDocuments', 'IDList'],
		data() {
			return {
				selectedItem: null,
				selectedIndex: null,
			};
		},
		computed: {
			...mapGetters('player', ['selectedDocumentToUpload', 'defaultSelectedDocumentToUpload']),
		},
		watch: {
			selectedDocumentToUpload(value) {
				if(value == this.defaultSelectedDocumentToUpload){
					this.selectForm(this.defaultSelectedDocumentToUpload, this.playerDocuments[this.defaultSelectedDocumentToUpload])
				}
				if (!this.IDList.includes(value)) {
					this.selectedIndex = null;
					this.selectedItem = null;
				}
			},
		},
		methods: {
			selectForm(i, it) {
				this.$store.dispatch('player/setSelectedDocumentToUpload', i);
				this.selectedIndex = i;
				this.selectedItem = it;
			},
		},
	};
</script>

<style scoped>
	.selector {
		padding: 10px 20px;
		border: 2px solid var(--dark-orange);
		border-radius: 10px;
		text-align: center;
		color: var(--dark-orange);
		font-weight: 600;
		background: var(--dirty-white);
		transition: var(--short-trans);
	}
	.selector:hover {
		cursor: pointer;
		background: var(--dark-orange);
		color: var(--dirty-white);
	}
	.selector.active {
		cursor: pointer;
		background: var(--dark-orange);
		color: var(--dirty-white);
	}
</style>
