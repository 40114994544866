<template>
	<button
		v-if="!timerButton"
		class="border-0 rounded-1 py-2 text-center text-white w-100"
		:class="classButton"
	>
		<i v-if="!!icon" :class="icon"></i> {{ caption }}
	</button>
	<button
		v-else
		class="border-0 rounded-1 py-2 text-center text-white w-100"
		:class="classButton"
		:disabled="timer != 0"
	>
		{{ caption }}
		<span class="text-white" v-if="timer != 0"> ({{ timer }}) </span>
	</button>
</template>

<script>
	export default {
		props: [
			'caption',
			'color',
			'timerButton',
			'timer',
			'nomargin',
			'marginBottom',
			'addClasses',
			'icon',
		],
		data() {
			return {
				allClasses: '',
			};
		},
		computed: {
			classButton() {
				const buttonSpecs = {
					orange: this.color == 'orange',
					green: this.color == 'green',
					grey: this.color == 'grey',
					sliderGrey: this.color == 'sliderGrey',
					sliderGreen: this.color == 'sliderGreen',
					'mt-2': this.nomargin != 'true',
				};
				return [buttonSpecs, this.addClasses];
			},
		},
	};
</script>

<style scoped>
	button {
		color: var(--dirty-white);
	}
	button.grey {
		background-color: var(--light-grey);
		transition: var(--short-trans);
	}
	button.grey:hover {
		background-color: var(--dark-grey);
		transition: var(--short-trans);
		box-shadow: 0px 0px 0.2rem 0.01rem var(--dark-grey);
	}
	button.grey:disabled {
		background-color: var(--light-grey);
		opacity: 0.4;
		transition: var(--short-trans);
		cursor: not-allowed;
	}
	button.orange {
		background-color: var(--light-orange);
		transition: var(--short-trans);
	}
	button.orange:hover {
		background-color: var(--dark-orange);
		transition: var(--short-trans);
		box-shadow: 0px 0px 0.5rem 0.01rem var(--dark-orange);
	}
	button.orange:disabled {
		background-color: var(--light-orange);
		opacity: 0.4;
		transition: var(--short-trans);
		cursor: not-allowed;
	}
	button.green {
		background-color: var(--light-green);
		transition: var(--short-trans);
	}
	button.green:hover {
		background-color: var(--dark-green);
		transition: var(--short-trans);
		box-shadow: 0px 0px 0.5rem 0.01rem var(--dark-green);
	}
	button.green:disabled {
		background-color: var(--light-green);
		opacity: 0.4;
		transition: var(--short-trans);
		cursor: not-allowed;
	}
	/* CLASSES ONLY FOR BUTTON ON SLIDERS - START */
	button.sliderGreen {
		background-color: var(--light-green);
		transition: var(--short-trans);
		color: var(--dark-grey);
		font-weight: 600;
	}
	button.sliderGreen:hover {
		background-color: var(--dark-green);
		transition: var(--short-trans);
		box-shadow: 0px 0px 0.5rem 0.01rem var(--dark-green);
	}
	button.sliderGreen:disabled {
		background-color: var(--light-green);
		opacity: 0.4;
		transition: var(--short-trans);
		cursor: not-allowed;
	}
	button.sliderGrey {
		background-color: var(--darker-grey);
		transition: var(--short-trans);
		font-weight: 600;
	}
	button.sliderGrey:hover {
		background-color: var(--light-grey);
		transition: var(--short-trans);
		box-shadow: 0px 0px 0.2rem 0.01rem var(--dark-grey);
	}
	button.sliderGrey:disabled {
		background-color: var(--light-grey);
		opacity: 0.4;
		transition: var(--short-trans);
		cursor: not-allowed;
	}
	/* CLASSES ONLY FOR BUTTON ON SLIDERS - END */
	i {
		font-size: 1.05rem;
		color: var(--dirty-white);
	}
	.grey.active {
		background-color: var(--light-orange);
		transition: var(--short-trans);
	}
	.grey.active:hover {
		background-color: var(--dark-orange);
		transition: var(--short-trans);
		box-shadow: 0px 0px 0.5rem 0.01rem var(--dark-orange);
	}
</style>
