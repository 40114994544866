export default {
	token(state) {
		return state.token;
	},
	temp_token(state) {
		return state.temp_token;
	},
	kyc_token(state) {
		return state.kyc_token;
	},
	isTempTokenValid(state) {
		return state.isTempTokenValid;
	},
	translations(_, _1, _2, rootGetters) {
		return rootGetters['translations/translations'];
	},
	tokenAuth(_, getters) {
		return {
			headers: {
				authorization: getters.auth.headers.authorization,
				'user-token': getters.token,
			},
		};
	},
	tempTokenAuth(_, getters) {
		return {
			headers: {
				authorization: getters.auth.headers.authorization,
				token: getters.temp_token,
			},
		};
	},
	isAuthenticated(state) {
		return !!state.token;
	},
	endpoint(_, _1, _2, rootGetters) {
		return rootGetters['api/endpoint'];
	},
	axios(_, _1, _2, rootGetters) {
		return rootGetters['api/axios'];
	},
	auth(_, _1, _2, rootGetters) {
		return rootGetters['api/auth'];
	},
	swal(_, _1, _2, rootGetters) {
		return rootGetters.swal;
	},
	balance(state) {
		return state.playerBalance;
	},
	playerInfo(state) {
		return state.playerInfo;
	},
	statisticLimits(state) {
		return state.statisticLimits;
	},
	statisticExclusions(state) {
		return state.statisticExclusions;
	},
	playerSurvey(state) {
		return state.playerSurvey;
	},
	playerDocuments(state) {
		return state.playerDocuments;
	},
	playerCampaign(state) {
		return state.playerCampaign;
	},
	selectedDocumentToUpload(state) {
		return state.selectedDocumentToUpload.value;
	},
	defaultSelectedDocumentToUpload(state) {
		return state.selectedDocumentToUpload.default;
	},
	isPlayerLoading(state) {
		return state.isLoading;
	},
	isCheckingToken(state) {
		return state.isCheckingToken;
	},
	isMajorLimit(state) {
		return state.isMajorLimit;
	},
	basicKYCLevel(state) {
		return state.basicKYCLevel;
	},
	selfieKYCLevel(state) {
		return state.selfieKYCLevel;
	},
	amlLevel(state) {
		return state.playerAml;
	},
	errorMessage(state) {
		return state.errorMessage;
	},
	optOutToken(state) {
		return state.optOutToken;
	},
	newsletterOptOut(state) {
		return state.newsletterOptOut;
	},
	isSocketOn(state) {
		return state.isSocketOn;
	}
};
