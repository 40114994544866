<template>
  <section>
    <div
      v-if="!isDesktop && !demoNotAvailable && currentGameName != null"
      class="mobile-frame-container"
    >
      <div
        v-if="playerCampaign?.id && isSocketOn && bonusBoxVisible"
        class="boxbonus boxbonusmobile col-12 m-0 p-0 row d-flex justify-content-center align-items-center"
      >
        <div class="col-12 p-0 py-2 m-0 row boxopen" v-if="openBonus">
          <div class="col-5 col-lg-2 information-turnover">
            <p
              v-if="
                parseInt(
                  playerCampaign?.target
                    ? playerCampaign?.target
                    : playerInfo?.bonus?.target
                ) > 0
              "
              class="mb-0"
            >
              <b>{{ translations.casino.turnover ?? "Turnover" }}:</b>
              {{ playerInfo?.currency?.symbol ?? "" }}
              {{
                parseInt(
                  playerCampaign?.target
                    ? playerCampaign?.target
                    : playerInfo?.bonus?.target
                ).toFixed(2)
              }}
            </p>
            <p
              v-if="
                parseInt(
                  playerCampaign?.freespins
                    ? playerCampaign?.freespins
                    : playerInfo?.bonus?.freespins
                ) > 0
              "
              class="mb-0"
            >
              <b>{{ translations.casino.freespins ?? "Freespins" }}</b
              >:
              {{
                playerCampaign?.freespins_counter
                  ? playerCampaign?.freespins_counter
                  : playerInfo?.bonus?.freespins_counter
              }}/{{
                playerCampaign?.freespins
                  ? playerCampaign?.freespins
                  : playerInfo?.bonus?.freespins
              }}
            </p>
            <p class="mb-0">
              <b>{{ translations.casino.max_bet_bonus ?? "Max bet bonus" }}</b
              >:
              {{ playerInfo?.currency?.symbol ?? "" }}
              {{
                playerCampaign?.campaign?.max_bet
                  ? playerCampaign?.campaign?.max_bet
                  : playerInfo?.bonus?.campaign?.max_bet
              }}
            </p>
          </div>
          <div
            class="col-7 col-lg-8 d-flex align-items-center container-mobile"
            v-if="
              parseInt(
                playerCampaign?.target
                  ? playerCampaign?.target
                  : playerInfo?.bonus?.target
              ) > 0
            "
          >
            <div class="skill-main">
              <div class="skill-wrrap">
                <div class="skill-bar">
                  <div
                    class="skill-per"
                    :per="
                      playerCampaign?.counter_percentage
                        ? playerCampaign?.counter_percentage
                        : playerInfo?.bonus?.counter_percentage + '%'
                    "
                    :style="`width: ${
                      playerCampaign?.counter_percentage
                        ? playerCampaign?.counter_percentage
                        : playerInfo?.bonus?.counter_percentage
                    }%;`"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="openBox col-12" @click="seebonus">
          <span class="gift-icon">
            <img
              src="/images/arrow.png"
              v-bind:class="openBonus ? '' : 'arrowopen'"
              alt="arrow"
            />
          </span>
        </div>
      </div>

      <div class="game-background-mobile" :style="backgroundImage"></div>
      <div class="game-background-overlap-mobile"></div>
      <div class="h-100 w-100" :style="containerStyle">
        <iframe
          frameborder="0"
          ref="gameFrame"
          class="game-frame mobile"
          :src="currentGamePath"
        ></iframe>
      </div>
    </div>
    <div v-else>
      <div class="d-none timecounter">{{ countDown }}</div>
      <transition name="fade">
        <base-loading v-if="isGameLoading"></base-loading>
      </transition>
      <transition name="fade">
        <div v-if="!isGameLoading">
          <div class="game-background" :style="backgroundImage"></div>
          <div class="game-background-overlap"></div>
          <div class="container game" v-if="!isGameLoading">
            <transition name="fade">
              <div v-if="isFullscreen" class="disable-fullscreen">
                <base-button
                  color="grey"
                  @click="allowFullscreen2"
                  nomargin="true"
                  icon="bi bi-arrows-angle-contract"
                ></base-button>
              </div>
            </transition>
            <div
              class="row my-5 my-sm-2 mt-lg-5 d-flex justify-content-center align-items-center"
            >
              <div class="col-2 col-lg-1">
                <base-button
                  color="orange"
                  @click="goGamesPage"
                  nomargin="true"
                  icon="bi bi-arrow-left"
                ></base-button>
              </div>
              <div class="col-8 col-lg-10">
                <div class="game-title">{{ currentGameName }}</div>
              </div>
              <div class="col-2 col-lg-1">
                <base-button
                  color="grey"
                  @click="allowFullscreen2"
                  nomargin="true"
                  icon="bi bi-arrows-fullscreen"
                ></base-button>
              </div>
            </div>
            <div class="row d-flex justify-content-center align-items-center">
              <div v-if="!demoNotAvailable">
                <div v-if="isDesktop">
                  <div
                    v-if="playerCampaign?.id && isSocketOn && bonusBoxVisible"
                    class="boxbonus col-12 m-0 py-2 row d-flex justify-content-center align-items-center information-turnover"
                  >
                    <div class="col-2 col-lg-1 mode-type">
                      <p class="mb-0 mx-1" style="color: white">Mode</p>
                      <p class="bonus mb-0 mx-1" style="color: white">Bonus</p>
                    </div>
                    <div class="col-5 col-lg-3 information-turnover">
                      <p
                        v-if="
                          parseInt(
                            playerCampaign?.target
                              ? playerCampaign?.target
                              : playerInfo?.bonus?.target
                          ) > 0
                        "
                        class="mb-0"
                        style="color: white"
                      >
                        <b>{{ translations.casino.turnover ?? "Turnover" }}</b
                        >:
                        {{ playerInfo?.currency?.symbol ?? "" }}
                        {{
                          parseInt(
                            playerCampaign?.target
                              ? playerCampaign?.target
                              : playerInfo?.bonus?.target
                          ).toFixed(2)
                        }}
                      </p>
                      <p
                        v-if="
                          parseInt(
                            playerCampaign?.freespins
                              ? playerCampaign?.freespins
                              : playerInfo?.bonus?.freespins
                          ) > 0
                        "
                        class="mb-0"
                        style="color: white"
                      >
                        <b>{{ translations.casino.freespins ?? "Freespins" }}</b
                        >:
                        {{
                          playerCampaign?.freespins_counter
                            ? playerCampaign?.freespins_counter
                            : playerInfo?.bonus?.freespins_counter
                        }}/{{
                          playerCampaign?.freespins
                            ? playerCampaign?.freespins
                            : playerInfo?.bonus?.freespins
                        }}
                      </p>
                      <p class="mb-0" style="color: white">
                        <b>{{ translations.casino.max_bet_bonus ?? "Max Bet bonus" }}</b
                        >:
                        {{ playerInfo?.currency?.symbol ?? "" }}
                        {{
                          playerCampaign?.campaign?.max_bet
                            ? playerCampaign?.campaign?.max_bet
                            : playerInfo?.bonus?.campaign?.max_bet
                        }}
                      </p>
                    </div>
                    <div
                      class="col-5 col-lg-8 wrapper-skill-main"
                      v-if="
                        parseInt(
                          playerCampaign?.target
                            ? playerCampaign?.target
                            : playerInfo?.bonus?.target
                        ) > 0
                      "
                    >
                      <div class="skill-main">
                        <div class="skill-wrrap">
                          <div class="skill-bar">
                            <div
                              class="skill-per"
                              :per="
                                playerCampaign?.counter_percentage
                                  ? playerCampaign?.counter_percentage
                                  : playerInfo?.bonus?.counter_percentage + '%'
                              "
                              :style="`width: ${
                                playerCampaign?.counter_percentage
                                  ? playerCampaign?.counter_percentage
                                  : playerInfo?.bonus?.counter_percentage
                              }%;`"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <iframe
                    ref="gameFrame"
                    class="game-frame"
                    :src="currentGamePath"
                    :class="isFullscreen ? 'fullscreen' : ''"
                    scrolling="no"
                  ></iframe>
                </div>
              </div>
              <div v-else>
                <div class="text-center text-error">
                  {{ demoNotAvailableMessage }}
                </div>
                <div class="container" v-if="isBalanceInsufficient">
                  <div class="row d-flex justify-content-center">
                    <div class="col-12 col-md-6">
                      <base-button
                        color="orange"
                        :caption="translations?.buttons?.deposit_now ?? 'Deposit Now'"
                        @click="redirectToDeposit"
                      ></base-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="buttons-container" v-if="isDesktop && !isGameLoading">
      <a
        class="buttonTerms-Condition"
        :href="`/${lang}/terms-and-conditions`"
        target="_blank"
        >{{ translations.footer.terms_condition + "*" }}</a
      >
      <a
        class="buttonResponsible-Gaming"
        :href="`/${lang}/responsible-gaming`"
        target="_blank"
        >{{ translations.footer.responsible_gaming ?? "Responsible Gaming" }}</a
      >
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import VueCookies from "vue-cookies";
export default {
  data() {
    let seconds = VueCookies.get("mgaCounter") ?? 3600; //60minutes time
    if (seconds <= 0) seconds = 3600;

    return {
      language_selected: localStorage.getItem("language"),
      countDown: seconds,
      startCountdown: 3600,
      intervalMga: null,
      backgroundUrl: null,
      isFullscreen: false,
      dataGame: null,
      openBonus: true,
      lang: localStorage.getItem("language"),
      maxHeight: window.innerHeight,
      // skinGames:null,
    };
  },

  computed: {
    ...mapGetters("skinPromotions", ["bonusBoxVisible"]),
    ...mapGetters("casino", ["allSkinGames", "game_detail", "allSkinGames_loaded"]),
    ...mapGetters("device", ["isDesktop", "isMobile"]),
    ...mapGetters("play", [
      "currentGameName",
      "currentGamePath",
      "isGameLoading",
      "isForReal",
      "demoNotAvailable",
      "demoNotAvailableMessage",
      "isBalanceInsufficient",
      "currentGameId",
      "currentProviderId",
    ]),
    ...mapGetters("translations", ["translations"]),
    ...mapGetters("player", [
      "isPlayerLoading",
      "playerInfo",
      "playerCampaign",
      "isSocketOn",
    ]),
    backgroundImage() {
      return `background-image: url('https://storage.googleapis.com/stage-camasy-images/${this.backgroundUrl}')`;
    },
    containerStyle() {
      return {
        maxHeight: `${this.maxHeight}px`,
        minHeighy: `${this.maxHeight}px`,
        overflow: "hidden",
      };
    },
  },
  watch: {
    isForReal(value) {
      return value;
    },
    isMobile(value) {
      console.log(value);
    },
  },
  methods: {
    handleResize() {
      this.maxHeight = window.innerHeight;
    },
    redirectToDeposit() {
      this.$router.push({
        path: `/${this.language_selected}/deposit`,
      });
    },
    seebonus() {
      this.openBonus = !this.openBonus;
    },
    realityCheck() {
      this.countDown = parseInt(this.playerInfo.information.reality_minutes) * 60;
      this.$store.dispatch(
        "play/realityCheck",
        this.playerInfo.information.reality_minutes
      );
    },
    startTimer() {
      if (this.isForReal) {
        //we wait for the game to load
        this.countDown--;
        VueCookies.set("mgaCounter", parseInt(this.countDown), 3600);
        if (this.countDown <= 61) this.realityCheck();
      } else {
        clearInterval(this.intervalMga);
      }
    },
    launchGame() {
      //aggiunto providerId e gameId
      if (
        Object.prototype.hasOwnProperty.call(this.playerInfo, "marketing") &&
        !this.playerInfo.marketing.terms_and_conditions
      ) {
        Swal.fire({
          title: this.translations.dashboard_menu.Attention ?? "Attention",
          html:
            this.translations.body.terms_and_conditions ??
            "We have made changes to our Terms and Conditions. In order to continue to use Kajot-Casino, please confirm your acknowledgment.</p><p><a href='/terms-and-conditions' target='_blank'>Terms and Conditions</a>",
          icon: "warning",
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: this.translations.dashboard_menu.YesWant ?? "Yes, I Want",
          cancelButtonText:
            this.translations.dashboard_menu.NoThink ?? "No, I need to think about it",
        }).then((result) => {
          let body = { terms_and_conditions: true };
          let playerId = this.playerInfo.user_id;
          if (result.isConfirmed) {
            this.$store
              .dispatch("registration/updatePlayer", {
                body,
                playerId,
              })
              .then(() => {
                location.reload();
              });
          } else this.$router.push("/");
        });
        return false;
      }
      //--CHIAMATA FUNZIONANTE--//
      const skinGames = this.game_detail;
      let gameId = skinGames.id;
      let providerId = this.$route.params.providerId;
      //controllo che skinGames non sia ull/undefined e che sia un array
      if (skinGames) {
        this.$store.dispatch("play/play", skinGames).then(() => {
          this.$store
            .dispatch("skinPromotions/forcedCampaigns", {
              campaign_id: this.playerInfo?.bonus?.campaign_id,
              player_id: this.playerInfo?.user_id,
              game_id: gameId,
              provider_id: providerId,
            })
            .then(() => {
              // console.log(this.playerCampaign);
            });
        });
        this.backgroundUrl = skinGames.thumb;
        console.log(skinGames);
      } else {
        console.log("error");
      }
    },
    // //--CHIAMATA FUNZIONANTE--//

    allowFullscreen() {
      this.$refs.gameFrame
        .requestFullscreen()
        .then(() => {
          document.documentElement.requestFullscreen({ navigationUI: "hide" });
          console.log("in fullscreen");
        })
        .catch((error) => {
          console.log(error.message);
        });
    },

    allowFullscreen2() {
      this.isFullscreen = !this.isFullscreen;
    },
    goGamesPage() {
      let route = sessionStorage.getItem("from");
      if (route) {
        window.location.href = route;
        sessionStorage.removeItem("from");
      } else {
        window.location.href = "/";
        sessionStorage.removeItem("from");
      }
    },
    launchGamePage() {
      let gameId = this.$route.params.gameId;
      let providerId = this.$route.params.providerId;
      this.$store
        .dispatch("casino/launchGamePage", {
          game_id: gameId,
          provider_id: providerId,
        })
        .then(() => {
          this.launchGame();
        });
    },
    run() {
      if (this.playerInfo.status_reason == "awaiting_reality_check") {
        let body = { status_reason: "" };
        let playerId = this.playerInfo.user_id;
        this.$store
          .dispatch("registration/updatePlayer", {
            body,
            playerId,
          })
          .then(() => {
            this.launchGamePage();
          });
      } else this.launchGamePage();
    },
  },
  created() {
    this.run();
  },
  mounted() {
    if (this.countDown == 3600 && this.playerInfo.information !== undefined) {
      //first call
      this.countDown = parseInt(this.playerInfo.information.reality_minutes);
      this.startCountdown = this.countDown;
    }

    this.intervalMga = setInterval(this.startTimer, 1000);
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    clearInterval(this.intervalMga);
    this.$store.dispatch("play/stopGame");
  },
};
</script>

<style scoped>
.game-title {
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  text-emphasis-color: var(--dark-orange);
  color: var(--dirty-white);
  font-weight: 600;
}
.game-frame {
  position: initial;
  min-width: 100%;
  width: auto;
  min-height: 75vh;
  height: 100%;
  box-shadow: var(--light-shadow);
  transition: var(--medium-trans);
}
.disable-fullscreen {
  position: fixed;
  z-index: 71;
  width: 3rem;
  right: 1rem;
  top: 4rem;
}
.game-frame.fullscreen {
  position: fixed;
  top: 3rem;
  left: 0;
  z-index: 70;
  height: calc(100% - 3rem);
  transition: var(--medium-trans);
}
.game-background,
.game-background-overlap {
  z-index: 59;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(10px);
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
}
.game-background-overlap {
  background: rgba(0, 0, 0, 0.644);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8;
  filter: blur(0px);
}
.game {
  z-index: 69;
  position: relative;
}
@media (min-width: 1200px) {
  .game-frame.fullscreen {
    top: 7vh;
    height: calc(100% - 7vh);
  }
  .disable-fullscreen {
    width: 5rem;
    right: 2rem;
    top: 6rem;
  }
}
/* MOBILE */
.mobile-frame-container {
  display: flex;
  max-height: 100vh;
  height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  align-items: flex-start;
  position: relative;
}
.game-background-mobile,
.game-background-overlap-mobile {
  z-index: 59;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
}
.game-background-overlap-mobile {
  background: rgba(0, 0, 0, 0.644);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8;
  filter: blur(0px);
}
.game-frame.mobile {
  border-radius: 0px;
  box-shadow: none;
  /* min-height: 90vh; */
  max-height: 100vh;
  height: 100%;
  min-width: 100vw;
  z-index: 69;
  position: relative;
  overflow: hidden !important;
}

.buttons-container {
  display: flex;
  justify-content: flex-start;
  position: relative;
  bottom: 0;
}

.buttonTerms-Condition,
.buttonResponsible-Gaming {
  background-color: var(--light-orange);
  transition: var(--short-trans);
  z-index: 60;
  padding: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  text-decoration: none;
  margin-right: 10px;
}

.buttonTerms-Condition:hover,
.buttonResponsible-Gaming:hover {
  opacity: 0.8;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
  border: none;
  text-decoration: none;
}

.skill-main {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.skill-main .skill-wrrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.skill-main .skill-wrrap .skill-name {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
}
.skill-main .skill-wrrap .skill-bar {
  height: 20px;
  background-color: #00283a;
  border-radius: 8px;
  box-shadow: 0px -3px 4.4px 0px rgba(0, 0, 0, 0.45) inset,
    0px 1.786px 1.786px 0px rgba(0, 0, 0, 0.25), 0px 4px 4.9px 0px rgba(0, 0, 0, 0.25),
    0px 3px 10px -5px rgba(255, 255, 255, 0.44) inset,
    0px -2px 4px 0px rgba(0, 0, 0, 0.25);
}
.skill-main .skill-wrrap .skill-per {
  height: 20px;
  background: var(--dark-orange);
  border-radius: 8px;
  width: 0;
  transition: 1s linear;
  position: relative;
  box-shadow: 0px -3px 4.4px 0px rgba(0, 0, 0, 0.45) inset,
    0px 1.786px 1.786px 0px rgba(0, 0, 0, 0.25), 0px 4px 4.9px 0px rgba(0, 0, 0, 0.25),
    0px 3px 10px -5px rgba(255, 255, 255, 0.44) inset,
    0px -2px 4px 0px rgba(0, 0, 0, 0.25);
}
.skill-main .skill-wrrap .skill-per:before {
  content: attr(per);
  position: absolute;
  padding: 4px 6px;
  background-color: var(--dark-orange);
  border: 1px solid #00000047;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  border-radius: 4px;
  top: -3px;
  right: -5px;
  transform: translateX(50%);
  border-radius: 21px;
  box-shadow: 0px -3px 4.4px 0px rgba(0, 0, 0, 0.45) inset,
    0px 1.786px 1.786px 0px rgba(0, 0, 0, 0.25), 0px 4px 4.9px 0px rgba(0, 0, 0, 0.25),
    0px 3px 10px -5px rgba(255, 255, 255, 0.44) inset,
    0px -2px 4px 0px rgba(0, 0, 0, 0.25);
}
.reload-session-warning {
  color: var(--darker-grey);
  line-height: 1.44;
  font-weight: bold;
  font-size: 14px;
}
.boxopen,
.boxbonus {
  background-color: var(--darker-grey);
  color: white;
  border-bottom: 1px solid white;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0px -3px 4.4px 0px rgba(0, 0, 0, 0.45) inset,
    0px 1.786px 1.786px 0px rgba(0, 0, 0, 0.25), 0px 4px 4.9px 0px rgba(0, 0, 0, 0.25),
    0px 3px 10px -5px rgba(255, 255, 255, 0.44) inset,
    0px -2px 4px 0px rgba(0, 0, 0, 0.25);
}

.boxopen p {
  transition: 0.3s all;
  color: white;
}

.box .boxbonus p {
  color: #fff;
  font-size: 14px;
}
.boxbonus .bonus {
  background-color: var(--dark-orange);
  border-color: var(--dark-orange);
  color: #111;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0 0.5rem 0 0.5rem;
  width: fit-content;
  border-radius: 3px;
}
.boxbonusmobile {
  z-index: 99999999;
  top: 0;
  position: fixed;
}

.information-turnover b {
  text-transform: capitalize !important;
  color: white !important;
}

@media only screen and (max-width: 769px) {
  .boxbonus p {
    font-size: 12px;
  }

  .information-turnover {
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  .container-mobile {
    justify-content: center !important;
    width: 100%;
    margin: 5px 0px !important;
  }

  .mode-type {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
  }

  .wrapper-skill-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
  }
}
.openBox {
  position: absolute;
  z-index: 9999;
  bottom: -24px;
  margin: 0 auto;
  text-align: center;
}
.openBox span {
  background-color: var(--darker-grey);
  border: 1px solid #ffff;
  padding: 3px 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #fff;
  border-top: 0px;
}
.openBox img {
  width: 18px;
  height: 12px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.openBox .arrowopen {
  transform: rotate(180deg);
}
.openBox .openBonus {
  transition: all 2s ease-in-out;
}
</style>
