export default {
	endpoint(_, _1, _2, rootGetters) {
		return rootGetters['api/endpoint'];
	},
	axios(_, _1, _2, rootGetters) {
		return rootGetters['api/axios'];
	},
	auth(_, _1, _2, rootGetters) {
		return rootGetters['api/auth'];
	},
	skinId(_, _1, _2, rootGetters) {
		return rootGetters['skinSettings/skinId'];
	},
	translations(_, _1, _2, rootGetters) {
		return rootGetters['translations/translations'];
	},
	basicKYCLevel(_, _1, _2, rootGetters) {
		return rootGetters['player/basicKYCLevel'];
	},
	registration_nickname(state) {
		return state.registration_nickname;
	},
	registration_password(state) {
		return state.registration_password;
	},
	isNicknameInvalid(state) {
		return state.isNicknameInvalid;
	},
	nicknameErrorMessage(state) {
		return state.nicknameErrorMessage;
	},
	passwordValidated(state) {
		return state.passwordValidated;
	},
	isEmailInvalid(state) {
		return state.isEmailInvalid;
	},
	emailErrorMessage(state) {
		return state.emailErrorMessage;
	},
	emailValidated(state) {
		return state.emailValidated;
	},
	isMobileInvalid(state) {
		return state.isMobileInvalid;
	},
	isTwilioOkay(state) {
		return state.isTwilioOkay
	},
	mobileErrorMessage(state) {
		return state.mobileErrorMessage;
	},
	isFieldsInvalid(state) {
		return state.isFieldsInvalid;
	},
	fieldsErrorMessage(state) {
		return state.fieldsErrorMessage;
	},
	isPasswordInvalid(state) {
		return state.isPasswordInvalid;
	},
	passwordErrorMessage(state) {
		return state.passwordErrorMessage;
	},
	passwordMatchControl(state) {
		return state.passwordMatchControl;
	},
	confirmPasswordFieldError(state) {
		return state.confirmPasswordFieldError;
	},
	verificationPlayerID(state) {
		return state.verificationPlayerID;
	},
	isFirstStepComplete(state) {
		return (
			state.isEmailInvalid ||
			state.isNicknameInvalid ||
			state.isPasswordInvalid ||
			!state.passwordMatchControl
			// ||
			// state.isMobileInvalid
		);
	},
	verificationConfirmed(state) {
		return state.verificationConfirmed;
	},
	codeMailCorrect(state) {
		return state.codeMailCorrect;
	},
	codeSmsCorrect(state) {
		return state.codeSmsCorrect;
	},
	isSMSCalled(state) {
		return state.isSMSCalled;
	},
	sendVerificationIsOkay(state) {
		return state.sendVerificationIsOkay
	}
	// RECOVERY EMAIL
	// isEmailSent(state) {
	// 	return state.isEmailSent;
	// },
};
