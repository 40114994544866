import playerGetters from './authPlayer/getters.js';
import playerMutations from './authPlayer/mutations.js';
import playerActions from './authPlayer/actions.js';

export default {
	namespaced: true,
	state() {
		return {
			path: {
				login: 'auth/login',
				logout: 'auth/logout',
				password_recovery: 'auth/password/recovery',
				information: 'me?kyc=1&marketing=1&riskyCountry=1',
				edit_information: 'me',
				documents: 'me/documents',
				allDocuments: 'me/all-documents',
				messages: 'me/messages/',
				lastStatistic: 'me/statistics/last',
				removeChanges: 'me/statistics/',
				balance: 'me/balance',
				reset_password: 'auth/password/reset',
				check_token_status: 'auth/status/',
				check_token_kyc: 'kyc/accessTokens/',
				survey: 'me/survey/',
				amlLevel: 'me/aml/level/current/',
			},
			token: null,
			kyc_token: null,
			temp_token: null,
			isTempTokenValid: false,
			errorMessage:false,
			basicKYCLevel: 'BasicAmlLevel',
			selfieKYCLevel: 'ID_SELFIE_2NDID_POA',
			playerInfo: {},
			playerDocuments: {},
			playerBalance: {},
			playerSurvey: {},
			playerStatistic: {},
			playerCampaign: {},
			isLoading: true,
			isCheckingToken: false,
			isPlayerLogged: false,
			playerAml: {},
			isMajorLimit:false,
			status_reason: 'Requested by user',
			selectedDocumentToUpload: {value: null, default: 'passport'},
		};
	},
	mutations: playerMutations,
	actions: playerActions,
	getters: playerGetters,
};
