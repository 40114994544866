import Swal from "sweetalert2";
import router from "@/router";
import VueCookies from "vue-cookies";

export default {
  namespaced: true,
  state() {
    return {
      error_server: "Error Server",
      isReadOneOpen: false,
      confirmButtonColor: "var(--light-orange)",
      cancelButtonColor: "var(--darker-grey)",
      denyButtonColor: "var(--dark-red)",
    };
  },
  mutations: {
    error_server(state) {
      Swal.fire(state).then((response) => {
        if (response.isConfirmed) {
          router.replace("/");
        } else {
          router.replace("/");
        }
      });
    },
    simple_alert(state, { message, path }) {
      Swal.fire({
        title: message,
        confirmButtonColor: state.confirmButtonColor,
      }).then((response) => {
        if (path) {
          if (response.isConfirmed) {
            router.replace(path);
          } else {
            router.replace(path);
          }
        } else {
          if (response.isConfirmed) {
            location.reload();
          } else {
            location.reload();
          }
        }
      });
    },
    top_right_notification(_, { message, status }) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      if (status == "success") {
        Toast.fire({
          icon: "success",
          title: message,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: message,
        });
      }
    },
  },
  actions: {
    logAlert({ commit, getters }, payload) {
      if (payload == "") {
        commit("simple_alert", {
          message: getters.translations.login.Logout_completed,
          path: "/",
        });
      } else if (Object.prototype.hasOwnProperty.call(payload, "token")) {
        commit("simple_alert", {
          message: getters.translations.login.Login_completed,
          path: { name: "informations" },
        });
      } else {
        if (sessionStorage.getItem("savedPath") && sessionStorage.getItem("savedPath") !== '/informations') {
          let savedPath = sessionStorage.getItem("savedPath");
          window.location.href = savedPath;
        } else {
          commit("simple_alert", {
            message: getters.translations.login.something_wrong,
            path: { name: "informations" },
          });
        }
      }
    },
    verifyPhone({ state, getters, dispatch }, playerId) {
      Swal.fire({
        confirmButtonColor: state.confirmButtonColor ?? "orange",
        denyButtonColor: state.denyButtonColor ?? "red",
        showDenyButton: true,
        title: getters.translations.buttons.Confirm_phone ?? "Confirm Phone",
        confirmButtonText: getters.translations.buttons.Confirm ?? "Confirm",
        denyButtonText: getters.translations.buttons.back ?? "Back",
        text:
          getters.translations.registration.Mobile_confirmation_code ??
          "Enter the code sent via text message",
        input: "text",
        inputAttributes: {
          input: "text",
          required: "true",
        },
        inputValidator: (value) => {
          if (!value)
            return (
              getters.translations.myprofile.The_field_is_required ??
              "The field is required"
            );
          else return null;
        },
        inputPlaceholder: getters.translations.header.Code ?? "Code",
      }).then((result) => {
        if (result.isConfirmed) {
          let verify = result.value;
          let service = "sms";
          dispatch(
            "registration/contactVerification",
            { verification: verify, service: service, playerId: playerId },
            { root: true }
          ).then(() => { });
        } else {
          return false;
        }
      });
    },
    verifyMail({ state, getters, dispatch }, playerId) {
      Swal.fire({
        confirmButtonColor: state.confirmButtonColor ?? "orange",
        denyButtonColor: state.denyButtonColor ?? "red",
        showDenyButton: true,
        title: getters.translations.buttons.Confirm_mail ?? "Confirm Email",
        confirmButtonText: getters.translations.buttons.Confirm ?? "Confirm",
        denyButtonText: getters.translations.buttons.back ?? "Back",
        text:
          getters.translations.registration.Email_confirmation_code ??
          "Enter the code sent via mail",
        input: "text",
        inputAttributes: {
          input: "text",
          required: "true",
        },
        inputValidator: (value) => {
          if (!value)
            return (
              getters.translations.myprofile.The_field_is_required ??
              "The field is required"
            );
          else return null;
        },
        inputPlaceholder: getters.translations.header.Code ?? "Code",
      }).then((result) => {
        if (result.isConfirmed) {
          let verify = result.value;
          let service = "email";
          dispatch(
            "registration/contactVerification",
            { verification: verify, service: service, playerId: playerId },
            { root: true }
          ).then((
          ) => {
            if (getters.codeMailCorrect) {
              setTimeout(() => {
                window.location.href = "/";
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    recoverPasswordAlert({ commit, getters }, payload) {
      console.log(payload);
      commit("simple_alert", {
        message: getters.translations.registration.please_follow_instruction,
        path: "/login",
      });
    },
    manyAttemptsAlert({ commit, getters }, payload) {
      console.log(payload);
      commit("simple_alert", {
        message:
          getters.translations.login.recoveryAccount ??
          "Try contacting customer service to recover your account",
        path: "/login",
      });
    },
    topRightNotification({ getters, commit }, { responseData, service }) {
      if (Object.prototype.hasOwnProperty.call(responseData, "data")) {
        if (responseData.data.status == "success") {
          if (service == "sms") {
            commit("top_right_notification", {
              message: getters.translations.registration.sms_is_verified,
              status: "success",
            });
          } else if (service == "email") {
            commit("top_right_notification", {
              message: getters.translations.registration.email_is_verified,
              status: "success",
            });
          }
        }
      } else {
        if (Object.prototype.hasOwnProperty.call(responseData, "response")) {
          let res = responseData.response;
          console.log(res);
          if((Object.prototype.hasOwnProperty.call(res, "message")))
          {
            commit("top_right_notification", {
              message: res.message,
              status: "warning",
            });
          }
          else
          {
            commit("top_right_notification", {
              message: getters.translations.registration.code_is_wrong,
              status: "warning",
            });
          }
         
        } else {
          commit("error_server");
        }
      }
    },
    readOne_message({ state, getters, dispatch }, payload) {
      if (getters.isTranslationLoading) {
        return;
      }

      const message = payload[0];
      let confirmButtonTextAlert =
        getters.translations.buttons.Confirm_red_message;
      let denyButtonTextAlert = getters.translations.buttons.Show_later;
      let divElement = '<div style="white-space: pre-line">';
      let showDeny = true;
      let popupError = false;
      //
      let remaninigTimeWarning =
        getters.translations.reports.remaninig_Time_Warning ??
        "Please choose within the time what you want to do, in case you don't have to do anything your game session will be restored in";
      let remainingTime = parseInt(message.duration) || 60; // initial timer for alert
      let timerInterval; // countdown live  60 sec

      if (message.type == "realityCheck") {
        confirmButtonTextAlert = getters.translations.reports.keep_playing;
        denyButtonTextAlert = getters.translations.reports.return_Home;
        divElement = "<div>";

        // timerfor alert text to strat new session o return to home
        timerInterval = setInterval(() => {
          Swal.update({
            html: `<p class="mb-2 reload-session-warning">${remaninigTimeWarning} (${remainingTime}s)</p>${divElement}${message.body}</div>`,
          });
          remainingTime--;

          // when timer is 0 , clear interval and dispatch the action
          if (remainingTime < 0) {
            clearInterval(timerInterval);
            handleTimeout();
          }
        }, 1000);
      }

      if (message.type == "firstLogin") {
        confirmButtonTextAlert =
          getters.translations.registration.set_limits_btn;
        denyButtonTextAlert = getters.translations.myprofile.Cancel;
      }


      if (message.type == "campaignReached") {
        confirmButtonTextAlert = getters.translations.buttons.refresh_balance ?? 'Refresh Balance';
        denyButtonTextAlert = getters.translations.buttons.return_website ?? 'Return to website';
      }

      let BonusPopupError = ["bonus-bet-limit", "bonus-min-balance-limit"];
      let limitPopupError = ["daily-bet-limit", "daily-bet-loss"];
      let isLimitPopup = false;

      if (
        BonusPopupError.includes(message.type) ||
        limitPopupError.includes(message.type)
      ) {
        showDeny = false;
        popupError = true;

        if (limitPopupError.includes(message.type)) {
          confirmButtonTextAlert =
            getters.translations.buttons.see_limits ?? "See your limits";
          isLimitPopup = true;
        } else {
          confirmButtonTextAlert =
            getters.translations.buttons.reload_page ?? "Reload the page";
        }
      }

      Swal.fire({
        title: message.subject ? message.subject : "",
        confirmButtonColor: state.confirmButtonColor,
        confirmButtonText: confirmButtonTextAlert
          ? confirmButtonTextAlert
          : "OK!",
        showDenyButton: showDeny,
        denyButtonColor: state.denyButtonColor,
        denyButtonText: denyButtonTextAlert ? denyButtonTextAlert : "Later",
        html:
          message.type == "realityCheck"
            ? `<p>${remaninigTimeWarning} (${remainingTime}s)</p>${divElement}${message.body}</div>`
            : `${divElement}${message.body}</div>`,
        imageUrl: message.image ? message.image : undefined,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: message.subject,
      }).then((result) => {
        if (
          result.isConfirmed ||
          message.type == "realityCheck" ||
          message.type == "firstLogin" ||
          message.type == "campaignReached"
        ) {
          if (message.type == "realityCheck" || popupError) {
            // alert(1);
            // alert(message.id);
            dispatch("player/messageHasBeenRed", message.id, {
              root: true,
            }).then(() => {
              if (popupError && isLimitPopup) {
                let lang = VueCookies.get("language") || "en";
                window.location.href = "/" + lang + "/limits";
              } else {
                clearInterval(timerInterval);
              }
            });
          } else {
            dispatch("player/messageHasBeenRed", message.id, {
              root: true,
            }).then(() => {
              clearInterval(timerInterval);
            });
          }
        }

        if (result.isConfirmed && message.type == "firstLogin") {
          router.replace("/dashboard/limits");
        }

        if (message.type == "campaignReached") {
          if (result.isConfirmed)
            location.reload();
          else
            location.href = "/";
        }


        if (!result.isConfirmed && message.type == "realityCheck") {
          clearInterval(timerInterval);
          dispatch("player/messageHasBeenRed", message.id, { root: true }).then(
            () => {
              router.replace("/");
            }
          );
        }
        if (message.type !== "realityCheck") {
          dispatch("player/syncMessage", {}, { root: true });
        }
      });

      function handleTimeout() {
        Swal.close(); // Close the popup
        dispatch("player/messageHasBeenRed", message.id, { root: true }).then(
          () => {
            location.reload();
          }
        );
      }
    },
    alertConfirmOrDeny({ state, getters, dispatch }, { message, body }) {
      const confirmButtonText = getters.translations.buttons.Confirm;
      const denyButtonText = getters.translations.buttons.back;
      Swal.fire({
        confirmButtonColor: state.confirmButtonColor,
        denyButtonColor: state.denyButtonColor,
        showDenyButton: true,
        title: message.title ? message.title : "",
        confirmButtonText: message.confirmButtonText
          ? message.confirmButtonText
          : confirmButtonText,
        denyButtonText: message.denyButtonText
          ? message.denyButtonText
          : denyButtonText,
        text: message.text,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(body.dispatch, body.payload, { root: true });
        } else {
          return false;
        }
      });
    },
  },
  getters: {
    translations(_, _1, _2, rootGetters) {
      return rootGetters["translations/translations"];
    },
    isTranslationLoading(_, _1, _2, rootGetters) {
      return rootGetters["translations/isTranslationLoading"];
    },
    codeMailCorrect(_, _1, _2, rootGetters) {
      return rootGetters["registration/codeMailCorrect"];
    },
  },
};
