<template>
  <section>
    <base-loading v-if="isTranslationLoading"></base-loading>
    <base-dashboard-section v-else>
      <template #nameSection>{{ translations.myprofile.Account_Limits }}</template>
      <template #default>
        <div class="container">
          <div class="row d-flex justify-content-center">
            <form @submit.prevent>
              <div class="form-group row mt-4 mb-5">
                <div class="col-12">
                  <b>{{ translations.myprofile.Account_Limits }}</b>
                </div>

                <div class="col-12 col-md-4 mt-3">
                  <label class="form-label" for="max_daily_deposit"
                    >{{ translations.myprofile.Maximum_Daily_Deposit }}:</label
                  >

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="max_daily_deposit">{{
                        playerInfo?.currency?.symbol ?? ""
                      }}</span>
                    </div>
                    <input
                      id="max_daily_deposit"
                      type="text"
                      min="0"
                      class="form-control"
                      v-model="max_daily_deposit"
                      :disabled="lastDailyMax.activated_at"
                      aria-describedby="max_daily_deposit"
                      @input="enableLimitsButton()"
                    />
                  </div>
                  <div v-if="lastDailyMax.activated_at">
                    <small v-if="lastDailyMax.status == 'pending'" class="mb-0 d-block"
                      >{{ translations.dashboard_menu.as_of }}
                      {{ lastDailyMax.activated_at }} UTC,
                      {{ translations.dashboard_menu.limit_is }}
                      € {{ lastDailyMax.response.max_daily_deposit ?? 0 }}
                    </small>
                    <small class="mb-0 d-block">
                      {{ translations.dashboard_menu.no_request }}
                      {{ lastDailyMax.activated_at }} UTC
                    </small>
                    <small
                      class="mb-0 d-block deleterequest"
                      v-if="lastDailyMax.status == 'pending'"
                      @click="removeChanges(lastDailyMax.id)"
                      >{{ translations.dashboard_menu.delete_request }}
                    </small>
                  </div>
                </div>

                <div class="col-12 col-md-6 mt-3" v-if="0">
                  <label class="form-label" for="max_weekly_deposit"
                    >{{ translations.myprofile.Maximum_Weekly_Deposit }}:</label
                  >

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="max_weekly_deposit">{{
                        playerInfo?.currency?.symbol ?? ""
                      }}</span>
                    </div>

                    <input
                      id="max_weekly_deposit"
                      type="text"
                      class="form-control"
                      v-model="max_weekly_deposit"
                      :disabled="lockLimits"
                      aria-describedby="max_weekly_deposit"
                      @input="enableLimitsButton()"
                    />
                  </div>
                </div>

                <div class="col-12 col-md-6 mt-3" v-if="0">
                  <label class="form-label" for="max_monthly_deposit"
                    >{{ translations.myprofile.Maximum_Monthly_Deposit }}:</label
                  >

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="max_monthly_deposit">
                        {{ playerInfo?.currency?.symbol ?? "" }}
                      </span>
                    </div>

                    <input
                      id="max_monthly_deposit"
                      type="text"
                      class="form-control"
                      v-model="max_monthly_deposit"
                      :disabled="lockLimits"
                      aria-describedby="max_monthly_deposit"
                      @input="enableLimitsButton()"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4 mt-3">
                  <label class="form-label" for="max_daily_bet">
                    {{ translations.myprofile.Max_daily_bet ?? "Max daily Bet" }}:</label
                  >

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="max_daily_bet">
                        {{ playerInfo?.currency?.symbol ?? "" }}
                      </span>
                    </div>

                    <input
                      id="max_daily_bet"
                      type="text"
                      class="form-control"
                      v-model="max_daily_bet"
                      @input="enableLimitsButton()"
                      :disabled="lastDailyBet.activated_at"
                      aria-describedby="max_daily_bet"
                    />
                  </div>
                  <div v-if="lastDailyBet.activated_at">
                    <small v-if="lastDailyBet.status == 'pending'" class="mb-0 d-block"
                      >{{ translations.dashboard_menu.as_of }}
                      {{ lastDailyBet.activated_at }} UTC,
                      {{ translations.dashboard_menu.limit_is }}
                      € {{ lastDailyBet.response.max_daily_bet ?? 0 }}
                    </small>
                    <small class="mb-0 d-block">
                      {{ translations.dashboard_menu.no_request }}
                      {{ lastDailyBet.activated_at }} UTC
                    </small>
                    <small
                      class="mb-0 d-block deleterequest"
                      v-if="lastDailyBet.status == 'pending'"
                      @click="removeChanges(lastDailyBet.id)"
                      >{{ translations.dashboard_menu.delete_request }}
                    </small>
                  </div>
                </div>
                <div class="col-12 col-md-4 mt-3">
                  <label class="form-label" for="max_daily_loss">Max daily Loss:</label>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="max_daily_loss">
                        {{ playerInfo?.currency?.symbol ?? "" }}
                      </span>
                    </div>

                    <input
                      id="max_daily_loss"
                      type="text"
                      class="form-control"
                      :disabled="lastDailyLoss.activated_at"
                      @input="enableLimitsButton()"
                      v-model="max_daily_loss"
                      aria-describedby="max_daily_loss"
                    />
                  </div>
                  <div v-if="lastDailyLoss.activated_at">
                    <small v-if="lastDailyLoss.status == 'pending'" class="mb-0 d-block"
                      >{{ translations.dashboard_menu.as_of }}
                      {{ lastDailyLoss.activated_at }} UTC,
                      {{ translations.dashboard_menu.limit_is }}
                      € {{ lastDailyLoss.response.max_daily_loss ?? 0 }}
                    </small>
                    <small class="mb-0 d-block">
                      {{ translations.dashboard_menu.no_request }}
                      {{ lastDailyLoss.activated_at }} UTC
                    </small>
                    <small
                      class="mb-0 d-block deleterequest"
                      v-if="lastDailyLoss.status == 'pending'"
                      @click="removeChanges(lastDailyLoss.id)"
                      >{{ translations.dashboard_menu.delete_request }}
                    </small>
                  </div>
                </div>
                <div class="col-12 col-md-4 mt-3" v-if="0">
                  <label class="form-label" for="max_daily_playing_time"
                    >Max daily Playing Time:</label
                  >

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="max_daily_playing_time">
                        <i class="bi bi-clock"></i>
                      </span>
                    </div>

                    <input
                      id="max_daily_playing_time"
                      type="text"
                      :disabled="lockLimits"
                      class="form-control"
                      v-model="max_daily_playing_time"
                      @input="enableLimitsButton()"
                      aria-describedby="max_daily_playing_time"
                    />
                  </div>
                </div>

                <div v-if="!lockLimits" class="col-12 col-md-6 mt-3">
                  <label class="form-label">&nbsp;</label>
                  <base-button
                    color="orange"
                    :caption="translations.buttons.Set_limits.toUpperCase()"
                    nomargin="true"
                    :disabled="isButtonLimitsDisabled"
                    @click="callModifyLimits"
                  ></base-button>
                </div>
                <div v-else class="col-12 mt-3">
                  {{ translations.dashboard_menu.limits24 }}
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="container">
          <div class="row d-flex justify-content-center">
            <form @submit.prevent>
              <div class="form-group row d-flex mt-4 mb-5">
                <div class="col-12 mb-2">
                  <b>{{ translations.footer.self_exclusion }}</b>
                </div>

                <div class="container" v-if="!lockExclusions">
                  <div class="col-12 col-md-6 mt-3">
                    <select
                      class="form-control"
                      id="newTime"
                      @change="changeTime($event)"
                      v-model="newTime"
                    >
                      <option value="" selected>
                        {{
                          translations.dashboard_menu.select_period ??
                          "Select a period of time"
                        }}
                      </option>
                      <option v-for="time in times" :key="time" :value="time">
                        {{
                          time == "forever"
                            ? translations.dashboard_menu.forever ?? "Forever"
                            : "+" +
                              time +
                              " " +
                              translations.registration.Days.toLowerCase()
                        }}
                      </option>
                    </select>
                    <!--  
									<div @click="setExpirationDate(time)" v-for="time in times" :key="time" class="col-12 col-md-3 mt-3">
										<button v-if="time =='forever'" class="date-input w-100">
											{{time}}
										</button>
										<button v-else class="date-input w-100">
											+{{time}} {{ translations.registration.Days.toLowerCase() }}
										</button>
									</div> -->
                  </div>
                </div>
                <div v-else>
                  <div v-if="exclusionTemp.status == 'pending'">
                    <b>{{ translations.dashboard_menu.pending_request }}</b>
                    <p class="mb-0">
                      {{ translations.dashboard_menu.as_of }}
                      {{ exclusionTemp.activated_at }} UTC,
                      {{ translations.dashboard_menu.locked_until }}
                      {{ exclusionTemp.newTimeExpired }},
                      {{ translations.dashboard_menu.after_approved }}
                    </p>
                    <!-- 
											<p>{{translations.dashboard_menu.cancel_request}}
												<button class="date-input  " @click="removeChanges(exclusionTemp.id)">{{translations.dashboard_menu.click_here}}</button>
											</p>-->
                  </div>
                  <p>
                    {{ translations.dashboard_menu.no_request }}
                    {{ exclusionTemp.activated_at }} UTC
                  </p>
                </div>
                <div class="col-12 m-0" v-if="newExpirationDate != null">
                  <div class="col-12 col-md-6 mt-3">
                    <label class="form-label" for="date_expires_at">
                      {{
                        translations.dashboard_menu.date_of_self_exclusion ??
                        "Date of Self exclusion"
                      }}
                    </label>
                    <input
                      @change="setExclusionButton"
                      id="date_expires_at"
                      readonly
                      type="date"
                      class="form-control"
                      v-model="newExpirationDate"
                      :min="minExpiration"
                    />
                  </div>
                  <div class="col-12 col-md-6 mt-3">
                    <label class="form-label">&nbsp;</label>
                    <base-button
                      color="orange"
                      :caption="translations.buttons.Set_exclusion.toUpperCase()"
                      nomargin="true"
                      @click="callSelfExclusion"
                      :disabled="exclusionButtonDisabled"
                    ></base-button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </template>
    </base-dashboard-section>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      max_daily_deposit: null,
      max_weekly_deposit: null,
      max_monthly_deposit: null,
      max_daily_bet: null,
      max_daily_loss: null,
      times: [],
      newTime: "",
      max_daily_playing_time: null,
      buttonLimitsDisabled: true,
      minExpiration: null,
      min_expirations_days: 1,
      addedDays: null,
      lockForever: true,
      newExpirationDate: null,
      exclusionButtonDisabled: true,
      defaultDate: null,
      lockLimits: false,
      exclusionTemp: { newTimeExpired: null, activated_at: null },
      lockExclusions: false,
      lastDailyMax: {},
      lastDailyBet: {},
      lastDailyLoss: {},
    };
  },
  computed: {
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    ...mapGetters("player", [
      "playerInfo",
      "isMajorLimit",
      "statisticExclusions",
      "statisticLimits",
    ]),
    ...mapGetters("api", ["refId"]),
    isButtonLimitsDisabled() {
      return this.buttonLimitsDisabled;
    },
  },
  watch: {
    newExpirationDate(value) {
      if (value == null) {
        this.exclusionButtonDisabled = true;
      } else if (value == this.minExpiration) {
        this.exclusionButtonDisabled = true;
      } else if (value < this.minExpiration) {
        this.exclusionButtonDisabled = false;
      } else {
        this.exclusionButtonDisabled = false;
      }
      this.defaultDate = this.newExpirationDate;
    },
  },
  methods: {
    setExpirationDate(days) {
      let expiration = new Date();

      if (days == "forever") expiration = new Date("2999-12-31");
      else expiration.setDate(expiration.getDate() + days);

      if (days == "") this.newExpirationDate = null;
      else {
        this.newExpirationDate = expiration.toISOString().slice(0, 10);
        this.defaultDate = this.newExpirationDate;
      }
    },
    changeTime() {
      this.setExpirationDate(this.newTime);
    },
    callLastStatistic() {
      //limits
      this.$store
        .dispatch("player/callLastStatistic", {
          type: "limits",
          to_activate: 1,
        })
        .then(() => {
          if (this.statisticLimits.length > 0) {
            let i;
            let stats = this.statisticLimits;
            for (i = 0; i < stats.length; i++) {
              if (stats[i].field == "max_daily_loss") this.lastDailyLoss = stats[i];
              else if (stats[i].field == "max_daily_bet") this.lastDailyBet = stats[i];
              else if (stats[i].field == "max_daily_deposit")
                this.lastDailyMax = stats[i];
            }
          }
        });
      // self-exclusions
      this.$store
        .dispatch("player/callLastStatistic", {
          type: "self-exclusions",
          to_activate: 1,
        })
        .then(() => {
          if (this.statisticExclusions.length > 0) {
            let first = this.statisticExclusions.shift();

            let dateAct = new Date(first.activated_at);
            let now = new Date(new Date().toISOString().slice(0, -1));
            let diffAct = now.getTime() - dateAct.getTime();

            if (diffAct <= 0) {
              this.lockExclusions = true;
              this.exclusionTemp.id = first.id;
              this.exclusionTemp.newTimeExpired = first.response.status_expires_at;
              this.exclusionTemp.activated_at = first.activated_at;
              this.exclusionTemp.status = first.status;

              let date = new Date(first.created_at);
              var diff = now.getTime() - date.getTime();
              if (diff > 0) this.lockExclusions = true;
            }
          }
        });
    },
    setTimes() {
      let days = [1, 2, 3, 4, 5, 6, 7, 14, 30, 60, 90, 180, 360];
      let times = [];
      let newTime = new Date();
      for (let i = 0; i < days.length; i++) {
        newTime = new Date().getTime() + days[i] * 86400000;
        if (newTime >= new Date(this.minExpiration).getTime()) this.lockForever = false;

        times.push(days[i]);
      }

      times.push("forever");
      this.times = times;
    },
    setPlayeDefaultData() {
      if (this.playerInfo.limits.max_daily_deposit != null) {
        this.max_daily_deposit = this.playerInfo.limits.max_daily_deposit;
      }
      if (this.playerInfo.limits.max_weekly_deposit != null) {
        this.max_weekly_deposit = this.playerInfo.limits.max_weekly_deposit;
      }
      if (this.playerInfo.limits.max_monthly_deposit != null) {
        this.max_monthly_deposit = this.playerInfo.limits.max_monthly_deposit;
      }

      if (this.playerInfo.betLimits.max_daily_bet != null) {
        this.max_daily_bet = this.playerInfo.betLimits.max_daily_bet;
      }
      if (this.playerInfo.betLimits.max_daily_loss != null) {
        this.max_daily_loss = this.playerInfo.betLimits.max_daily_loss;
      }
      if (this.playerInfo.betLimits.max_daily_playing_time != null) {
        this.max_daily_playing_time = this.playerInfo.betLimits.max_daily_playing_time;
      }
      if (this.playerInfo.status_expires_at != null) {
        this.minExpiration = this.playerInfo.status_expires_at.slice(0, 10);
        this.newExpirationDate = this.playerInfo.status_expires_at.slice(0, 10);
        this.defaultDate = this.minExpiration;
      } else {
        this.defaultDate = new Date().toISOString().slice(0, 10);
        this.setExpirationDate(0);
        this.minExpiration = this.newExpirationDate;
      }
      this.setTimes();
    },
    enableLimitsButton() {
      this.max_daily_deposit =
        parseInt(this.max_daily_deposit, 10) || this.max_daily_deposit;
      this.max_daily_deposit = String(this.max_daily_deposit)
        .replace(/[^0-9]/g, "")
        .substring(0, 10);

      this.max_daily_bet = parseInt(this.max_daily_bet, 10) || this.max_daily_bet;
      this.max_daily_bet = String(this.max_daily_bet)
        .replace(/[^0-9]/g, "")
        .substring(0, 10);

      this.max_daily_loss = parseInt(this.max_daily_loss, 10) || this.max_daily_loss;
      this.max_daily_loss = String(this.max_daily_loss)
        .replace(/[^0-9]/g, "")
        .substring(0, 10);

      this.max_weekly_deposit = this.max_daily_deposit;
      this.max_monthly_deposit = this.max_daily_deposit;
      this.buttonLimitsDisabled = false;
    },
    removeChanges(id) {
      Swal.fire({
        title: this.translations.dashboard_menu.Attention ?? "Attention",
        html: this.translations.account_balance.Are_you_sure_undone ?? "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: this.translations.dashboard_menu.YesWant ?? "Yes, I Want",
        cancelButtonText:
          this.translations.dashboard_menu.NoThink ?? "No, I need to think about it",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("player/playerRemoveChanges", id).then(() => {
            Swal.fire({
              title: this.translations.dashboard_menu.removeChanges ?? "Request removed",
              icon: "success",
              showCancelButton: false,
              allowOutsideClick: false,
              confirmButtonText:
                this.translations.dashboard_menu.returnToSite ?? "Return to the site",
            }).then(() => {
              location.reload();
            });
          });
        }
      });
    },
    supportNotification(content_body = null, subject = null) {
      let ref_id = this.refId;
      let emails = ["support@kajot-casino.com"];

      this.$store
        .dispatch("player/supportNotification", {
          emails: emails,
          content_body: content_body,
          subject: subject,
          ref_id: ref_id,
        })
        .then(() => {
          location.reload();
        });
    },
    callSelfExclusion() {
      let activated = false;
      if (this.newExpirationDate < this.minExpiration) activated = true;

      if (activated) {
        Swal.fire({
          title: this.translations.dashboard_menu.Attention ?? "Attention",
          html:
            this.translations.dashboard_menu.SelfExclusionReason ??
            "Are you sure you want to self-exclude yourself from the game? In order for us to approve the requested reduction in your self-exclusion, we are required to ask for the reason. Please enter the reason below:",
          input: "textarea",
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: this.translations.dashboard_menu.YesWant ?? "Yes, I Want",
          cancelButtonText:
            this.translations.dashboard_menu.NoThink ?? "No, I need to think about it",
          preConfirm: (value) => {
            if (!value) {
              Swal.showValidationMessage(
                '<i class="fa fa-info-circle"></i> ' +
                  this.translations.dashboard_menu.reasonReq
              );
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            let today = new Date();

            let time =
              this.newExpirationDate +
              " " +
              today.getHours() +
              ":" +
              String(today.getMinutes()).padStart(2, "0") +
              ":" +
              String(today.getSeconds()).padStart(2, "0");

            this.$store
              .dispatch("player/playerSelfExclusion", {
                time: time,
                activated: activated,
                status_reason: result.value,
              })
              .then(() => {
                let playerId = this.playerInfo.user_id;
                let content_body =
                  "The user <b>" +
                  playerId +
                  "</b> would like to change the self exclusion date to " +
                  this.newExpirationDate +
                  " for the following reason:<br>" +
                  result.value +
                  "<br>" +
                  process.env.VUE_APP_URLBO +
                  "resources/players/" +
                  playerId +
                  "?tab=statistic";
                this.supportNotification(
                  content_body,
                  "Self exclusion date change request - Player " + this.playerInfo.user_id
                );
              });
          }
        });
      } else {
        Swal.fire({
          title: this.translations.dashboard_menu.Attention ?? "Attention",
          html:
            this.translations.dashboard_menu.SelfExclusion ??
            "Are you sure you want to self-exclude yourself from the game?",
          icon: "warning",
          type: "input",
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: this.translations.dashboard_menu.YesWant ?? "Yes, I Want",
          cancelButtonText:
            this.translations.dashboard_menu.NoThink ?? "No, I need to think about it",
        }).then((result) => {
          if (result.isConfirmed) {
            let today = new Date();
            let time =
              this.newExpirationDate +
              " " +
              today.getHours() +
              ":" +
              today.getMinutes() +
              ":" +
              today.getSeconds();
            this.$store
              .dispatch("player/playerSelfExclusion", {
                time: time,
                activated: activated,
              })
              .then(() => {
                location.reload();
              });
          }
        });
      }
    },
    callModifyLimits() {
      this.$store
        .dispatch("player/editPlayerLimits", {
          max_daily_deposit: +this.max_daily_deposit,
          max_monthly_deposit: +this.max_monthly_deposit,
          max_weekly_deposit: +this.max_weekly_deposit,
          max_daily_bet: +this.max_daily_bet,
          max_daily_loss: +this.max_daily_loss,
        })
        .then(() => {
          if (this.isMajorLimit) {
            location.reload();
            /*
            let playerId = this.playerInfo.id;
            let content_body = "The user <b>" +  playerId +
            "</b> has made some changes to the limits" + "<br>" +  process.env.VUE_APP_URLBO +
            "resources/players/" + playerId + "?tab=statistic";
            this.supportNotification(content_body,"Limits change request - Player " + this.playerInfo.id); */
          } else location.reload();
        });
    },
  },
  created() {
    this.setPlayeDefaultData();
    this.callLastStatistic();
  },
};
</script>

<style scoped>
input:disabled:hover {
  cursor: not-allowed;
}

#date_expires_at {
  background: transparent;
  border: 0px;
  padding: 0;
  z-index: -1;
  position: relative;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.deleterequest {
  color: var(--dark-orange);
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}
.date-input {
  padding: 5px 0px;
  background: var(--light-orange);
  border: 2px solid var(--dark-orange);
  border-radius: 10px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}
</style>
