<template>
	<base-dashboard-section>
		<template #nameSection>{{
			translations.myprofile.Update_Password
		}}</template>
		<template #default>
			<div class="container">
				<div class="row d-flex justify-content-center">
					<form @submit.prevent>
						<div class="form-group row mt-4 mb-5">
							<div class="col-12"><b></b></div>

							<div class="row d-flex align-items-center mt-3">
								<div v-if="showFields" class="col-12 col-md-6 mt-3">
									<label class="form-label" for="current_password"
										>{{ translations.myprofile.Current_Password }}:</label
									>

									<input
										id="current_password"
										type="password"
										class="form-control my-3"
										v-model.trim="current_pwd"
									/>
								</div>

								<div v-if="showFields" class="col-12 col-md-6 mt-3">
									<label class="form-label" for=""
										>{{ translations.myprofile.New_Password }}:</label
									>

									<input
										id="new_password"
										type="password"
										class="form-control my-3"
										v-model.trim="new_pwd"
										@keyup="enablingPasswordButton"
									/>
									<transition name="error-message">
										<p v-if="new_pwd_error" class="warning">
											{{ new_pwd_error_message }}
										</p>
									</transition>
								</div>

								<div v-if="showFields" class="col-12 col-md-6 mt-3">
									<label class="form-label" for=""
										>{{ translations.myprofile.Confirm_password }}:</label
									>

									<input
										id="confirm_password"
										type="password"
										class="form-control my-3"
										v-model.trim="confirm_pwd"
										@keyup="enablingPasswordButton"
									/>
									<transition name="error-message">
										<p v-if="confirm_pwd_error" class="warning">
											{{ confirm_pwd_error_message }}
										</p>
									</transition>
								</div>

								<div v-if="showFields" class="col-12 col-md-6 mt-3">
									<label class="form-label">&nbsp;</label>
									<base-button
										color="orange"
										:caption="translations.myprofile.Update_Password"
										:disabled="passwordButtonDisabled"
										nomargin="true"
										@click="changePlayerPassword"
									></base-button>
								</div>
							</div>
							<div v-if="showFields == false" class="row justify-content-center">
								<div class="col-12 col-md-6 mt-3 d-flex justify-content-center">
									<p class="text-center">{{ translations.myprofile.Password_cant_be_changed }}</p>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</template>
	</base-dashboard-section>
</template>

<script>
	import { mapGetters } from 'vuex';
	import Swal from 'sweetalert2';
	export default {
		mounted() {
			this.checkUserStatus();
		},
		data() {
			return {
				current_pwd: null,
				new_pwd: null,
				new_pwd_invalid: false,
				new_pwd_error: false,
				new_pwd_error_message: '',
				confirm_pwd: null,
				confirm_pwd_invalid: false,
				confirm_pwd_error: false,
				confirm_pwd_error_message: "The password doesn't match",
				passwordButtonDisabled: true,
				showFields: true,
			};
		},
		computed: { 
			...mapGetters('player', ['errorMessage']),
			...mapGetters('translations', ['isTranslationLoading', 'translations']),
			playerInfo() {
				return this.$store.getters['player/playerInfo'];
			}
		},
		watch: {
			confirm_pwd(value) {
				this.confirm_pwd_error = false;
				this.confirm_pwd_invalid = true;
				if (value.length < 5) {
					return false;
				} else {
					if (this.new_pwd != value) {
						this.confirm_pwd_error = true;
					} else {
						this.confirm_pwd_error = false;
						this.confirm_pwd_invalid = false;
					}
				}
			},
			new_pwd(value) {
				this.new_pwd_invalid = true;
				this.new_pwd_error = false;
				this.new_pwd_error_message = '';
				if (value.length == 0) {
					return false;
				} else if (value.length != 0) {
					if (this.validationPassword(value)) {
						this.new_pwd_invalid = false;
						this.new_pwd_error = false;
					} else {
						this.new_pwd_invalid = true;
						this.new_pwd_error = true;
						this.new_pwd_error_message =
							this.translations.registration.password_error;
					}
				}
			},
		},
		methods: {
			enablingPasswordButton() {
				if (
					this.new_pwd === this.confirm_pwd &&
					!this.new_pwd_invalid &&
					!this.confirm_pwd_invalid
				) {
					this.passwordButtonDisabled = false;
				} else {
					this.passwordButtonDisabled = true;
				}
			},
			changePlayerPassword() {
				let email_before_at = this.playerInfo.email.substring(0, this.playerInfo.email.indexOf("@")).toLowerCase();
				let current_pwd_to_lower = this.current_pwd.toLowerCase();
				let new_pwd_to_lower = this.new_pwd.toLowerCase();
				let username_to_lower = this.playerInfo.username.toLowerCase();

				if(new_pwd_to_lower == email_before_at || new_pwd_to_lower == username_to_lower){
					alert(this.translations.registration.password_nickname_email_check);
					return;
				}

				if(current_pwd_to_lower === new_pwd_to_lower){
					alert(this.translations.myprofile.You_must_choose_a_different_password);
					return;
				}

				this.$store.dispatch('player/changePlayerPassword', {
					current: this.current_pwd,
					new: this.new_pwd,
					confirm: this.confirm_pwd,
				}).then(() => {
					let icon = 'success'
					let msg = this.translations.myprofile.Update_Password;
					if(this.errorMessage)
					{
						msg = this.translations.registration.passwords_no_match
						icon = 'error';
					}

					Swal.fire({
						html: msg,
						icon: icon,
					}) 
				 });
			},
			validationPassword(value) {
				let result = String(value).match(
					/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/gm
				);
				return result;
			},
			checkUserStatus(){
				const token = this.playerInfo.token;
				this.$store.dispatch('player/checkTempToken', token)
				.then((data) => {
					let created_at = data.created_at;
					let updated_at = data.updated_at;

					if(created_at == updated_at){
						this.showFields = false;
					}
				});
			}
		}
	};
</script>
