<template>
  <section class="container py-5 my-5 position-relative">
    <div class="row d-flex justify-content-center">
      <div class="col col-sm-12 col-md-8 col-lg-6">
        <div v-if="$slots.title">
          <h1>
            <slot name="title"></slot>
          </h1>
        </div>
        <div class="content mt-4">
          <slot></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
h1 {
  font-size: 30px;
  color: #696969;
  padding-bottom: 5px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #b3b3b3;
  width: 100%;
  margin-bottom: 15px;
  line-height: 1.3;
  font-weight: 400;
}
.content {
  line-height: 20px;
  min-height: 65vh;
}
</style>
