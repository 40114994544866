<template>
  <div>
    <section>
        <div class="container maintenance">
            <div class="row">
                <div v-if="!isTranslationLoading" class="col-12 my-5 text-error text-center">{{
                    translations.reports.maintenance ?? "We're under maintenance. Please, try and come back later"
                }}</div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters('skinSettings', ['maintenance']),
        ...mapGetters("translations", ["isTranslationLoading", "translations"])
    },
    mounted() {
        if(!this.maintenance)
            window.location.href = "/";
    }
}
</script>

<style>
    .maintenance {
        min-height: 30vh !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>