<template>
	<div
		class="transaction my-4"
		v-for="transaction in transactionsList"
		:key="transaction.id"
	>
		<div class="row my-3 pt-2 d-flex justify-content-center align-items-center">
			<div class="col-4 text-center transaction-time">
				{{ transaction.created_at.slice(8, 10) }}/{{
					transaction.created_at.slice(5, 7)
				}}/{{ transaction.created_at.slice(0, 4) }} -
				{{ transaction.created_at.slice(11, 19) }}
			</div>
			<div class="col-4 text-center transaction-type">
				{{ transaction.type.name }}
			</div>
			<div
				class="col-4 text-center"
				:class="transactionStatusClass(transaction.status)"
			>
				{{ transaction.status }}
			</div>
		</div>
		<div class="row my-3 pb-2 d-flex justify-content-center align-items-center">
			<div class="col-4 text-center">
				<label for="" class="transaction-label">{{
					translations.account_balance.Description
				}}</label>
				{{ transaction.type.description }}
			</div>
			<div class="col-4 text-center">
				<label for="" class="transaction-label">{{
					translations.account_balance.Amount
				}}</label>
				{{ transaction.currency.symbol }}
				{{ (transaction.amount * 1).toFixed(2) }}
			</div>
			<div class="col-2 text-center">
				<label for="" class="transaction-label">{{
					translations.account_balance.Balance
				}}</label>
				{{ transaction.currency.symbol }}
				{{ (transaction.balance * 1).toFixed(2) }}
			</div>
			<div class="col-2 text-center">
				<label for="" class="transaction-label">ID</label>
				{{ transaction.id }}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['transactionsList', 'translations'],
		computed: {
			transactionStatusClass() {
				return (status) => {
					return {
						authorized: status == 'authorized',
						pending: status == 'pending',
						declined: status == 'declined',
						initialized: status == 'initialized',
						reverted: status == 'reverted',
					};
				};
			},
		},
	};
</script>

<style scoped>
	.transaction {
		box-shadow: var(--light-shadow);
		border: 2px solid whitesmoke;
		background: var(--extralight2-grey);
		border-radius: 10px;
		transition: var(--short-trans);
		transform: scale(1);
	}
	.transaction:hover {
		transform: scale(1.05);
		cursor: default;
	}
	.transaction-type {
		text-transform: uppercase;
		font-weight: 600;
	}
	.transaction-time {
		font-weight: 600;
		font-size: 0.8rem;
	}
	.transaction-label {
		display: block;
		text-align: center;
		font-size: 0.7rem;
		font-style: italic;
	}
	.initialized {
		color: rgb(47, 128, 187);
		text-transform: capitalize;
	}
	.authorized {
		color: green;
		text-transform: uppercase;
		font-size: 1.2rem;
		font-weight: bolder;
	}
	.declined {
		color: var(--dark-red);
		text-transform: uppercase;
		font-weight: bold;
	}
	.reverted {
		color: goldenrod;
		text-transform: capitalize;
		font-weight: bold;
	}
	.pending {
		color: var(--light-orange);
		font-style: italic;
	}
	.pending::after {
		content: '...';
		color: var(--light-orange);
	}
</style>
