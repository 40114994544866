<template>
  <div class="home" v-if="!isCasinoLoading">
    <carousel-full :banners="liveCasino_banners"></carousel-full>
    <div class="container-games">
      <games-filter-component
        :isDesktop="isDesktop"
        :games_providers="allSkinProviders"
        :sections="liveCasino_sections"
        :sectionsFilters="filters"
        :isCasinoLoading="isCasinoLoading"
      ></games-filter-component>
      <base-loading v-if="isLoadingCardGames && isLoadingBanners"></base-loading>
      <base-loading v-if="isLoadingCardGames"></base-loading>
      <card-game-container v-if="!isCasinoLoading && !isLoadingCardGames">
      </card-game-container>
    </div>
  </div>
</template>

<script>
import CarouselFull from "@/components/elements/CarouselFull.vue";
import CardGameContainer from "@/components/global/ui/CardGameContainer.vue";
import GamesFilterComponent from "@/components/elements/GamesFilterComponent.vue";
import { mapGetters } from "vuex";

export default {
  name: "LiveCasinoPage",
  components: {
    CarouselFull,
    CardGameContainer,
    GamesFilterComponent,
  },
  data() {
    return {
      filters: [{ label: "is_live", value: 1 }],
    };
  },
  computed: {
    ...mapGetters("casino", [
      "selectedGames",
      "isCasinoLoading",
      "liveCasino_sections",
      "allSkinProviders",
      "isLoadingCardGames",
      "reels_games_selected",
    ]),
    ...mapGetters("translations", ["translations"]),
    // ...mapGetters("device", ["isDesktop"]),
    ...mapGetters("device", ["isDesktop", "isTablet", "isMobile", "isDesktopSize"]),
    ...mapGetters("skinBanners", ["liveCasino_banners", "isLoadingBanners"]),
    selectedReels() {
      return this.reels_games_selected;
    },
  },
  beforeCreate() {},
  async created() {
    this.$store.dispatch("skinBanners/callBanners", "live-casino");
    let language = localStorage.getItem("language");
    this.$router.push({ params: { lang: language } });
    await this.$store.dispatch("casino/changingPage", { currentPage: 1 }).then(() => {
      this.$store.dispatch("casino/callSkinProviders", this.filters);
    });
  },
  mounted() {
    // const lang = localStorage.getItem("language") || "en";
    // const titles = {
    //   en: "Kajot Online Casino | Home",
    //   sk: "Kajot Online Casino | Home",
    //   cs: "Kajot Online Casino | Hlavní Stránka",
    //   de: "Kajot Online Casino | Startseite",
    //   hu: "Kajot Online Casino | Itthon",

    // };
    // document.title = titles[lang] || "Kajot Online Casino";
    document.title =
      this.translations?.body?.livePageTitle ?? "Kajot Online Casino | Live Casino";
  },
  methods: {
    viewGamewithReels(reels) {
      this.$store.dispatch("casino/viewGamebySelectedReels", reels);
      this.$store.dispatch("casino/changingPage", { currentPage: 1 });
    },
  },
};
</script>

<style scoped>
.predefined {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  overflow-x: auto;
}

.predefined ul {
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0px;
  padding: 5px 0px;
  text-align: center;
  white-space: nowrap;
}
.predefined .reel-games-btn {
  min-width: 35px;
  padding: 0px 10px;
  cursor: pointer;
  transition: var(--short-trans);
  color: var(--darker-grey);
  font-weight: 600;
  display: inline;
  position: relative;
}

.predefined .reel-games-btn::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  background-color: rgba(127, 2, 21, 0.7);
}

.predefined .reel-games-btn:last-child::after {
  display: none;
}

.predefined .reel-games-btn:hover {
  text-decoration: underline;
}

.actived {
  text-decoration: underline;
  color: var(--dark-orange) !important;
  font-weight: 600;
}

::-webkit-scrollbar {
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--dirty-white);
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dark-orange);
  border-radius: 2px;
  transition: var(--short-trans);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--light-orange);
  transition: var(--short-trans);
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .predefined {
    flex-wrap: nowrap;
  }

  .predefined .reel-games-btn {
    white-space: nowrap;
  }
}
</style>
