<template>
  <section>
    <base-info-page>
      <template #title>{{ page.name }}</template>
      <template #default>
        <div>
          <div class="body" v-html="page.content"></div>
        </div>
      </template>
    </base-info-page>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "SecurityPage",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("skinSettings", ["language", "page"]),
  },
  created() {
    const lang = this.$route.params.lang || this.language;

    this.$store
      .dispatch("skinSettings/callPage", this.$route.name + "-" + lang)
      .then(() => {
        document.title = this.page.seo.title;
        const description = this.page.seo.description;
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", description);
        const keywords = this.page.seo.keywords;
        const metaKeywords = document.createElement("meta");
        metaKeywords.setAttribute("name", "keywords");
        metaKeywords.setAttribute("content", keywords);
        document.querySelector("head").appendChild(metaKeywords);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  mounted() {
  
  },
};
</script>
