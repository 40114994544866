<template>
  <section>
    <div
      v-if="this.$route.name != 'game-page'"
      class="container-fluid fullpage"
    >
      <div class="row justify-content-center align-items-center">
        <div class="col-12">
          <div class="logo-img">
            <div
              class="text-center welcome my-5"
              v-if="$route.name !== 'game-page'"
            >
              {{ translations?.body?.welcomeTo ?? $t("body.welcomeTo") }}
            </div>
            <div class="text-center my-5">
              <img src="@/assets/img/logoprimeshadow.png" alt="Kajot Logo" />
            </div>
          </div>
        </div>
        <div class="col-12 my-5">
          <div class="container">
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-lg-3 d-flex justify-content-center">
                <div class="point point-1"></div>
                <div class="point point-2"></div>
                <div class="point point-3"></div>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div v-if="isTooSlow">
              <div class="container my-4">
                <div class="row justify-content-center">
                  <div class="col-10">
                    <div class="text-center text-error-small">
                      {{
                        translations?.body?.connectionSlow ??
                        $t("body.connectionSlow")
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      counter: 0,
    };
  },
  computed: {
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    isTooSlow() {
      if (this.counter > 12) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    setCounter(value) {
      const counter = () => {
        this.counter++;
      };
      const interval = setInterval(counter, 1000);
      if (value == "stop") {
        clearInterval(interval);
      }
    },
  },
  mounted() {
    this.setCounter("start");
  },
  beforeUnmount() {
    this.setCounter("stop");
  },
};
</script>

<style scoped>
.fullpage {
  max-width: 1920px;
  margin: 0 auto;
  float: none;
  height: 100vh;
  width: 100vw;
  padding-top: 20vh;
  background: var(--darker-grey);
}
.welcome {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--light-orange);
  text-shadow: var(--light-shadow);
  font-style: italic;
}
.point {
  width: 1rem;
  height: 1rem;
  margin: 0.7rem;
  position: relative;
  border-radius: 50%;
  -webkit-animation: bouncing 0.6s infinite alternate;
  -moz-animation: bouncing 0.6s infinite alternate;
  -o-animation: bouncing 0.6s infinite alternate;
  animation: bouncing 0.6s infinite alternate;
}
.point-2 {
  animation-delay: 200ms;
}
.point-3 {
  animation-delay: 400ms;
}

@-webkit-keyframes bouncing {
  from {
    border: 3px solid var(--dark-orange);
    background: var(--light-orange);
  }
  to {
    opacity: 0.6;
    transform: translateY(-20px);
    border: 1px solid var(--light-grey);
    background: var(--extralight-grey);
  }
}

@-moz-keyframes bouncing {
  from {
    border: 3px solid var(--dark-orange);
    background: var(--light-orange);
  }
  to {
    opacity: 0.6;
    transform: translateY(-20px);
    border: 1px solid var(--light-grey);
    background: var(--extralight-grey);
  }
}

@-o-keyframes bouncing {
  from {
    border: 3px solid var(--dark-orange);
    background: var(--light-orange);
  }
  to {
    opacity: 0.6;
    transform: translateY(-20px);
    border: 1px solid var(--light-grey);
    background: var(--extralight-grey);
  }
}

@keyframes bouncing {
  from {
    border: 3px solid var(--dark-orange);
    background: var(--light-orange);
  }
  to {
    opacity: 0.6;
    transform: translateY(-20px);
    border: 1px solid var(--light-grey);
    background: var(--extralight-grey);
  }
}
</style>
