<template>
  <div class="home">
    <carousel-full v-if="!isLoadingBanners" :banners="bingo_banners"></carousel-full>
    <div class="container-games">
      <games-filter-component
        :isDesktop="isDesktop"
        :filters="filters"
        :sectionsFilters="filters"
        :games_providers="allSkinProviders"
        :sections="others_sections"
        :isCasinoLoading="isCasinoLoading"
      ></games-filter-component>
      <base-loading v-if="isLoadingCardGames && isLoadingBanners"></base-loading>
      <card-game-container v-if="!isCasinoLoading && !isLoadingCardGames">
      </card-game-container>
    </div>
  </div>
</template>

<script>
import CarouselFull from "@/components/elements/CarouselFull.vue";
import CardGameContainer from "@/components/global/ui/CardGameContainer.vue";
import GamesFilterComponent from "@/components/elements/GamesFilterComponent.vue";
import { mapGetters } from "vuex";

export default {
  name: "OthersPage",
  data() {
    return {
      lang: "",
      filters: [{ label: "is_live", value: 0 }],
    };
  },
  components: {
    CarouselFull,
    CardGameContainer,
    GamesFilterComponent,
  },
  computed: {
    ...mapGetters("casino", [
      "isCasinoLoading",
      "others_sections",
      "allSkinProviders",
      "isLoadingCardGames",
    ]),
    ...mapGetters("translations", ["translations"]),
    // ...mapGetters("device", ["isDesktop"]),
    ...mapGetters("device", ["isDesktop", "isTablet", "isMobile", "isDesktopSize"]),
    ...mapGetters("skinBanners", ["bingo_banners", "isLoadingBanners"]),
  },
  methods: {
    // checkPagePosition() {
    // 	let calc =
    // 		(window.scrollY / document.documentElement.scrollHeight) * 100;
    // 	if (calc >= 65) {
    // 		this.loadMore();
    // 	} else {
    // 		return;
    // 	}
    // },
  },
  async created() {
    //ADD ALL GAMES FROM SCRATCH & CRASH
    let language = localStorage.getItem("language");
    this.$router.push({ params: { lang: language } });
    this.$store.dispatch("skinBanners/callBanners", "others");
    await this.$store.dispatch("casino/changingPage", { currentPage: 1 }).then(() => {
      this.$store.dispatch("casino/callSkinProviders", this.filters);
    });
  },
  mounted() {
    // window.addEventListener('scroll', () => {
    // 	this.checkPagePosition();
    // 	console.log(
    // 		(window.scrollY / document.documentElement.scrollHeight) * 100
    // 	);
    // });
    // this.lang = this.$route.params.lang || "en";

    // document.title = "Kajot Online Casino | Bingo";
    document.title =
      this.translations?.body?.othersGamePageTitle ?? "Kajot Online Casino | Others";
  },
  unmounted() {
    //when change page resetskinprovider
    this.$store.dispatch("casino/callSkinProviders");
  },
};
</script>
