export default {
  namespaced: true,
  state() {
    return {
      path: {
        banners: "cms/banners",
      },
      casino_banners: [],
      liveCasino_banners: [],
      bingo_banners: [],
      scratchCards_banners: [],
      crashGames_banners: [],
      promotion_banners: [],
      default_banners: '',
      isLoadingBanners: false,
      fallback_banner: null,
      bannersMobile: [],
      bannersDesktop: [],
      start_banner: [{
        medias: [
          {
            id: 9530,
            mediable_type: "App\\SkinBanner",
            mediable_id: 226,
            category: "medias",
            url: "media/app___skin_banner_226_653446f61a4124.72083987.jpg",
            url_webp: null,
            title: "card",
            comment: null,
            is_mobile: 0,
            created_at: "2023-10-21T21:47:34.000000Z",
            updated_at: "2023-10-23T05:44:50.000000Z",
            deleted_at: null,
            full_url: "https://storage.googleapis.com/stage-camasy-images/media/app___skin_banner_226_653446f61a4124.72083987.jpg"
          },
          {
            id: 9531,
            mediable_type: "App\\SkinBanner",
            mediable_id: 226,
            category: "medias",
            url: "media/app___skin_banner_226_65344713201d44.05741762.jpg",
            url_webp: null,
            title: "100% Welcome Bonus",
            comment: null,
            is_mobile: 1,
            created_at: "2023-10-21T21:48:03.000000Z",
            updated_at: "2023-10-23T08:55:39.000000Z",
            deleted_at: null,
            full_url: "https://storage.googleapis.com/stage-camasy-images/media/app___skin_banner_226_65344713201d44.05741762.jpg"
          }
        ],
        options: [{
          "html": "",
          "button_url": "",
          "button_text": "",
          "html_mobile": "",
          "button_url_1": null,
          "button_text_1": null,
          "button_url_mobile": "",
          "button_text_mobile": "",
          "button_url_mobile_1": null,
          "button_text_mobile_1": null
        }],
      }]
    };
  },
  mutations: {
    setBanners(state, payload) {

      state.fallback_banner = payload.fallbackIMG[0]
        ? payload.fallbackIMG[0].medias[0]
        : "";

      if (payload.casinoBanners.length > 0) {
        state.casino_banners = payload.casinoBanners;
        state.default_banners = payload.casinoBanners[0]?.medias[0]?.full_url;
      } else {
        state.casino_banners = state.start_banner;
        state.default_banners = state.casino_banners;
      }

      state.liveCasino_banners = payload.liveCasinoBanners;
      state.bingo_banners = payload.bingoBanners;
      state.scratchCards_banners = payload.scratchCardsBanners;
      state.crashGames_banners = payload.crashGamesBanners;
      if (payload.promotionBanners.length > 0) {
        state.promotion_banners = payload.promotionBanners;
      } else {
        state.promotion_banners = state.default_banners;
      }
      state.default_banners = state.casino_banners;
      state.isLoadingBanners = false;

    },
  },

  actions: {
    async callBanners({ state, getters, commit }, tag) {



      state.isLoadingBanners = true;
      let addParam = '?';



      addParam += getters.language ? 'language=' + getters.language : '';
      let userPath = getters.playerInfo.user_id ? '&user_id=' + getters.playerInfo.user_id : '';
      addParam += userPath;

      if (tag != null)
        addParam += "&tag[" + tag + "]=" + tag;

      const responseDatax = await getters.axios
        .get(getters.endpoint + state.path.banners + addParam, getters.tokenAuth)
        .then((response) => response.data.data)
        .catch((err) => err);
      let casinoBanners = [];
      let liveCasinoBanners = [];
      let bingoBanners = [];
      let scratchCardsBanners = [];
      let crashGamesBanners = [];
      let promotionBanners = [];
      let fallbackIMG = [];

      responseDatax.forEach((b) => {
        let comment = b.comment;

        if (comment != null && comment != "") {
          switch (true) {
            case comment.startsWith("casino"): {
              casinoBanners.unshift(b);
              break;
            }
            case comment.startsWith("liveCasino"): {
              liveCasinoBanners.unshift(b);
              break;
            }
            case comment.startsWith("bingo"): {
              bingoBanners.unshift(b);
              break;
            }
            case comment.startsWith("scratchCards"): {
              scratchCardsBanners.unshift(b);
              break;
            }
            case comment.startsWith("crashGames"): {
              crashGamesBanners.unshift(b);
              break;
            }
            case comment.startsWith("promotion"): {
              promotionBanners.unshift(b);
              break;
            }
            case comment.startsWith("fallbackIMG"): {
              fallbackIMG.unshift(b);
              break;
            }
          }
        } else {
          // ad check to set default banner URL if any parameters was null or ''
          b.banner =
            "https://stage-camasy-images.storage.googleapis.com/media/app___skin_banner_221_6488857567d8d2.51890349.jpg";
          casinoBanners.unshift(b);
          liveCasinoBanners.unshift(b);
          bingoBanners.unshift(b);
          promotionBanners.unshift(b);
          fallbackIMG.unshift(b);
        }
      });
      commit("setBanners", {
        casinoBanners,
        liveCasinoBanners,
        bingoBanners,
        scratchCardsBanners,
        crashGamesBanners,
        promotionBanners,
        fallbackIMG,
      });
    },
  },
  getters: {
    endpoint(_, _1, _2, rootGetters) {
      return rootGetters["api/endpoint"];
    },
    axios(_, _1, _2, rootGetters) {
      return rootGetters["api/axios"];
    },
    auth(_, _1, _2, rootGetters) {
      return rootGetters["api/auth"];
    },
    language(_, _1, _2, rootGetters) {
      return rootGetters["translations/language_selected"];
    },
    isLoadingBanners(state) {
      return state.isLoadingBanners;
    },
    tokenAuth(_, _1, _2, rootGetters) {
      return rootGetters['player/tokenAuth'];
    },
    playerInfo(_, _1, _2, rootGetters) {
      return rootGetters['player/playerInfo'];
    },
    fallback_banner(state) {
      return state.fallback_banner;
    },
    casino_banners(state) {
      return state.casino_banners;
    },
    liveCasino_banners(state) {
      return state.liveCasino_banners;
    },
    bingo_banners(state) {
      return state.bingo_banners;
    },
    scratchCards_banners(state) {
      return state.scratchCards_banners;
    },
    crashGames_banners(state) {
      return state.crashGames_banners;
    },
    promotion_banners(state) {
      return state.promotion_banners;
    },
    default_banners(state) {
      return state.default_banners;
    },
    start_banner(state) {
      return state.start_banner;
    },
  },
};
