<template>
  <div
    id="carouselFull"
    class="carousel slide"
    data-bs-touch="true"
    data-ride="carousel"
    ref="carousel"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div
      class="carousel-inner position-relative"
      :style="`min-height: 30rem; background-color: white;`"
    >
      <div v-if="!isMobile">
        <div
          v-for="(banner, index) in bannersDesktop"
          :key="banner.id"
          class="carousel-item"
          :class="index === 0 ? 'active' : ''"
          :style="`background-image: url(${banner.medias.desktop.full_url}); background-size: cover;`"
        >
          <desktop-carousell-message-inside :banner="banner" :isDesktop="isDesktop">
          </desktop-carousell-message-inside>
        </div>
      </div>
      <div v-else-if="!isLoadingBanners && isMobile">
        <div
          v-for="(banner, index) in bannersMobile"
          :key="banner.id"
          class="carousel-item"
          :class="index === 0 ? 'active' : ''"
          :style="`background-image: url(${banner.medias.mobile.full_url}); background-size: cover`"
        >
          <mobile-carousell-message-inside
            :banner="banner"
            :isDesktop="isDesktop"
            :isLandscape="isLandscape"
          >
          </mobile-carousell-message-inside>
        </div>
      </div>
    </div>

    <button
      v-if="numberOfBanners > 1"
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselFull"
      data-bs-slide="prev"
      @click="handleArrowClick"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      v-if="numberOfBanners > 1"
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselFull"
      data-bs-slide="next"
      id="nextSlide"
      @click="handleArrowClick"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
import DesktopCarousellMessageInside from "@/components/elements/DesktopCarousellMessageInside.vue";
import MobileCarousellMessageInside from "@/components/elements/MobileCarousellMessageInside.vue";
import { mapGetters } from "vuex";
import // Carousel,
// Slide,
// Pagination,
// Navigation,
"vue3-carousel";
export default {
  components: {
    DesktopCarousellMessageInside,
    MobileCarousellMessageInside,
    // Carousel,
    // Slide,
    // Pagination,
    // Navigation,
  },
  props: ["banners"],
  data() {
    return {
      isBannerEmpty: true,
      arrayCarousel: [],
      bannersMobile: null,
      bannersDesktop: null,
      numberOfBanners: null,
      isMobile: window.innerWidth <= 700,
      intervalBanner: null,
      autoScrollInterval: null,
      isMouseOverCarousel: false,
    };
  },
  computed: {
    ...mapGetters("device", ["isDesktop", "isLandscape"]),
    ...mapGetters("skinBanners", [
      "default_banners",
      "fallback_banner",
      "isLoadingBanners",
    ]),
  },
  methods: {
    startAutoScroll() {
      if (this.isMobile) {
        this.autoScrollInterval = setInterval(() => {
          const nextSlideButton = document.getElementById("nextSlide");

          if (nextSlideButton && !this.isMouseOverCarousel) {
            nextSlideButton.click();
          } else {
            clearInterval(this.autoScrollInterval);
          }
        }, 5000);
      }
    },
    handleArrowClick() {
      clearInterval(this.autoScrollInterval);
      this.startAutoScroll();
    },
    handleMouseEnter() {
      this.isMouseOverCarousel = true;
      clearInterval(this.autoScrollInterval);
    },
    handleMouseLeave() {
      this.isMouseOverCarousel = false;
      this.startAutoScroll();
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 700;
      this.setBanners();
    },
    loadBanners() {
      if (this.banners.length > 0 && !this.isLoadingBanners) {
        this.setBanners();
        clearInterval(this.intervalBanner);
      }
    },
    setBanners() {
      let bannersToDisplay = null;
      let banMob = [];
      let banDes = [];
      if (this.banners.length == 0) bannersToDisplay = this.default_banners;
      else bannersToDisplay = this.banners;

      this.numberOfBanners = bannersToDisplay.length;
      bannersToDisplay.forEach((banner) => {
        if (banner.medias.length == 0) {
          banner.medias.desktop = this.default_banners;
          banner.medias.mobile = this.default_banners;
          banMob.push(banner);
          banDes.push(banner);
          return;
        }

        let foundMobileBanner = banner.medias.find((el) => el.is_mobile == 1);
        let foundDesktopBanner = banner.medias.find((el) => el.is_mobile == 0);

        if (foundMobileBanner != undefined && foundDesktopBanner != undefined) {
          banner.medias.mobile = foundMobileBanner;
          banner.medias.desktop = foundDesktopBanner;
          banMob.push(banner);
          banDes.push(banner);
          this.isBannerEmpty = false;
        } else if (foundMobileBanner == undefined && foundDesktopBanner != undefined) {
          banner.medias.mobile = foundDesktopBanner;
          banner.medias.desktop = foundDesktopBanner;
          banMob.push(banner);
          banDes.push(banner);
        } else if (foundMobileBanner != undefined && foundDesktopBanner == undefined) {
          banner.medias.mobile = foundMobileBanner;
          banner.medias.desktop = this.default_banners;
          banDes.push(banner);
          banMob.push(banner);
        }
      });

      banDes.reverse();
      banMob.reverse();

      this.bannersMobile = banMob;
      this.bannersDesktop = banDes;
    },
  },
  watch: {
    isBannerEmpty: {
      handler(newBanners, value) {
        if (newBanners !== value) {
          this.isBannerEmpty = false;
        }
      },
      immediate: true,
    },
    $route() {
      clearInterval(this.autoScrollInterval);
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.intervalBanner = setInterval(this.loadBanners, 500);
  },
  mounted() {
    if (this.isMobile) {
      this.startAutoScroll();
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    clearInterval(this.autoScrollInterval);
    // this.isMouseOverCarousel = false;
  },
};
</script>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.carousel-item {
  min-height: 30rem;
  background-repeat: no-repeat;
  animation: fadeIn 1s ease-in;
}

.carousel-item.loaded {
  opacity: 1;
}

@media (max-width: 768px) {
  /* Styles for mobile devices */
  .carousel-item {
    min-height: 32rem;
    animation: fadeIn 1s ease-in;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  /* Styles for tablets */
  .carousel-item {
    min-height: 20rem;
    animation: fadeIn 1s ease-in;
  }
}

@media (min-width: 1025px) {
  /* Styles for desktop */
  .carousel-item {
    min-height: 30rem;
    animation: fadeIn 1s ease-in;
  }
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.99;
  transition: opacity 0.15s ease;
}

.carousel-control-prev-icon {
  background-image: url("../../../public/images/left.svg") !important;
}

.carousel-control-next-icon {
  background-image: url("../../../public/images/right.svg") !important;
}

.carousel-control-next-icon:hover,
.carousel-control-prev-icon:hover {
  transform: scale(1.4) !important;
  transition: 0.25s all !important;
}
</style>

<style>
.Carousel-HomePage {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 30rem;
}
.carousel__pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  position: relative;
  line-height: 0;
  margin: -40px 0 0;
  width: 100%;
}

.carousel__icon {
  display: block;
  width: 33px !important;
  height: 33px !important;
  -o-object-fit: contain !important;
  object-fit: contain !important;
  fill: white !important;
  background: rgba(255, 255, 255, 0.49) !important;
  border-radius: 50% !important;
  opacity: 0.8;
  /* box-shadow: 0px 1px 0px 0px #00000091; */
}

.carousel__icon:hover {
  opacity: 1 !important;
}

.carousel__pagination-item li {
  margin: 0px 5px;
}

.carousel__pagination-button:hover::after,
.carousel__pagination-button--active::after {
  background-color: white;
  border: 3px solid transparent;
}

.carousel__pagination-button::after {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: lightgrey;
}

.carousel__pagination-button {
  display: block;
  border: 0;
  margin: 0;
  cursor: pointer;
  background: transparent;
}

.carousel__pagination-button--active {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  text-align: center;
  position: relative;
  background: cornflowerblue;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-image: cornflowerblue;
}

.carousel__pagination-button:hover::after,
.carousel__pagination-button--active::after {
  background-color: white !important;
}

.carousel__prev,
.carousel__next {
  width: 40px;
}

.carousel__icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
  fill: black;
  background: white;
  border-radius: 50%;
  opacity: 0.8;
}

#carouselBanner .carousel__viewport {
  border-radius: 0px !important;
}

ol.carousel__track {
  margin-bottom: 0px !important;
}

.carousel-image {
  width: 100%;
  min-height: 30rem;
  object-fit: cover;
  object-position: left;
}

.carousel__slide {
  scroll-snap-stop: auto;
  flex-shrink: 0;
  /* margin: 0 5px !important; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateZ(0);
}

.carousel__slide--active {
  width: 80% !important;
  opacity: 1;
  margin: 0px 20px !important;
}

.zindex {
  opacity: 0;
}

.carousel__slide--visible {
  opacity: 1 !important;
}

.carousel__slide--next,
.carousel__slide--prev {
  width: 10% !important;
  margin: 0px !important;
}

.carousel__slide--active .inside-message-desk {
  display: block;
  position: absolute;
  width: 90%;
  max-width: 600px;
  top: 0%;
  left: 40%;
  transform: translate(-50%, -50%);
}
.carousel__slide--active .inside-message-mob {
  display: block;
  position: absolute;
  width: 90%;
  max-width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inside-message-desk,
.inside-message-mob {
  display: none;
}
@media (max-width: 700px) {
  .carousel__slide--active {
    width: 100% !important;
    opacity: 1;
    margin: 0px !important;
  }

  .carousel__slide--next,
  .carousel__slide--prev {
    width: 100% !important;
    margin: 0px !important;
  }

  .carousel__slide--prev .inside-message-desk {
    display: block;
    width: 90%;
    max-width: 600px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .carousel__slide--prev .inside-message-mob {
    display: block;
    width: 90%;
    max-width: 600px;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .inside-message-desk,
  .inside-message-mob {
    display: none;
  }
}
</style>

<!--
  //   <carousel
  //   :wrapAround="true"
  //   :items-to-show="3"
  //   :items-to-scroll="1"
  //   :transition="300"
  //   :touch-drag="false"
  //   :pause-autoplay-on-hover="true"
  //   class="Carousel-HomePage"
  //   id="carouselBanner"
  //   :autoplay="isMobile && bannersMobile.length > 1 ? 5000 : 0"
  // >
  //   <slide
  //     :id="banner.id"
  //     v-for="banner in isMobile ? bannersMobile : bannersDesktop"
  //     :key="banner.id"
  //     class="position-relative"
  //   >
  //     <div class="carousel__item">
  //       <img
  //         :src="isMobile ? banner.medias.mobile.full_url : banner.medias.desktop.full_url"
  //         class="carousel-image"
  //       />
  //     </div>
  //     <div class="inside-message-desk" v-if="!isMobile">
  //       <desktop-carousell-message-inside
  //         :banner="banner"
  //         :isDesktop="isDesktop"
  //       ></desktop-carousell-message-inside>
  //     </div>
  //     <div class="inside-message-mob" v-else>
  //       <mobile-carousell-message-inside
  //         :banner="banner"
  //         :isDesktop="isDesktop"
  //         :isLandscape="isLandscape"
  //       ></mobile-carousell-message-inside>
  //     </div>
  //   </slide>
  //   <template #addons>
  //     <navigation
  //       v-if="
  //         (isMobile && bannersMobile?.length > 1) ||
  //         (!isMobile && bannersDesktop?.length > 1)
  //       "
  //     />
  //   </template>
  // </carousel> -->
