<template>
	<div>
		<base-loading v-if="isCheckingTransaction"></base-loading>
		<section v-if="!isCheckingTransaction">
			<div
				v-if="!!transactionInfo"
				class="container alert mt-5"
				:class="type"
				role="alert"
			>
				<div class="row justify-content-center">
					<div class="col-11 col-md-12">
						<h4 class="alert-heading">
							Transaction {{ transactionInfo.status }}!
						</h4>
						<p>
							Transaction <strong>{{ transactionInfo.id }}</strong> has been
							{{ transactionInfo.status }}
						</p>
						<hr />
						<p>
							{{ translations.reports.Check_your_email }}
						</p>
					</div>
				</div>
			</div>
			<div v-else class="container mt-5">
				<div class="row justify-content-center mt-5">
					<div class="col-11 col-md-12">
						<h2 class="text-danger text-center">
							{{ translations.reports.transaction_not_found }}
						</h2>
					</div>
					<div class="row justify-content-center mt-5">
						<div class="col-10 col-md-8 col-lg-4">
							<base-button
								color="orange"
								:caption="$t('login.goHome').toUpperCase()"
								@click="$router.replace('/')"
							></base-button>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	export default {
		computed: {
			...mapGetters('reports', ['transactionInfo', 'isCheckingTransaction']),
			playerInfo() {
				return this.$store.getters['player/playerInfo'];
			},
			translations() {
				return this.$store.getters['translations/translations'];
			},
			type() {
				const typeClass = {
					'alert-success': this.transactionInfo.status == 'success',
					'alert-danger': this.transactionInfo.status == 'declined',
					'alert-primary': this.transactionInfo.status == 'initialized',
					'alert-warning': this.transactionInfo.status == 'pending',
				};
				return typeClass;
			},
		},
		methods: {
			checkTransactionId() {
				const id = this.$route.params.transactionId;
				this.$store.dispatch('reports/getTransactionStatus', id);
			},
		},
		created() {
			this.checkTransactionId();
		},
	};
</script>

<style scoped>
	section {
		min-height: 50vh;
	}
</style>
