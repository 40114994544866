<template>
	<div class="accordion-item my-3 radius-border">
		<h2 class="accordion-header" :id="`flush-heading${i}`">
			<button
				class="accordion-button collapsed radius-border"
				type="button"
				data-bs-toggle="collapse"
				:data-bs-target="`#flush-collapse${i}`"
				aria-expanded="false"
				:aria-controls="`flush-collapse${i}`"
				@click="$store.dispatch('player/setSelectedDocumentToUpload', i)"
			>
				<slot name="accordionTitle"></slot>
			</button>
		</h2>
		<div
			:id="`flush-collapse${i}`"
			class="accordion-collapse collapse radius-border"
			:aria-labelledby="`flush-heading${i}`"
			data-bs-parent="#accordionFlushBox"
		>
			<div class="accordion-body radius-border">
				<slot name="accordionContent"></slot>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['i'],
	};
</script>

<style scoped>
	.accordion-button.collapsed {
		box-shadow: var(--light-shadow);
	}
	.accordion-button:not(.collapsed)::after {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	}
	.accordion-button:checked {
		background-color: var(--dark-orange);
		border-color: var(--dark-orange);
	}
	.accordion-button:focus {
		box-shadow: 0px 0px 0.5rem 0.01rem var(--dark-orange);
	}
	.accordion-button:not(.collapsed) {
		background-color: var(--light-orange);
		color: rgb(255, 255, 255);
	}
	.radius-border,
	.accordion-button.radius-border {
		border-radius: 10px;
	}
</style>
