<template>
  <div>
    <div class="container-fluid back-main-header"></div>
    <div class="container-fluid main-header" :class="mainHeaderClasses">
      <div class="row align-items-center row-header">
        <div class="col-2 position-relative">
          <div class="logo-img text-center position-absolute">
            <router-link to="/">
              <img src="@/assets/img/new_logo_kajot.png" alt="Kajot Logo" />
            </router-link>
          </div>
        </div>
        <div class="col-7 nav-style-with-2">
          <div class="row justify-content-center align-items-center w-100">
            <div class="col text-center p-0 m-0 nav-style-link-with-2">
              <router-link
                class="menu-link"
                :to="{ name: 'kajotPrimePage', params: { lang: this.languages } }"
                >{{ translations.header.kajotPrime ?? "Kajot Prime" }}</router-link
              >
            </div>
            <div class="col text-center p-0 m-0">
              <router-link
                class="menu-link"
                :to="{ name: 'CasinoPage', params: { lang: this.languages } }"
                >{{ translations.header.casino }}</router-link
              >
            </div>
            <div class="col text-center p-0 m-0 nav-style-link-with-2">
              <router-link
                class="menu-link"
                :to="{ name: 'live-casino', params: { lang: this.languages } }"
                >{{ translations.header.live_casino }}</router-link
              >
            </div>
            <div class="col text-center p-0 m-0 nav-style-link-with-2">
              <router-link
                class="menu-link"
                :to="{ name: 'crash-games', params: { lang: this.languages } }"
                >{{ translations.header.crash_games }}</router-link
              >
            </div>
            <div class="col text-center p-0 m-0 nav-style-link-with-2">
              <router-link
                class="menu-link"
                :to="{ name: 'others', params: { lang: this.languages } }"
              >
                {{ translations.header.others }}
              </router-link>
            </div>
            <!----------------------- prova ------------------>
            <div class="col text-center pe-5 m-0 nav-style-link-with-2">
              <router-link
                class="menu-link"
                :to="{ name: 'promotion', params: { lang: this.languages } }"
                >{{ translations.header.promotions }}</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-3 menu-button-container">
          <div v-if="isAuthenticated" class="row justify-content-end align-items-center">
            <div
              @click="handleWindowClick"
              @mouseover="showSummary"
              class="col-6 position-relative"
            >
              <div
                ref="balanceButton"
                class="row justify-content-center align-items-center"
                :class="[
                  {
                    'balance-field': !isBalanceShow,
                    'balance-field-open': isBalanceShow,
                  },
                  isBalanceShow ? '' : 'single',
                ]"
              >
                <div class="col-1 p-0">
                  <span class="eye" @click="showBalance"
                    ><i v-if="!isBalanceShow" class="bi bi-eye-fill"></i
                    ><i v-else class="bi bi-eye-slash-fill"></i
                  ></span>
                </div>
                <div class="col-1 p-0"></div>
                <div class="col-9 p-0" v-if="playerInfo">
                  <div class="balance-text">
                    {{ translations.account_balance.Balance }}:
                    <span class="d-block text-center" style="color: var(--dirty-white)">
                      {{ playerInfo?.currency?.symbol ?? "" }}
                      {{
                        playerInfo?.balance
                          ? (
                              playerInfo?.balance?.total + playerInfo?.balance?.bonus
                            ).toFixed(2)
                          : ""
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <transition name="route">
                <div
                  ref="balanceSummary"
                  :class="
                    isBalanceShow
                      ? 'balance-summary-open '
                      : isBalanceShow
                      ? 'balance-summary-close'
                      : ''
                  "
                >
                  <balance-summary
                    v-if="isBalanceShow"
                    :showBalanceClasses="showBalanceClasses"
                    :isBalanceShow="isBalanceShow"
                  ></balance-summary>
                </div>
              </transition>
            </div>
            <div class="col-6">
              <router-link :to="{ name: 'informations' }">
                <div
                  class="menu-button grey"
                  :data-button="translations.buttons.dashboard"
                ></div>
              </router-link>
            </div>
          </div>
          <div v-else class="row justify-content-end">
            <div class="col-6">
              <router-link :to="`/${this.languages}/login`">
                <div
                  class="menu-button green"
                  :data-button="translations.login.login"
                ></div>
              </router-link>
            </div>

            <div class="col-6">
              <router-link :to="`/${this.languages}/registration`">
                <div
                  class="menu-button orange"
                  :data-button="translations.header.register"
                ></div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BalanceSummary from "../../elements/BalanceSummary.vue";
export default {
  components: {
    BalanceSummary,
  },
  data() {
    return {
      isShowSummary: false,
      isBalanceShow: !!localStorage.getItem("show_balance") ?? false,
      languages: localStorage.getItem("language") || "en",
    };
  },
  props: ["translations", "isAuthenticated", "playerInfo", "isTop", "isGamePage"],
  computed: {
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    // languages() {
    //   return this.$store.getters["translations/skinLanguages"];
    // },
    depositButton() {
      if (this.isOnBalance) {
        return "col-9";
      } else {
        return "col-9";
      }
    },
    mainHeaderClasses() {
      return [this.isTop ? "" : "top", this.isGamePage ? "game-page-header" : ""];
    },
    // showBalanceClasses() {
    //   return ["balance-text", this.isBalanceShow ? "" : "hide"];
    // },
  },
  methods: {
    handleDocumentClick(event) {
      const balanceSummaryElement = this.$refs.balanceSummary;
      const balanceButtonElement = this.$refs.balanceButton;

      const isClickOutsideBalanceSummary =
        balanceSummaryElement && !balanceSummaryElement.contains(event.target);
      const isClickOutsideBalanceButton =
        balanceButtonElement && !balanceButtonElement.contains(event.target);

      if (isClickOutsideBalanceSummary && isClickOutsideBalanceButton) {
        this.isBalanceShow = false;
        localStorage.removeItem("show_balance");
      }
    },

    showSummary() {
      this.isShowSummary = true;
    },
    hideSummary() {
      this.isShowSummary = false;
    },
    showBalance(event) {
      event.stopPropagation();
      this.isBalanceShow = !this.isBalanceShow;
      if (this.isBalanceShow) {
        localStorage.setItem("show_balance", this.isBalanceShow);
      } else {
        localStorage.removeItem("show_balance");
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleDocumentClick);
  },
};
</script>

<style scoped>
/* momentaneo (forse) */
.nav-style-with-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-style-link-with-2 {
  white-space: nowrap;
  /* padding-left: 25px !important;
  padding-right: 10px !important; */
}

.back-main-header,
.main-header {
  min-height: 4rem;
  background-color: var(--darker-grey);
  transition: var(--short-trans);
  max-width: 1920px;
}
.back-main-header.game-page-header,
.main-header.game-page-header {
  background-color: transparent;
  opacity: 0.5;
  transition: var(--short-trans);
  max-width: 1920px;
}
.game-page-header:hover {
  background-color: var(--darker-grey);
  opacity: 1;
  transition: var(--short-trans);
}
.main-header {
  position: fixed;
  top: 0;
  background-color: var(--darker-grey);
  transition: var(--short-trans);
  z-index: 89;
}
.row-header {
  min-height: 4rem;
}
.logo-img {
  position: relative;
  /* top: 20px; */
  top: 50%;
  transition: 0.3s all ease;
  left: 50%;
  transform: translate(-50%, -15%);
}

.main-header.top .logo-img {
  transform: translate(-50%, -30%);
}
.main-header.top .logo-img img {
  top: 5px;
  transition: 0.3s all ease;
  max-width: 8rem !important;
}
.logo-img img {
  transition: 0.3s all ease;
  max-width: 12rem;
}
.balance-field {
  padding: 5px 20px;
  border: 1px solid var(--light-orange);
  border-radius: 10px;
  transition: var(--short-trans);
  text-align: center;
  font-size: 0.8vw;
  color: var(--dirty-white);
  transition: var(--medium-trans);
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  position: relative;
  /* min-height: 50px;
  /* padding: 10px 20px;
  border: 1px solid var(--light-orange);
  border-radius: 10px;
  transition: var(--short-trans);
  text-align: center;
  font-size: 0.8vw;
  color: var(--dirty-white);
  transition: var(--medium-trans);
  display: flex;
  flex-direction: column;
  align-items: center; */
}

.balance-field-open {
  padding: 5px 20px;
  border: 1px solid var(--light-orange);
  border-radius: 10px;
  transition: var(--short-trans);
  text-align: center;
  font-size: 0.8vw;
  color: var(--dirty-white);
  transition: var(--medium-trans);
}
.single {
  /* padding-right: 30px; */
  /* padding-right: 0px; */
}
.eye > i {
  color: var(--dirty-white);
  transition: var(--short-trans);
}
.eye > i:hover {
  color: var(--dark-orange);
  cursor: pointer;
  transition: var(--short-trans);
}
.balance-text {
  color: var(--dirty-white);
  cursor: pointer;
  text-align: center;
}
.balance-text.blur {
  filter: blur(3px);
}
.balance-text.hide {
  display: none;
}
.balance-summary-close {
  position: absolute;
  top: 60px;
  left: -50px;
  transition: var(--short-trans);
  background: var(--darker-grey);
  border: 2px solid var(--extralight-grey);
  box-shadow: var(--light-shadow);
  border-radius: 10px;
  z-index: 86;
  padding: 1rem 0.5rem;
  min-width: 200px;
  max-width: 350px;
}

.balance-summary-open {
  position: absolute;
  top: 65px;
  left: 0%;
  transition: var(--short-trans);
  background: var(--darker-grey);
  border: 2px solid var(--extralight-grey);
  box-shadow: var(--light-shadow);
  border-radius: 10px;
  z-index: 86;
  padding: 1rem 0.5rem;
  min-width: 300px;
  max-width: 320px;
}
.menu-link {
  color: var(--dirty-white);
  text-transform: uppercase;
  text-shadow: none;
  font-weight: 600;
  font-size: 0.7rem;
  transition: var(--short-trans);
}
.menu-link:hover {
  color: var(--dark-orange);
  text-shadow: var(--light-shadow);
  text-decoration: none;
  font-size: 0.9rem;
  transition: var(--short-trans);
}
.menu-link.router-link-active {
  color: var(--light-orange);
  text-shadow: var(--dark-orange);
  font-size: 1rem;
  transition: var(--short-trans);
}
.menu-button-container {
  padding-right: 2rem;
}
.menu-button {
  color: white;
  text-align: center;
  padding: 1.2rem 2.5rem;
  background: var(--light-grey);
  border-radius: 10px;
  box-shadow: var(--light-shadow);
  box-sizing: border-box;
  position: relative;
  text-align: center;
  transition: var(--short-trans);
  cursor: pointer;
  border: 2px solid var(--light-grey);
}
.menu-button:hover {
  box-shadow: var(--light-shadow);
  border-color: var(--light-orange);
}
.menu-button::before {
  font-size: 1rem;
  content: attr(data-button);
  position: absolute;
  text-transform: uppercase;
  margin: 0.4rem auto;
  top: 0;
  right: 0;
  left: 0;
  text-align: center;
  letter-spacing: initial;
  transition: var(--short-trans);
}
.menu-button:hover::before {
  letter-spacing: 0.5px;
  font-weight: 600;
}
/* MEDIA QUERIES */
@media screen and (max-width: 1400px) {
  .menu-link:hover {
    font-size: 0.7rem;
  }
  .menu-link.router-link-active {
    font-size: 0.7rem;
  }
}
/* ------------------------------------------------------ */
.orange {
  background-color: var(--light-orange);
  transition: var(--short-trans);
  border: 2px solid var(--light-orange);
}
.orange:hover {
  background-color: var(--dark-orange);
  transition: var(--short-trans);
  border-color: var(--light-orange);
}
.green {
  background-color: var(--dark-green);
  transition: var(--short-trans);
  border: 2px solid var(--dark-green);
}
.green:hover {
  background-color: var(--light-green);
  transition: var(--short-trans);
  border-color: var(--light-green);
}
.grey {
  background-color: var(--light-grey);
  transition: var(--short-trans);
  border: 2px solid var(--light-grey);
}
.grey:hover {
  background-color: var(--dark-grey);
  transition: var(--short-trans);
  border-color: var(--light-grey);
}
</style>
