<template>
  <div class="container">
    <div class="row justify-content-center justify-content-lg-between align-items-center">
      <div class="col-11 my-2 my-md-1 mx-1 mx-md-2 col-md-3 mini-card">
        <div class="container">
          <div class="row mini-card-title">
            {{ translations.reports.Bets }}
          </div>
        </div>
        <div class="row my-2 mini-card-text">
          <div class="col-12">
            <i class="bi bi-cash"></i> {{ translations.reports.Real }}:
            {{ (+casinoList?.total?.bets).toFixed(2) }}
            {{ casinoList?.data[0]?.currency?.code ?? "" }}
          </div>
        </div>
        <div class="row my-2 mini-card-text">
          <div class="col-12">
            <i class="bi bi-gift-fill"></i> {{ translations.reports.Bonus }}:
            {{ (+casinoList?.total?.bonus_bets).toFixed(2) }}
            {{ casinoList?.data[0]?.currency?.code ?? "" }}
          </div>
        </div>
      </div>
      <div class="col-11 my-2 my-md-1 mx-1 mx-md-2 col-md-3 mini-card">
        <div class="container">
          <div class="row mini-card-title">
            {{ translations.reports.Wins }}
          </div>
        </div>
        <div class="row my-2 mini-card-text">
          <div class="col-12">
            <i class="bi bi-cash"></i> {{ translations.reports.Real }}:
            {{ (+casinoList?.total?.wins).toFixed(2) }}
            {{ casinoList?.data[0]?.currency?.code ?? "" }}
          </div>
        </div>
        <div class="row my-2 mini-card-text">
          <div class="col-12">
            <i class="bi bi-gift-fill"></i> {{ translations.reports.Bonus }}:
            {{ (+casinoList?.total?.bonus_wins).toFixed(2) }}
            {{ casinoList?.data[0]?.currency?.code ?? "" }}
          </div>
        </div>
      </div>
      <div class="col-11 my-2 my-md-1 mx-1 mx-md-2 col-md-3 mini-card">
        <div class="container">
          <div class="row my-2 mini-card-title">
            {{ translations.reports.Net }}
          </div>
        </div>
        <div class="row my-2 mini-card-text">
          <div class="col-12">
            <i class="bi bi-cash"></i> {{ translations.reports.Real }}:
            {{ (+casinoList.total.net).toFixed(2) }}
            {{ casinoList.data[0].currency.code ?? "" }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="table-responsive-md mt-5" v-if="casinoList.data.length != 0">
			<table class="table">
				<thead>
					<tr class="table-row-heading">
						<th scope="col">ID</th>
						<th scope="col">{{ translations.reports.Date_Time }}</th>
						<th scope="col">{{ translations.reports.Currency }}</th>
						<th scope="col">{{ translations.reports.Game }}</th>
						<th scope="col">{{ translations.reports.Provider }}</th>
						<th scope="col">{{ translations.reports.Bets_Count }}</th>
						<th scope="col">{{ translations.reports.Bets }}</th>
						<th scope="col">{{ translations.reports.Wins }}</th>
						<th scope="col">{{ translations.reports.Net }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="casinoTrans in casinoList.data" :key="casinoTrans.id">
						<th scope="row">{{ casinoTrans.id }}</th>
						<td>{{ casinoTrans.created_at.slice(0,10) }} / {{ casinoTrans.created_at.slice(11,19) }}</td>
						<td>{{ casinoTrans.currency.code }}</td>
						<td>{{ casinoTrans.game }}</td>
						<td>{{ casinoTrans.provider }}</td>
						<td>{{ casinoTrans.bets_count }}</td>
						<td>{{ (+casinoTrans.bets).toFixed(2) }} {{ casinoTrans.currency.symbol }}</td>
						<td>{{ (+casinoTrans.wins).toFixed(2) }} {{ casinoTrans.currency.symbol }}</td>
						<td :class="(+casinoTrans.net) < 0 ? 'negative' : 'positive'">{{ (+casinoTrans.net).toFixed(2) }} {{ casinoTrans.currency.symbol }}</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<th scope="row">{{ translations.reports.TOTAL }}</th>
						<th scope="row">{{ casinoList.total.bets_count }}</th>
						<th scope="row">{{ (+casinoList.total.bets).toFixed(2) }} {{ casinoList.data[0].currency.symbol }}</th>
						<th scope="row">{{ (+casinoList.total.wins).toFixed(2) }} {{ casinoList.data[0].currency.symbol }}</th>
						<th scope="row" :class="(+casinoList.total.net) < 0 ? 'negative' : 'positive'">{{ (+casinoList.total.net).toFixed(2) }} {{ casinoList.data[0].currency.symbol }}</th>
					</tr>
				</tfoot>
			</table>
		</div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["casinoList"],
  computed: {
    ...mapGetters("translations", ["translations"]),
  },
};
</script>

<style scoped>
.mini-card {
  background: var(--darker-grey);
  color: var(--dirty-white);
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: var(--light-shadow);
  min-height: 11rem;
}
.mini-card-title {
  color: var(--dirty-white);
  font-size: 2rem;
  font-weight: 600;
}
.mini-card-text div,
.mini-card-text i {
  color: var(--dirty-white);
  font-size: 1.2rem;
  font-weight: 500;
}
.table-row-heading {
  border-top: 2px solid var(--light-orange);
  border-bottom: 2px solid var(--light-orange);
}
th,
td {
  text-align: center;
  font-size: 0.9rem;
  margin: auto;
}
.negative {
  color: var(--dark-red);
}
.positive {
  color: var(--darker-green);
}
</style>
