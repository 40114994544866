<template>
  <div>
    <transition name="fade">
      <base-loading v-if="isPlayerLoading || isTranslationLoading"></base-loading>
    </transition>
    <div v-if="!isPlayerLoading && !isTranslationLoading">
      <div class="header container my-5 pb-5">
        <div
          class="row d-flex align-items-center justify-content-center justify-content-md-between"
        >
          <!-- WELCOME PLAYER -->
          <div class="col-10 col-md-6 col-lg-6">
            <h1 class="py-2 text-center text-md-start">
              <span class="greeting">{{ translations.registration.Welcome }}</span
              >, {{ playerInfo.name }}
            </h1>
            <p>
              <span v-if="playerInfo.is_verified" class="text-center text-success-small">
                {{ translations.myprofile.Account_verified }}
              </span>
              <span v-else class="text-center text-error-small">
                {{ translations.myprofile.Account_not_yet_verified }}
              </span>
            </p>
          </div>
          <!-- PLAYER BALANCE -->
          <div class="section-card my-2 col-11 col-md-6 col-lg-4 col-xl-3">
            <div class="container">
              <div class="row d-flex align-items-center">
                <div :class="isDesktop ? 'col-12' : 'col-8'">
                  <h6>{{ translations.account_balance.Your_balances }}</h6>
                  <div class="balance">
                    {{ translations.account_balance.Withdrawable_Balance }}:
                    {{ playerInfo?.currency?.symbol ?? "" }}
                    {{ playerInfo?.balance?.available.toFixed(2) }}
                  </div>
                  <div class="balance">
                    {{ translations.account_balance.Balance }}:
                    {{ playerInfo?.currency?.symbol ?? "" }}
                    {{ playerInfo?.balance?.deposited.toFixed(2) }}
                  </div>
                  <div class="balance">
                    {{ translations.account_balance.Bonus_Balance }}:
                    {{ playerInfo?.currency?.symbol ?? "" }}
                    {{ playerInfo?.balance?.bonus.toFixed(2) }}
                  </div>
                </div>
                <div v-if="isMobile || isTablet" class="col-4">
                  <ul>
                    <li>
                      <router-link
                        :to="{ name: 'deposit' }"
                        @click="scrollToBottomFromDeposit"
                      >
                        {{ translations.buttons.deposit.toUpperCase() }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- GO TO PLAYER VERIFICATION -->
    </div>

    <!-- DASHBOARD MENU -->
    <section>
      <div class="container my-4">
        <div class="row d-flex justify-content-center shortCut">
          <ul
            class="col-11 col-lg-10 dashboard-menu text-center d-flex flex-wrap justify-content-md-center"
          >
            <li>
              <router-link
                :to="`/${language_selected}/informations`"
                @click="scrollToBottom"
              >
                {{ translations.dashboard_menu.My_profile }}
              </router-link>
            </li>
            <li>
              <router-link
                :to="`/${language_selected}/passwords`"
                @click="scrollToBottom"
              >
                {{ translations.registration.password }}
              </router-link>
            </li>
            <li>
              <router-link
                :to="`/${language_selected}/documents`"
                @click="scrollToBottom"
              >
                {{ translations.myprofile.Documents }}
              </router-link>
            </li>
            <li>
              <router-link :to="`/${language_selected}/sowq`" @click="scrollToBottom">
                {{ translations.dashboard_menu.Sowq ?? "Sowq" }}
              </router-link>
            </li>
            <li>
              <router-link :to="`/${language_selected}/kyc`" @click="scrollToBottom">
                {{ translations.affiliate_program.kyc_policy }}
              </router-link>
            </li>
            <li>
              <router-link
                :to="`/${language_selected}/transactions`"
                @click="scrollToBottom"
              >
                {{ translations.account_balance.Transaction }}
              </router-link>
            </li>
            <li>
              <router-link :to="`/${language_selected}/reports`" @click="scrollToBottom">
                {{ translations.dashboard_menu.Reports }}
              </router-link>
            </li>
            <li>
              <router-link :to="`/${language_selected}/limits`" @click="scrollToBottom">
                {{ translations.myprofile.Account_Limits }}
              </router-link>
            </li>
            <li v-if="isDesktop">
              <router-link :to="`/${language_selected}/deposit`" @click="scrollToBottom">
                {{ translations.account_balance.Deposit }}
              </router-link>
            </li>
            <li>
              <router-link :to="`/${language_selected}/withdraw`" @click="scrollToBottom">
                {{ translations.account_balance.Withdraw }}
              </router-link>
            </li>
            <!-- <li>
              <router-link :to="`/${language_selected}/messages`" @click="scrollToBottom">
                {{ translations.my_inbox.Messages }}
              </router-link>
            </li>  -->
            <li>
              <router-link :to="`/${language_selected}/myBonus`" @click="scrollToBottom">
                {{ translations.promotion.my_bonus ?? "My Bonus" }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- DASHBOARD SELECTED SUB-PAGE -->
      <router-view v-slot="slotProps" id="frame">
        <transition name="fade" mode="out-in">
          <keep-alive>
            <component :is="slotProps.Component"></component>
          </keep-alive>
        </transition>
      </router-view>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      language_selected: localStorage.getItem("language"),
    };
  },
  computed: {
    ...mapGetters("player", [
      "token",
      "isAuthenticated",
      "isPlayerLoading",
      "playerInfo",
    ]),
    ...mapGetters("device", ["isDesktop", "isTablet", "isMobile"]),
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
  },
  methods: {
    scrollToBottom() {
      if (this.isMobile) {
        const offset = 500;
        const scrollPosition = window.pageYOffset + offset;
        const maxScrollPosition =
          document.documentElement.scrollHeight - window.innerHeight;
        if (scrollPosition < maxScrollPosition) {
          window.scrollTo({
            top: scrollPosition,
            behavior: "smooth",
          });
        }
      }
    },
    scrollToBottomFromDeposit() {
      if (this.isMobile) {
        const offset = 800;
        const scrollPosition = window.pageYOffset + offset;
        const maxScrollPosition =
          document.documentElement.scrollHeight - window.innerHeight;
        if (scrollPosition < maxScrollPosition) {
          window.scrollTo({
            top: scrollPosition,
            behavior: "smooth",
          });
        }
      }
    },
  },
  created() {
    let language = localStorage.getItem("language");
    this.$router.push({ params: { lang: language } });
  },
};
</script>

<style scoped>
.greeting {
  color: var(--light-orange);
}
.header {
  border-bottom: 1px solid var(--extralight-grey);
}

ul {
  padding: 0;
  margin-bottom: 0;
}
.balance {
  font-size: 0.9rem;
}
.token {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.section-card {
  border: 2px solid var(--extralight-grey);
  border-radius: 15px;
  box-shadow: var(--light-shadow);
  padding: 20px 20px;
  max-height: 9rem;
  min-height: 7rem;
}

@media (max-width: 600px) {
  .section-card {
    border: 2px solid var(--extralight-grey);
    border-radius: 15px;
    box-shadow: var(--light-shadow);
    padding: 10px 8px;
    min-height: 7rem;
    /* removed the max-height for mobile view */
  }
}

.dashboard-menu {
  border: 2px dashed var(--extralight-grey);
  border-radius: 15px;
  box-shadow: var(--light-shadow);
  padding: 25px 20px;
  white-space: nowrap;
}

ul > li {
  list-style: none;
  display: flex;
}

li > a {
  padding: 10px;
  margin: 5px 10px;
  background: white;
  border-radius: 15px;
  box-shadow: var(--light-shadow);
  border: 2px solid var(--light-orange);
  transition: var(--short-trans);
  color: var(--dark-orange);
  text-transform: uppercase;
  transition: var(--short-trans);
}

li:hover > a {
  color: white;
  text-decoration: none;
  border-color: var(--dark-grey);
  background: var(--dark-grey);
}

a.router-link-exact-active {
  background: var(--dark-grey);
  border-color: var(--dark-grey);
  color: white;
  box-shadow: var(--orange-shadow);
  transition: var(--short-trans);
}

@media (max-width: 767px) {
  .dashboard-menu {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    justify-content: center;
    max-width: 100%;
  }

  .shortCut ul > li {
    width: 50%;
    padding: 5px;
  }

  .shortCut li > a {
    width: 100%;
    text-align: center;
  }
}
</style>

<!--------------------------------------------

	OLD VERSION WITH SCROLL


	<template>
	<div>
		<transition name="fade">
			<base-loading
				v-if="isPlayerLoading || isTranslationLoading"
			></base-loading>
		</transition>
		<div v-if="!isPlayerLoading && !isTranslationLoading">
			<div class="header container my-5 pb-5">
				<div
					class="row d-flex align-items-center justify-content-center justify-content-md-between"
				>
					-- WELCOME PLAYER --
					<div class="col-10 col-md-6 col-lg-6">
						<h1 class="py-2 text-center text-md-start">
							<span class="greeting">{{
								translations.registration.Welcome
							}}</span
							>, {{ playerInfo.name }}
						</h1>
						<p>
							<span
								v-if="playerInfo.is_verified"
								class="text-center text-success-small"
							>
								{{ translations.myprofile.Account_verified }}
							</span>
							<span v-else class="text-center text-error-small">
								{{ translations.myprofile.Account_not_yet_verified }}
							</span>
						</p>
					</div>
					 PLAYER BALANCE 
					<div class="section-card my-2 col-11 col-md-6 col-lg-4 col-xl-3">
						<div class="container">
							<div class="row d-flex align-items-center">
								<div :class="isDesktop ? 'col-12' : 'col-8'">
									<h6>{{ translations.account_balance.Your_balances }}</h6>
									<div class="balance">
										{{ translations.account_balance.Withdrawable_Balance }}:
										{{ playerInfo?.currency?.symbol }}
										{{ playerInfo.balance.available }}
									</div>
									<div class="balance">
										{{ translations.account_balance.Balance }}:
										{{ playerInfo?.currency?.symbol }}
										{{ playerInfo.balance.deposited }}
									</div>
									<div class="balance">
										{{ translations.account_balance.Bonus_Balance }}:
										{{ playerInfo?.currency?.symbol }}
										{{ playerInfo.balance.bonus }}
									</div>
								</div>
								<div v-if="isMobile || isTablet" class="col-4">
									<ul>
										<li>
											<router-link :to="{ name: 'deposit' }">
												{{ translations.buttons.deposit.toUpperCase() }}
											</router-link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<(!-- DESKTOP LOGOUT BUTTON -->
<!-- <div
						v-if="isDesktop"
						class="section-card my-2 col-10 col-md-4 col-lg-4 col-xl-3"
					>
						<div class="form-group">
							<base-button
								@click="logoutPlayer"
								color="grey"
								:caption="translations.buttons.LogOut"
								id="logout"
							></base-button>
						</div>

						<div class="my-2">
							<div
								v-if="playerInfo.is_verified"
								class="text-center text-success-small"
							>
								{{ translations.myprofile.Account_verified }}
							</div>
							<div v-else class="text-center text-error-small">
								{{ translations.myprofile.Account_not_yet_verified }}
							</div>
						</div>
					</div> --(>)
				</div>
				 GO TO PLAYER VERIFICATION 
			</div>

			 DASHBOARD MENU 
			<section>
				<div class="container my-4">
					<div class="row d-flex justify-content-center">
						<ul class="col-11 col-lg-12 dashboard-menu text-center">
							<li>
								<router-link :to="`/${language_selected}/informations`">
									{{ translations.dashboard_menu.My_profile }}
								</router-link>
							</li>
							<li>
								<router-link :to="`/${language_selected}/passwords`">
									{{ translations.registration.password }}
								</router-link>
							</li>
							<li>
								<router-link :to="`/${language_selected}/documents`">
									{{ translations.myprofile.Documents }}
								</router-link>
							</li>
							<li>
								<router-link :to="`/${language_selected}/sowq`"> Sowq </router-link>
							</li>
							<li>
								<router-link :to="`/${language_selected}/kyc`">
									{{ translations.affiliate_program.kyc_policy }}
								</router-link>
							</li>
							<li>
								<router-link :to="`/${language_selected}/transactions`">
									{{ translations.account_balance.Transaction }}
								</router-link>
							</li>
							<li>
								<router-link :to="`/${language_selected}/reports`">
									{{ translations.dashboard_menu.Reports }}
								</router-link>
							</li>
							<li>
								<router-link :to="`/${language_selected}/limits`">
									{{ translations.myprofile.Account_Limits }}
								</router-link>
							</li>
							<li v-if="isDesktop">
								<router-link :to="`/${language_selected}/deposit`">
									{{ translations.account_balance.Deposit }}
								</router-link>
							</li>

							<li >
								<router-link :to="`/${language_selected}/withdraw`">
									{{ translations.account_balance.Withdraw }}</router-link
								>
							</li>

							<li>
								<router-link :to="`/${language_selected}/messages`">
									{{ translations.my_inbox.Messages }}</router-link
								>
							</li>
						</ul>
					</div>
				</div>
				<(!)-- DASHBOARD SELECTED SUB-PAGE --()>
				<router-view v-slot="slotProps" id="frame">
					<transition name="fade" mode="out-in">
						<keep-alive>
							<component :is="slotProps.Component"></component>
						</keep-alive>
					</transition>
				</router-view>
			</section>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	export default {
		data(){
			return{
			language_selected: localStorage.getItem('language'),
			}
		},
		computed: {
			...mapGetters('player', [
				'token',
				'isAuthenticated',
				'isPlayerLoading',
				'playerInfo',
			]),
			...mapGetters('device', ['isDesktop', 'isTablet', 'isMobile']),
			...mapGetters('translations', ['isTranslationLoading', 'translations']),
		},
		created(){
			let language = localStorage.getItem("language");
    		this.$router.push({ params: { lang: language } });
		}
	};
</script>

<style scoped>
	.greeting {
		color: var(--light-orange);
	}
	.header {
		border-bottom: 1px solid var(--extralight-grey);
	}

	ul {
		padding: 0;
		margin-bottom: 0;
	}
	.balance {
		font-size: 0.9rem;
	}
	.token {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.section-card {
		border: 2px solid var(--extralight-grey);
		border-radius: 15px;
		box-shadow: var(--light-shadow);
		padding: 20px 20px;
		max-height: 9rem;
		min-height: 7rem;
	}

	.dashboard-menu {
		border: 2px dashed var(--extralight-grey);
		border-radius: 15px;
		box-shadow: var(--light-shadow);
		padding: 25px 20px;
		overflow-x: scroll;
		white-space: nowrap;
	}

	/* .dashboard-menu::-webkit-scrollbar {
		height: 0;
	}

	.dashboard-menu::-webkit-scrollbar-track {
		background: transparent;
	} */

	/* SCROLLBAR */
	/* @media screen and (max-width: 1400px) { */
	/* width */
	.dashboard-menu::-webkit-scrollbar {
		height: 5px;
	}

	/* Track */
	.dashboard-menu::-webkit-scrollbar-track {
		background: var(--dirty-white);
		border-radius: 2px;
		margin: 15px;
	}

	/* Handle */
	.dashboard-menu::-webkit-scrollbar-thumb {
		background: var(--light-orange);
		border-radius: 2px;
		transition: var(--short-trans);
	}

	/* Handle on hover */
	.dashboard-menu::-webkit-scrollbar-thumb:hover {
		background: var(--dark-orange);
		transition: var(--short-trans);
	}
	/* } */

	ul > li {
		list-style: none;
		display: inline;
	}

	li > a {
		padding: 10px;
		margin: 5px 10px;
		background: white;
		border-radius: 15px;
		box-shadow: var(--light-shadow);
		border: 2px solid var(--light-orange);
		transition: var(--short-trans);
		color: var(--dark-orange);
		text-transform: uppercase;
		transition: var(--short-trans);
	}

	li:hover > a {
		color: white;
		text-decoration: none;
		border-color: var(--dark-grey);
		background: var(--dark-grey);
	}

	a.router-link-exact-active {
		background: var(--dark-grey);
		border-color: var(--dark-grey);
		color: white;
		box-shadow: var(--orange-shadow);
		transition: var(--short-trans);
		transform: translateX(-100px);
		/* cursor: not-allowed; */
	}
</style>

------------------------------------------------>
