<template>
  <base-dashboard-section>
    <template #nameSection>{{ translations.account_balance.Transaction }}</template>
    <template #default>
      <transition name="fade">
        <div class="container search-module py-1 px-3">
          <div class="row date-field py-3 d-flex justify-content-center">
            <div class="col-6">
              <label class="form-label" for="from_date">{{
                translations.reports.From
              }}</label>
              <input
                v-model="from_date"
                class="form-control"
                id="from_date"
                type="date"
                :max="yesterday"
              />
            </div>
            <div class="col-6">
              <label class="form-label" for="from_date">{{
                translations.reports.To
              }}</label>
              <input
                v-model="to_date"
                class="form-control"
                id="from_date"
                type="date"
                :max="today"
              />
            </div>
          </div>
          <div class="row my-3 d-flex justify-content-center">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label class="form-label" for="type">{{
                  translations.reports.Type
                }}</label>
                <select id="type" class="form-control" v-model="type">
                  <option value="" selected>
                    {{ translations.reports.All }}
                  </option>
                  <option value="1">
                    {{ translations.account_balance.Deposit }}
                  </option>
                  <option value="2">
                    {{ translations.account_balance.Withdraw }}
                  </option>
                  <option value="4">
                    {{ translations.account_balance.Casino_bet }}
                  </option>
                  <option value="5">
                    {{ translations.account_balance.Casino_win }}
                  </option>
                  <option value="6">
                    {{ translations.account_balance.Casino_refund }}
                  </option>
                  <option value="7">
                    {{ translations.account_balance.Casino_bonus_bet }}
                  </option>
                  <option value="8">
                    {{ translations.account_balance.Casino_bonus_win }}
                  </option>
                  <option value="10">
                    {{ translations.account_balance.Bonus_assigned }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label class="form-label" for="status">{{
                  translations.reports.Status
                }}</label>
                <select id="type" class="form-control" v-model="status">
                  <option value="authorized">
                    {{ translations.account_balance.Authorized }}
                  </option>
                  <option value="declined">
                    {{ translations.account_balance.Declined }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row my-3 d-flex justify-content-center">
            <div class="col-12">
              <base-button
                :caption="translations.reports.Search.toUpperCase()"
                color="orange"
                :disabled="disabledButton"
                @click="search"
              ></base-button>
            </div>
          </div>
        </div>
      </transition>
      <div class="container mt-5">
        <transition name="fade">
          <div v-if="isTransactionsLoading">
            <base-loading></base-loading>
          </div>

          <div v-else>
            <div
              class="row justify-content-center justify-content-lg-start align-items-center mb-2"
            >
              <div class="col-12 my-2 my-md-1 col-md-4">
                <div class="mini-card">
                  <div class="container">
                    <div class="row mini-card-title">
                      {{ translations.account_balance.Deposit ?? "Deposit" }}
                    </div>
                  </div>
                  <div class="row my-2 mini-card-text">
                    <div class="col-12">
                      {{ (+transactionsDeposit.amount).toFixed(2) }}
                      {{ playerInfo?.currency?.code }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 my-2 my-md-1 col-md-4">
                <div class="mini-card">
                  <div class="container">
                    <div class="row mini-card-title">
                      {{ translations.account_balance.Withdraw ?? "Withdraw" }}
                    </div>
                  </div>
                  <div class="row my-2 mini-card-text">
                    <div class="col-12">
                      {{ (+transactionsWithdraw.amount).toFixed(2) }}
                      {{ playerInfo?.currency?.code }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 my-2 my-md-1 col-md-4">
                <div class="mini-card">
                  <div class="container">
                    <div class="row mini-card-title">
                      {{ translations.reports.Net ?? "Net" }}
                    </div>
                  </div>
                  <div class="row my-2 mini-card-text">
                    <div class="col-12">
                      {{
                        (
                          Math.abs(transactionsWithdraw.amount) -
                          Math.abs(transactionsDeposit.amount)
                        ).toFixed(2)
                      }}
                      {{ playerInfo?.currency?.code }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!isFirstLoadTransactions && responseLength != 0">
              <data-table-component
                :casinoList="transactionsList"
                :translations="translations"
                :skinName="skinName"
                :isDesktop="isDesktop"
              ></data-table-component>
            </div>

            <div v-if="!isFirstLoadTransactions && responseLength == 0">
              <div class="row">
                <h3 class="text-center">
                  {{ translations.reports.Ooops_search }}
                </h3>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </template>
  </base-dashboard-section>
</template>

<script>
//import TransactionsReports from './Reports/TransactionsReports.vue';
import DataTableComponent from "@/components/elements/DataTableComponentTrans.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    //TransactionsReports,
    DataTableComponent,
  },
  data() {
    return {
      today: null,
      yesterday: null,
      from_date: null,
      to_date: null,
      status: "authorized",
      type: "",
      responseLength: null,
    };
  },
  watch: {
    transactionsList(value) {
      if (value != null) {
        this.responseLength = value.length;
      }
    },
    transactionsTotList(value) {
      if (value != null) {
        this.responseLengthTot = value.length;
      }
    },
  },
  computed: {
    ...mapGetters("casino", ["allSkinProviders"]),
    ...mapGetters("reports", [
      "isTransactionsLoading",
      "isFirstLoadTransactions",
      "transactionsList",
      "transactionsWithdraw",
      "transactionsDeposit",
      "transactionsTotal",
    ]),
    ...mapGetters("player", ["playerInfo"]),
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    disabledButton() {
      return this.from_date > this.to_date ? true : false;
    },
    skinName() {
      return this.$store.getters["skinSettings/skinName"];
    },
    isDesktop() {
      return this.$store.getters["device/isDesktop"];
    },
  },
  methods: {
    getDate() {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      let from = new Date();
      from.setDate(from.getDate());
      this.today = new Date().toISOString().slice(0, 10);
      this.yesterday = yesterday.toISOString().slice(0, 10);
      this.to_date = new Date().toISOString().slice(0, 10);
      this.from_date = from.toISOString().slice(0, 10);
    },
    requestTransactionList() {
      const params = {
        from: this.from_date,
        to: this.to_date,
        type: this.type,
        status: this.status,
      };
      this.$store.dispatch("reports/getTransactionsReports", params);

      const paramsDep = {
        from: this.from_date,
        to: this.to_date,
        type: 1,
        status: this.status,
        total: 1,
      };
      //total reports
      this.$store.dispatch("reports/getTransactionsReports", paramsDep);

      const paramsWith = {
        from: this.from_date,
        to: this.to_date,
        type: 2,
        status: this.status,
        total: 1,
      };
      //total reports
      this.$store.dispatch("reports/getTransactionsReports", paramsWith);
    },
    search() {
      this.requestTransactionList(this.from_date, this.to_date, this.type, this.status);
    },
  },
  created() {
    this.getDate();
  },
  mounted() {
    // const lang = localStorage.getItem("language") || "en";
    // const titles = {
    // en: "Kajot Online Casino | Transactions",
    // sk: "Kajot Online Casino | Transakcie",
    // cs: "Kajot Online Casino | Transakce",
    // de: "Kajot Online Casino | Transaktionen",
    // hu: "Kajot Online Casino | Tranzakciók",
    // };
    // document.title = titles[lang] || "Kajot Online Casino | Transactions";
    document.title =
      this.translations?.body?.transactionPageTitle ??
      "Kajot Online Casino | Transactions";
    this.search();
  },
};
</script>

<style scoped>
.date-field {
  background: var(--extralight-grey);
  border-radius: 10px;
  transition: var(--short-trans);
  transform: scale(1);
}

.mini-card {
  background: var(--darker-grey);
  color: var(--dirty-white);
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: var(--light-shadow);
  min-height: 11rem;
}
.mini-card-title {
  color: var(--dirty-white);
  font-size: 2rem;
  font-weight: 600;
}
.mini-card-title small {
  color: var(--dirty-white);
  font-size: 1rem;
  padding: 0px;
  font-weight: 600;
}
.mini-card-text div,
.mini-card-text i {
  color: var(--dirty-white);
  font-size: 1.2rem;
  font-weight: 500;
}
.table-row-heading {
  border-top: 2px solid var(--light-orange);
  border-bottom: 2px solid var(--light-orange);
}
th,
td {
  text-align: center;
  font-size: 0.9rem;
  margin: auto;
}
.negative {
  color: var(--dark-red);
}
.positive {
  color: var(--darker-green);
}
</style>
