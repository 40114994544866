<template> 
	<section>
	   <base-info-page>
		  <template #title>{{page.name}}</template>
		  <template #default>
			 <div>
				<div class="body" v-html="page.content"></div>
			 </div>
		  </template>
	   </base-info-page>
	</section>
 </template>
<script>
	import { mapGetters } from 'vuex';
	export default {
		name: 'StaticPage',
		data() {
			return {
			  
			};
		},
		computed: {
			...mapGetters('skinSettings', [
				'language','page',
			]),
			pathWithLang() 
			{
      			return `/${this.language}/cookies`;
    		},
		},
		beforeMount()
		{
			this.$store.dispatch('skinSettings/callPage', this.$route.params.page+'-'+this.language)
			 .then(() => 
			{
				if(!this.page.id)
				 window.location.replace("/");

				document.title = this.page.seo.title;
				document.querySelector('meta[name="description"]').setAttribute("content", this.page.seo.description);
				const keywords = this.page.seo.keywords;
				const metaKeywords = document.createElement("meta");
				metaKeywords.setAttribute("name", "keywords");
				metaKeywords.setAttribute("content", keywords);
				document.querySelector("head").appendChild(metaKeywords);	
			 
				 
				 
			}).catch(error => {
				console.error(error);
			});
 
		}
	};
</script>
