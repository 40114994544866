export default {
	async checkNickname({ state, getters, commit }, payload) {
		state.nicknameErrorMessage = null;
		state.isNicknameInvalid = true;
		if (payload.length == 0) {
			return false;
		} else if (payload.length < 6) {
			state.nicknameErrorMessage =
				getters.translations.registration.username_error_min;
			return false;
		} else if (payload.length > 25) {
			state.nicknameErrorMessage =
				getters.translations.registration.username_error_max;
			return false;
		} else {
			let body = {
				username: payload,
				withTrashed: true
			};
			const responseData = await getters.axios
				.post(getters.endpoint + state.path.checkUsername, body, getters.auth)
				.then((response) => response)
				.catch((err) => err);
			const nicknameAlreadyUsed =
				getters.translations.registration.nickname_already_used;
			commit('setNicknameStatus', { responseData, nicknameAlreadyUsed });
		}
	},
	async checkEmail({ state, getters, commit }, payload) {
		state.emailErrorMessage = null;
		state.isEmailInvalid = true;
		if (payload.length == 0) {
			return false;
		}
		if (payload.length < 6) {
			state.emailErrorMessage = getters.translations.registration.email_error;
			return false;
		}
		commit('emailValidation', payload);
		if (getters.emailValidated) {
			let body = {
				email: payload,
				emailVerified: true,
				withTrashed: true
			};
			const responseData = await getters.axios
				.post(getters.endpoint + state.path.checkEmail, body, getters.auth)
				.then((response) => response)
				.catch((err) => err);
			const emailAlreadyUsed =
				getters.translations.registration.email_already_used;
			commit('setEmailStatus', { responseData, emailAlreadyUsed });
		} else {
			state.emailErrorMessage = getters.translations.registration.email_error;
		}
	},
	setPasswordStatus({ state, getters, commit }, payload) {
		state.isPasswordInvalid = true;
		state.passwordErrorMessage = null;
		if (payload.length == 0) {
			return false;
		} else {
			commit('passwordValidation', payload);
			if (getters.passwordValidated) {
				state.isPasswordInvalid = false;
			} else {
				state.isPasswordInvalid = true;
				state.passwordErrorMessage =
					getters.translations.registration.password_error;
			}
		}
	},

	async sendVerificationCode({ state, getters, commit }, payload) {
		state.registration_nickname = payload.username;
		state.registration_password = payload.password;
		payload.status = 'incompleted';

		const responseData = await getters.axios
			.post(getters.endpoint + state.path.register, payload, getters.auth)
			.then((response) => {
				if (Object.prototype.hasOwnProperty.call(response, 'data')) {
					commit('setSendVerificationIsOkay', true)
					return response.data.data.id;
				} else {
					return alert('ERROR SERVER');
				}
			})
			.catch((err) => err.message);
		if (state.sendVerificationIsOkay == true) {
			commit('setTempUser', responseData);
			// console.log('RESPONSEDATA SENDVERIFICATIONCODE', responseData)
		} else {
			// console.log('E false')
			commit('setSendVerificationIsOkay', false)
		}

	},

	async validationPhone({ state, getters }, payload) {
		return await getters.axios
			.post(getters.endpoint + state.path.twilioCheck, payload, getters.auth)
			.then((response) => {
				if (Object.prototype.hasOwnProperty.call(response, 'data')) {
					return response.data.is_valid;
				}
				else
					return true;
			})
			.catch((err) => { console.log(err); return true; });

	},

	async sendVerificationCodeAgain({ state, getters, commit }, payload) {
		const responseData = await getters.axios
			.post(getters.endpoint + state.path.sendEmailAgain(payload))
			.then((response) => response)
			.catch((err) => err);
		commit('sendVerificationCodeAgain', responseData);
	},

	async deleteTemporaryUser({ state, getters, commit }) {
		const temporaryUser = localStorage.getItem('_usr');
		if (temporaryUser != null) {
			const responseData = await getters.axios
				.delete(
					getters.endpoint + state.path.static + temporaryUser,
					getters.auth
				)
				.then((response) => response)
				.catch((err) => err);
			localStorage.removeItem('_usr');
			commit('deleteTempUser', responseData);
		}
	},

	async contactVerification(
		{ state, getters, commit, dispatch },
		{ verification, service, playerId = null }
	) {
		let userId = playerId ?? getters.verificationPlayerID;

		const body = {
			user_id: userId,
			skin_id: getters.skinId,
			verification,
			check_campaign: 1
		};

		if (service == 'email') {
			body.email_verified = 1
		} else {
			body.sms_verified = 1
		}

		console.log(body)

		const responseData = await getters.axios
			.post(getters.endpoint + state.path.notification(service), body)
			.then((response) => response)
			.catch((err) => err);
		commit('contactVerification', { responseData, service });
		dispatch(
			'alert/topRightNotification',
			{ responseData, service },
			{ root: true }
		);
	},


	async sendKyc({ state, getters, commit }, payload) {
		const body = {
			level: getters.basicKYCLevel,
		};

		const responseData = await getters.axios
			.post(getters.endpoint + state.path.kyc + payload, body, getters.auth)
			.then((response) => response)
			.catch((err) => err);
		commit('sendKyc', responseData);
	},

	async checkPhoneNumber({ state, getters, commit }, { mobile, prefix }) {
		state.mobileErrorMessage = null;
		state.isMobileInvalid = false;
		let phoneIsInvalid = false;
		if (mobile.length > 4) {
			phoneIsInvalid = mobile.startsWith(prefix);
		}
		if (phoneIsInvalid) {
			state.isMobileInvalid = true;
			state.mobileErrorMessage =
				getters.translations.registration.remove_phone_prefix;
			return;
		} else {
			if (prefix === 'isIntoRegistration') {
				prefix = "";
			}
			const body = {
				mobile: prefix + mobile,
				smsVerified: true,
			};
			console.log(typeof body.mobile)
			console.log(body)
			console.log(getters.endpoint + state.path.checkMobile)
			const responseData = await getters.axios
				.post(getters.endpoint + state.path.checkMobile, body, getters.auth)
				.then((response) => response)
				.catch((err) => err);
			const mobileAlreadyUsed =
				getters.translations.registration.phone_already_used;
			commit('checkPhoneNumber', { responseData, mobileAlreadyUsed });
		}
	},

	async checkPhoneNumberForRegistration({ state, getters, commit }, { mobile, prefix }) {
		state.mobileErrorMessage = null;
		state.isMobileInvalid = false;
		let phoneIsInvalid = false;
		if (mobile.length > 4) {
			phoneIsInvalid = mobile.startsWith(prefix);
		}
		if (phoneIsInvalid) {
			state.isMobileInvalid = true;
			state.mobileErrorMessage =
				getters.translations.registration.remove_phone_prefix;
			return;
		} else {
			const body = {
				mobile: prefix + mobile,
				smsVerified: true,
				skipEmpty:true
			};
			// console.log(typeof body.mobile)
			// console.log(body)
			// console.log(getters.endpoint + state.path.checkMobile)
			const responseData = await getters.axios
				.post(getters.endpoint + state.path.checkMobile, body, getters.auth)
				.then((response) => response)
				.catch((err) => err);
			const mobileAlreadyUsed =
				getters.translations.registration.phone_already_used;
			commit('checkPhoneNumber', { responseData, mobileAlreadyUsed });
		}
	},
	async checkFields({ state, getters, commit }, { checkMga }) {

		const body = checkMga;

		const responseData = await getters.axios
			.post(getters.endpoint + state.path.checkFields, body, getters.auth)
			.then((response) => response)
			.catch((err) => err);

		const fieldsAlreadyUsed =
			getters.translations.registration.fields_already_used;
		commit('checkFields', { responseData, fieldsAlreadyUsed });
	},
	async restore({ state, getters, commit }, payload) {

		let path = state.path.restore(payload.token) + "?email=" + payload.email + "&type=activation-token&email_verified=1";
		state.isEmailInvalid = false;
		state.isNicknameInvalid = false;
		state.isPasswordInvalid = false;
		state.passwordMatchControl = true;
		const responseData = await getters.axios
			.get(getters.endpoint + path, getters.auth)
			.then((response) => response.data)
			.catch((err) => err);

		commit('setRestore', responseData);
		return responseData;
	},

	async updatePlayer(
		{ state, getters, commit, dispatch },
		{ body, playerId }
	) {
		console.log(body);
		console.log(playerId);
		const responseData = await getters.axios
			.put(getters.endpoint + state.path.static + playerId, body, getters.auth)
			.then((response) => response)
			.catch((err) => err);

		if (Object.prototype.hasOwnProperty.call(responseData, 'status')) {
			if (responseData.status == '200') {
				console.log(responseData, dispatch);
			} else {
				alert('SERVER ERROR');
			}
		} else {
			commit('saveRegistration', responseData);
		}
	},
	async saveRegistration(
		{ state, getters, commit, dispatch },
		{ body, playerId }
	) {
		const responseData = await getters.axios
			.put(getters.endpoint + state.path.static + playerId, body, getters.auth)
			.then((response) => response)
			.catch((err) => err);

		if (Object.prototype.hasOwnProperty.call(responseData, 'status')) {
			if (responseData.status == '200') {
				const message = getters.translations.registration.registration_and_redirect;
				localStorage.removeItem('btag');
				localStorage.removeItem('_usr');
				const prefilledData = {
					nickname: getters.registration_nickname,
					password: getters.registration_password,
				};
				dispatch('setPrefilledLoginValue', prefilledData, { root: true });
				//dispatch('sendKyc', playerId);
				if (body.is_pep || body.is_ubo) {
					commit('redirectToLogin', message);
				} else {
					const skinId = getters.skinId;
					dispatch('callTwilio', { playerId, skinId });
				}
			} else {
				alert('Error during registration. Contact our customer service for more information');
			}
		} else {
			alert('Error during registration. Contact our customer service for more information');
			commit('saveRegistration', responseData);
		}
	},


	async callTwilio({ state, getters, commit }, { playerId, skinId }) {

		const body = {
			skin_id: skinId,
			user_id: playerId,
		};

		// console.log('body______', body)
		const responseData = await getters.axios
			.post(getters.endpoint + state.path.twilio, body, getters.auth)
			.then((response) => response)
			.catch((err) => err);
		if (responseData.data.status === 'error') {
			// alert('Something went wrong try again')
			alert("Invalid telephone number; if you believe the telephone number exists, please contact customer service");
			commit('setIsTwilioOkay', false)
			//return;
		}
		if (responseData.data.status === 'success') {
			commit('setIsTwilioOkay', true)
			commit('callTwilio', responseData);
		}
	},

	// RECOVER PASSWORD
	async recoverPassword({ state, getters, dispatch }, payload) {

		if (payload) {
			const config = {
				body: { username: payload, expiration_hours: 1 },
				path: state.path.recoveryPassword,
			};
			if (payload.includes("@"))
				config.body = { email: payload, expiration_hours: 1 };

			const responseData = await getters.axios
				.post(getters.endpoint + config.path, config.body, getters.auth)
				.then((response) => response.data.token)
				.catch((err) => err);
			dispatch('alert/recoverPasswordAlert', responseData, { root: true });
		}
	},
	async manyAttempts({ state, getters, dispatch }, payload) {

		if (payload) {
			const config = {
				body: { username: payload },
				path: state.path.manyAttempts,
			};
			if (payload.includes("@"))
				config.body = { email: payload };

			const responseData = await getters.axios
				.post(getters.endpoint + config.path, config.body, getters.auth)
				.then((response) => response.data.token)
				.catch((err) => err);
			dispatch('alert/manyAttemptsAlert', responseData, { root: true });
		}
	},
};
