<template>
  <div
    v-if="!isGamePage && !isTranslationLoading"
    class="container-fluid bg-grey py-4 mt-5"
  >
    <div class="container">
      <div class="languageSelector my-4">
        <select
          name="selectLanguage"
          id="selectLanguage"
          class="py-2 px-4"
          v-model="language_selected"
          @change="changeLanguage"
        >
          <option
            class="text-start"
            v-for="lang in languages"
            :key="lang.id"
            :value="lang.code"
          >
            {{ lang.name }}
          </option>
        </select>
      </div>
      <div class="links my-4">
        <ul class="nav justify-content-center">
          <li class="nav-item">
            <router-link :to="`/${language_selected}/promotion`">{{
              translations.footer.promotions ?? "Promotion"
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="`/${language_selected}/security`">{{
              translations.footer.security ?? "Security"
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="`/${language_selected}/responsible-gaming`">{{
              translations.footer.responsible_gaming ?? "Responsible Gaming"
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="`/${language_selected}/contact`">{{
              translations.footer.contact ?? "Contact"
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="`/${language_selected}/imprint`">{{
              translations.footer.imprint ?? "Imprint"
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="`/${language_selected}/terms-and-conditions`">{{
              translations.footer.terms_condition ?? "Terms and conditions"
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="`/${language_selected}/affiliate-program`">{{
              translations.footer.affiliate ?? "Affiliate"
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="`/${language_selected}/privacy`">{{
              translations.footer.privacy ?? "Privacy"
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="`/${language_selected}/help`">{{
              translations.footer.help ?? "Help"
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="`/${language_selected}/cookies`">{{
              translations.footer.cookies ?? "Cookies"
            }}</router-link>
          </li>
        </ul>
      </div>
      <div class="mga my-4">
        <div class="col-12 text-center">
          <a
            target="_blank"
            href="https://authorisation.mga.org.mt/verification.aspx?lang=EN&company=73def842-3230-4ffc-892f-33f1244025b6&details=1"
          >
            <img
              src="@/assets/img/otherLogos/mga_logo3.png"
              alt="MGA"
              height="49"
              width="125"
            />
          </a>
        </div>
        <div>
          <p class="mb-0">
            <b>Pro Xenon Mediathek Ltd.</b>
            {{
              translations.footer.copy1 ??
              "is licensed in Malta by the MALTA GAMING AUTHORITY and is regulated under the licence number MGA/B2C/218/2012, issued on 1 August 2018."
            }}
            <b>Pro Xenon Mediathek Ltd.</b>
            {{
              translations.footer.copy2 ??
              "is a limited liability company domiciled in Malta, Commercial Register no. C54870 under the law of Malta with registered business ddress at Office 1 – 2288, Level G, Quantum House, 75 Abate Rigord Street, Ta’ Xbiex, XBX 1120, Malta."
            }}
          </p>
          <p class="mb-0">
            {{
              translations.footer.copy3 ??
              "Any time or data entered in the website are in UTC format"
            }}
          </p>
          <p>
            {{ translations.registration.phone ?? "Phone" }}:
            <a href="tel:+35627221112">+356 2722 1112</a>
          </p>
        </div>
      </div>

      <div class="depositMethods my-4">
        <div>
          <img src="@/assets/img/depositMethods/deposit_1.png" alt="Deposit Methods" />
        </div>
      </div>
      <div class="depositMethods my-4">
        <div>
          <img src="@/assets/img/depositMethods/deposit_3.png" alt="Deposit Methods" />
        </div>
      </div>
      <div class="gamecare my-4">
        <p>
          <b>{{
            translations.footer.copy_responsibility ??
            "Responsible gaming - taking responsibility is very important to us!"
          }}</b>
          <br />Germany:
          <a href="https://www.begambleaware.org" target="_blank">
            www.begambleaware.org </a
          >, International:
          <a href="https://www.gamcare.org.uk" target="_blank"> www.gamcare.org.uk</a>
        </p>
      </div>
      <div class="gamecare18plus my-4">
        <div>
          <p>
            {{ translations.footer.copy4 ?? "Warning! - Gambling can be addictive!" }}
          </p>

          <img width="40" height="40" src="\images\18.png" alt="18 years" />
        </div>
      </div>
    </div>

    <div class="cookie-container" v-if="viewCookies">
      <div class="container">
        <div class="col-12 p-0 m-0 row">
          <div class="col-12 col-md-8 p-0 m-0">
            <h3 class="cookie-text">
              {{ translations.footer.privacy_settings ?? "Privacy settings" }}
            </h3>
            <p
              class="cookie-text"
              v-html="
                translations.footer.cookie_text ??
                'We use cookies and similar technologies that are necessary to operate the website. Additional cookies are used to perform analysis of website usage. By continuing to use our website, consent to our use of cookies. For more information, please read our <a href=\'privacy\'> Privacy Policy.</a>'
              "
            ></p>
            <div class="col-12 p-0 m-0 row">
              <div class="col-12 col-md-4">
                <div class="form-check px-2">
                  <input type="checkbox" v-model="essentialCookies" disabled="disabled" />
                  <span class="text">{{
                    translations.footer.essential ?? "Essential"
                  }}</span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-check px-2">
                  <input type="checkbox" v-model="functionalCookies" />
                  <span class="text">{{
                    translations.footer.functional ?? "Functional"
                  }}</span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-check px-2">
                  <input type="checkbox" v-model="statisticalCookies" />
                  <span class="text">{{
                    translations.footer.statistical ?? "Statistical"
                  }}</span>
                </div>
              </div>
            </div>
            <a class="cookie-text cookie-text-link" href="/help">
              {{
                translations.footer.help_section ??
                "You can find more information in the help section 'Data protection'"
              }}
            </a>
          </div>
          <div class="col-12 col-md-4 p-0 m-0">
            <div class="agree">
              <base-button
                @click="setCookies(1)"
                color="orange"
                :caption="translations.footer.cookie_confirm ?? 'Confirm All'"
                id="deposit"
              ></base-button>
              <base-button
                @click="setCookies(2)"
                color="grey"
                :caption="translations.footer.save_setting ?? 'Save & close settings'"
                id="login"
              ></base-button>
              <base-button
                @click="setCookies(3)"
                color="grey"
                :caption="
                  translations.footer.save_essential ?? 'Only accept essential cookies'
                "
              ></base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";

export default {
  data() {
    return {
      language_selected: localStorage.getItem("language"),
      isGamePage: false,
      viewCookies: false,
      essentialCookies: true,
      functionalCookies: false,
      statisticalCookies: false,
      lang: "",
    };
  },
  watch: {
    $route() {
      this.disableFooter();
    },
  },
  computed: {
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    ...mapGetters("device", ["isDesktop"]),
    ...mapGetters("player", ["playerInfo"]),
    languages() {
      return this.$store.getters["translations/skinLanguages"];
    },
  },
  methods: {
    loadCookies() {
      if (VueCookies.get("cookieConfirmed") == null) this.viewCookies = true;
    },
    setCookies(method) {
      // 1 confirm all cookie 2 select cookie 3 only essential cookie
      let string = "e"; //essential
      if (method == 1) {
        this.functionalCookies = true;
        this.statisticalCookies = true;
      } else if (method == 3) {
        this.functionalCookies = false;
        this.statisticalCookies = false;
      }
      if (this.functionalCookies) {
        string += "f";
        this.startChat();
      }

      if (this.statisticalCookies) {
        string += "s";
        //in pending GA and mediacle
      }

      let exp = 31536000; // y1 year
      VueCookies.set("cookieConfirmed", string, exp);
      VueCookies.set("language", this.language_selected, exp);
      this.viewCookies = false;
    },
    changeLanguage() {
      if (this.playerInfo.user_id != null) {
        let body = {
          language: this.language_selected,
          dob: this.playerInfo.information.dob,
        };
        let playerId = this.playerInfo.user_id;
        this.$store.dispatch("registration/updatePlayer", {
          body,
          playerId,
        });
      }

      this.$store.dispatch("translations/changeLanguage", this.language_selected);
      const currentPath = this.$route.path;
      const langRegex = /\/[^/]+\//; // controllo che il formato sia valido

      let newLanguagePath;
      if (currentPath === "/") {
        newLanguagePath = currentPath;
      } else {
        newLanguagePath = `/${this.language_selected}${currentPath.replace(
          langRegex,
          "/"
        )}`;
      }
      this.$router.push(newLanguagePath);
      setTimeout(() => {
        location.reload();
      }, 400);
    },
    //  changeLanguage() {
    // 	this.$store.dispatch(
    //  	'translations/changeLanguage',
    //  	this.language_selected
    //  );	setTimeout(() => {
    // 	location.reload();
    // }, 400);
    // },
    disableFooter() {
      const page = this.$route.name;
      if (page == "game-page") {
        this.isGamePage = true;
      } else {
        this.isGamePage = false;
      }
    },
    startChat() {
      let cook = VueCookies.get("cookieConfirmed");
      console.log(cook);
      if (
        (this.$route.name != "game-page" ||
          (this.$route.name == "game-page" && this.isDesktop)) &&
        (cook == "ef" || cook == "efs")
      )
       
      import(`@/store/modules/main/chat/ocm-zopim`).then();
  
    },
  },
  created() {
    this.disableFooter();
    this.loadCookies();
  },
  mounted() {
    this.startChat();
  },
};
</script>

<style scoped>
* {
  text-align: center;
  text-decoration: none;
}
.depositMethods img {
  max-width: 100%;
}
.bg-grey {
  background-color: #f0f0f0;
}
select {
  border-radius: 4px;
  cursor: pointer;
}
option:hover {
  cursor: inherit;
}
.links ul {
  list-style-type: none;
  padding: 0 auto;
}
li {
  align-items: center;
  padding-left: 5px;
  padding-right: 10px;
  color: var(--dark-orange);
  border-right: 1px solid var(--dark-orange);
}
li:last-child {
  border-right: none;
}

.mga p,
.gamecare p {
  font-size: 10px;
  font-weight: 500;
}

.cookie-container {
  align-content: left;
  align-items: left;
  padding: 2rem;
  color: #bbb;
  left: 0px;
  right: 0px;
  background-color: #252525;
  opacity: 0.95;
  z-index: 99999999;
  position: fixed;
  bottom: 0;
  font-size: 1rem;
  gap: 2rem;
  visibility: visible;
  flex-wrap: wrap;
}
.cookie-container .cookie-text {
  color: #bbb;
  text-align: left;
}
.cookie-container .cookie-text-link {
  color: var(--dark-orange);
  text-align: left;
  display: block;
}
.cookie-container a {
  color: var(--white-color);
}

.cookie-container .cookie-text {
  flex: 8 768px;
}
.cookie-container .form-check {
  text-align: left;
  margin-bottom: 1rem;
}
.cookie-container .form-check span {
  color: #bbb;
  padding-left: 0.4rem;
}
.cookie-container .agree {
  flex: 1 150px;
  text-align: center;
}

.agree button {
  background: var(--button-background);
  color: var(--button-text);
  border: none;
  padding: 0.4rem 1.2rem;
  cursor: pointer;
  border-radius: 20px;
  font-size: 1rem;
}
</style>
