<template>
  <!-- <div
    v-if="sections && sections.length != 1 && !isLoadingCardGames"
    class="row flex-nowrap game-box justify-content-start justify-content-md-center justify-content-lg-start section-shadow align-items-center"
  > -->
  <div
    v-if="sections && sections.length != 1"
    class="row flex-nowrap game-box justify-content-start justify-content-md-center justify-content-lg-start section-shadow align-items-center"
  >
    <div
      v-for="section in sections"
      :id="section.id"
      class="col-2 game-section my-2"
      :class="addClasses(section.id, section.rank)"
      :key="section.id"
      @click="changeSect(section.id)"
    >
      <div class="button-content">
        <img v-if="0 && section.thumb" :src="section.thumb" class="button-icon" />
        <span class="button-text">{{ getSectionTranslation(section.slug) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["sections", "translations", "isSticked", "filters"],
  data() {
    return {
      lang: localStorage.getItem("language"),
      activeSection: null,
    };
  },
  computed: {
    ...mapGetters("casino", ["selectedSection", "isLoadingCardGames"]),
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    getSectionTranslation(slug) {
      const newSlug = slug.replace(/\s+/g, "").toLowerCase();
      return this.translations?.buttons?.[newSlug] ?? slug;
    },
    async changeSect(id) {
      this.$emit("closeSearch");
      await this.$store
        .dispatch("casino/selectSection", id)
        .then(() => this.$store.dispatch("casino/callSkinProviders", this.filters));
      console.log(this.filters);
      // quando cambio section riporto la paginazione a 1 cosi vedo i primi della pagina 1 in base alla section
      this.$store.dispatch("casino/changingPage", { currentPage: 1 });
      //add scrol top on change section
      if (window.innerWidth > 768) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      // Ottengo il percorso attuale senza il valore di lang
      let currentPath = this.$route.path.replace(`/${this.lang}`, "");
      // Nuovo path con valore lang
      let newPath = `/${this.lang}${currentPath}`;
      this.$router.push(newPath);
    },

    addClasses(value, rank) {
      return [
        this.selectedSection == value ? "active" : "",
        this.isSticked ? "isSticked" : "",
        rank ? `order-${rank}` : "",
      ];
    },
  },
};
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.game-box {
  overflow-x: auto;
}

.game-section {
  margin-right: 1.5rem;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: var(--darker-grey);
  transition: var(--short-trans);
  box-shadow: none;
  background: var(--dirty-white);
  line-height: 16px;
  border: 1px solid transparent;
  min-width: 130px;
  max-width: 100%;
  text-align: center;
}

.button-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
}

.button-icon {
  max-width: 25%;
  object-fit: contain;
}

.button-text {
  margin-left: 0.5rem;
  white-space: nowrap;
  color: black !important;
}

.game-section:hover {
  cursor: pointer;
  background: var(--light-grey);
  color: white;
  border-color: var(--light-grey);
}

.game-section.active {
  /* color: var(--dirty-white); */
  background: var(--dark-orange);
  border-color: var(--dark-orange);
  transition: var(--short-trans);
}

.section-shadow {
  transition: var(--short-trans);
  transform: scale(0.99);
  border-radius: 10px;
}

@media (max-width: 424px) {
  /* Styles for mobile devices */
  .game-section {
    max-width: 100%;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .section-shadow {
    padding: 1px 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

/* SCROLLBAR */
/* width */
::-webkit-scrollbar {
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--dirty-white);
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dark-orange);
  border-radius: 2px;
  transition: var(--short-trans);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--light-orange);
  transition: var(--short-trans);
}
</style>
