<template>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 lh-sm">
        <h6>{{ playerInfo.name }}</h6>
        <div class="balance my-2" v-if="isBalanceShow && playerInfo">
          <span class="bold"
            >{{ translations.account_balance.Withdrawable_Balance }}:
          </span>
          <span :class="showBalanceClasses" style="color: var(--dirty-white)">
            {{ playerInfo?.currency?.symbol ?? "" }}
            {{ playerInfo.balance.available.toFixed(2) }}</span
          >
        </div>
        <div class="balance my-2" v-if="isBalanceShow && playerInfo">
          <span class="bold">{{ translations.account_balance.Balance }}: </span>
          <span :class="showBalanceClasses" style="color: var(--dirty-white)">
            {{ playerInfo?.currency?.symbol ?? "" }}
            {{ playerInfo.balance.deposited.toFixed(2) }}</span
          >
        </div>
        <div class="balance my-2" v-if="isBalanceShow && playerInfo">
          <span class="bold">
            {{ translations.account_balance.Bonus_Balance }}:
          </span>
          <span :class="showBalanceClasses" style="color: var(--dirty-white)">
            {{ playerInfo?.currency?.symbol ?? "" }}
            {{ playerInfo.balance.bonus.toFixed(2) }}</span
          >
        </div>
         
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <base-button
            @click="deposit"
            color="orange"
            :caption="translations.buttons.deposit_now"
            id="deposit"
          ></base-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <base-button
            @click="logoutPlayer"
            color="grey"
            :caption="translations.buttons.LogOut"
            id="logout"
          ></base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["showBalanceClasses", "isBalanceShow"],
  computed: {
    ...mapGetters("player", ["playerInfo"]),
    ...mapGetters("translations", ["translations"]),
  },
  methods: {
    async logoutPlayer() {
      this.$store.dispatch("player/logout");
    },
    deposit() {
      this.$router.push({ name: "deposit" });
    },
  },
};
</script>

<style scoped>
.balance {
  font-size: 0.9rem;
  color: var(--dirty-white);
}
h6 {
  color: var(--light-orange);
}
.bold {
  font-weight: 700;
  color: var(--dirty-white);
}
.balance-text {
  color: var(--dirty-white);
  cursor: pointer;
  text-align: center;
}
.balance-text.blur {
  filter: blur(3px);
}
.balance-text.hide {
  display: none;
}
</style>
